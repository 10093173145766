
@import "themes.scss";
@import "mixins";

.distributionbanner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/usecases/distributionusecases_banner.svg');
    background-repeat: no-repeat;
    background-position: -79px 0px;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: center;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
            }
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: center;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
.distribution-bdy-main {
    float: left;
    width: 100%;
    margin: 482px 0px 0px 0px;
    padding: 100px 0px;
    @include upsure-md{
        padding: 50px 0px;
    }
    @include upsure-lg{
        padding: 50px 0px;
    }
    .dist-sec-one {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        .distsec-item {
            flex: 0 auto;
            width: 360px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-flow: column;

            @include upsure-sm{
                width: 100%;
                
            }
            @include upsure-md{
    width: 300px;
    margin-bottom: 1rem;
            }



            .items-img {
                flex: 0 auto;
                width: 100%;
                text-align: center;
                padding-bottom: 30px;
            }
            .items-title {
                flex: 0 auto;
                width: 100%;
                font-size: 15px;
                font-weight: 500;
                color: #012932;
                text-align: center;
                padding-bottom: 15px;
            }
            .items-dec {
                flex: 0 auto;
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                color: #667477;
                text-align: justify;
            }
        }
    }
}
.dist-sec-two {
    background: #F5FBFB;
    float: left;
    width: 100%;
    padding: 100px 0px;
    @include upsure-md{
        padding: 50px 0px;
    }
    @include upsure-lg{
        padding: 50px 0px;
    }
    .container {
        h2.sectitle {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        p.sectitle-dec {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #012932;
            text-align: center;
            margin: 10px 0px 0px;
            padding: 0px;
        }
        .distsec-two-flx {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            padding-top: 50px;
            @include upsure-md{
                flex-flow: column;
            }
            @include upsure-lg{
                flex-flow: row;
            }
            .items-dec {
                flex: 0 auto;
                width: 545px;
                @include upsure-lg{
                    width: 47%;
                }
                h3 {
                    float: left;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: #012932;
                    text-align: left;
                    padding: 0px;
                    margin: 0px 0px 15px;
                }
                p {
                    float: left;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    text-align: justify;
                    padding: 0px;
                    margin: 0px 0px 10px;
                }
            }
            .items-img {
                flex: 0 auto;
                width: 708px;
                @include upsure-lg{
                    width: 47%;
                }
                img{
                    @include upsure-xs {
                        width: 100%;
                        height: 100%;
                    }
                
                    @include upsure-sm {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
.dist-sec-three {
    float: left;
    width: 100%;
    padding: 100px 0px;
    @include upsure-md{
        padding: 50px 0px;
    }
    @include upsure-lg{
        padding: 50px 0px;
    }
    .container {
        h2.sectitle {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        p.sectitle-dec {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #012932;
            text-align: center;
            margin: 20px 0px 0px;
            padding: 0px;
        }
        .distsec-three-flx {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            padding-top: 100px;
            @include upsure-md{
flex-flow: column;
            }
            @include upsure-lg{
                flex-flow: row;
                            }
            .items-dec {
                flex: 0 auto;
                width: 544px;
                @include upsure-lg{
                    width: 47%;
                }
                h3 {
                    float: left;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: #012932;
                    text-align: left;
                    padding: 0px;
                    margin: 0px 0px 15px;
                }
                p {
                    float: left;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    text-align: justify;
                    padding: 0px;
                    margin: 0px 0px 10px;
                }
            }
            .items-img {
                flex: 0 auto;
                width: 708px;
                @include upsure-lg{
                    width: 47%;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.dist-sec-four {
    background: #F5FBFB;
    float: left;
    width: 100%;
    padding: 100px 0px;
    @include upsure-md{
        padding: 50px 0px;
    }
    @include upsure-lg{
        padding: 50px 0px;
    }
    .container {
        h2.sectitle {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        p.sectitle-dec {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #012932;
            text-align: center;
            margin: 10px 0px 0px;
            padding: 0px;
        }
        .secfour-bx {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            padding-top: 60px;
            @include upsure-xs{
                flex-flow: column;
            }
            @include upsure-sm{
                flex-flow: column;
            }
            @include upsure-md{
                flex-flow: row;
            }
            .bx-item-blu {
                flex: 0 auto;
                width: 421px;
                height: 286px;
                background: #012932;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                flex-flow: column;
                @include upsure-xs{
                    width: 100%; 
                }
                @include upsure-sm{
                    width: 100%;
                }
                @include upsure-lg{
                    justify-content: center !important;
                }
            }
            .bx-item-drkblu {
                flex: 0 auto;
                width: 30%;
                height: 286px;
                background: #012932;
                padding: 25px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                flex-flow: column;
                box-shadow: 0px 4px 15px #629CA3;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;
                @include upsure-xs{
                    width: 100%; 
                    justify-content: center;
                }
                @include upsure-sm{
                    height: 235px;
                    width: 100%;
                }
                @include upsure-lg{
                    justify-content: center;
                }
                .bxitm-title {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    margin: 0px 0px 15px;
                }
                .bxitm-dec {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #fff;
                }
            }
            .bx-item-blu {
                flex: 0 auto;
                width: 30%;
                height: 286px;
                background: #2CA1AF;
                padding: 25px;
                box-shadow: 0px 4px 15px #629CA3;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;
                margin-left: 25px;
                margin-right: 25px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                flex-flow: column;
                @include upsure-xs{
                    width: 100%; 
                    margin: 1rem 0rem;
                    justify-content: center;
                }
                @include upsure-sm{
                    height: 235px;
                    width: 100%;
                }
                @include upsure-md{
                    width: 90%;
                    margin: 0% 1%;
                }
                .bxitm-title {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                    margin: 0px 0px 15px;
                }
                .bxitm-dec {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #fff;
                }
            }
        }
    }
}