.menu-container .manu ul li {
.switch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    width: 86px;
    height: 35px;
    background-color: #fff;
    border-radius: 60px;
    .eng {
        flex: 0 auto;
        width: 60px;
        height: 27px;
        line-height: 27px;
        margin-right: 0.1rem;
        font-size: 12px;
        font-weight: bold;
        color: #667477;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        a {
            font-size: 12px !important;
            font-weight: bold !important;
            color: #667477 !important;
            margin: 0;
            padding: 0 !important;
        }
    }
    .jsp {
        flex: 0 auto;
        width: 60px;
        height: 27px;
        line-height: 27px;
        margin-left: 0.1rem;
        font-size: 12px;
        font-weight: bold;
        color: #667477;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        a {
            font-size: 12px !important;
            font-weight: bold !important;
            color: #667477 !important;
            margin: 0;
            padding: 0 !important;
        }
    }
    .active {
        background-color: #000;
        color: #fff;
        text-align: center;
        // width: 70px;
        // height: 27px;
        // line-height: 27px;
        border-radius: 60px;
        a {
            color: #fff !important;
        }
    }
}
.contact-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    width: 86px;
    height: 35px;
    background-color: #000;
    border-radius: 60px;
    .eng {
        flex: 0 auto;
        width: 60px;
        height: 27px;
        line-height: 27px;
        margin-right: 0.1rem;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        a {
            font-size: 12px !important;
            font-weight: bold !important;
            color: #fff !important;
            margin: 0;
            padding: 0 !important;
        }
    }
    .jsp {
        flex: 0 auto;
        width: 60px;
        height: 27px;
        line-height: 27px;
        margin-left: 0.1rem;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        a {
            font-size: 12px !important;
            font-weight: bold !important;
            color: #fff !important;
            margin: 0;
            padding: 0 !important;
        }
    }
    .active {
        background-color: #fff;
        color: #000;
        text-align: center;
        border-radius: 60px;
        a {
            color: #000 !important;
        }
    }
}
}
.mob-menu {
    .mobflx {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-flow: row;
        width: auto;

    .swt-item {
        flex: 0 auto;
        width: auto;
        margin-right: 20px;
    .switch {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        width: 86px;
        height: 35px;
        background-color: #fff;
        border-radius: 60px;
        // margin-right: 30px;
        .eng {
            flex: 0 auto;
            width: 60px;
            height: 27px;
            line-height: 27px;
            margin-right: 0.1rem;
            font-size: 12px;
            font-weight: bold;
            color: #667477;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            a {
                font-size: 12px !important;
                font-weight: bold !important;
                color: #667477 !important;
                margin: 0;
                padding: 0 !important;
            }
        }
        .jsp {
            flex: 0 auto;
            width: 60px;
            height: 27px;
            line-height: 27px;
            margin-left: 0.1rem;
            font-size: 12px;
            font-weight: bold;
            color: #667477;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            a {
                font-size: 12px !important;
                font-weight: bold !important;
                color: #667477 !important;
                margin: 0;
                padding: 0 !important;
            }
        }
        .active {
            background-color: #000;
            color: #fff;
            text-align: center;
            // width: 70px;
            // height: 27px;
            // line-height: 27px;
            border-radius: 60px;
            a {
                color: #fff !important;
            }
        }
    }
    .contact-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.3rem;
        width: 86px;
        height: 35px;
        background-color: #000;
        border-radius: 60px;
        .eng {
            flex: 0 auto;
            width: 60px;
            height: 27px;
            line-height: 27px;
            margin-right: 0.1rem;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            a {
                font-size: 12px !important;
                font-weight: bold !important;
                color: #fff !important;
                margin: 0;
                padding: 0 !important;
            }
        }
        .jsp {
            flex: 0 auto;
            width: 60px;
            height: 27px;
            line-height: 27px;
            margin-left: 0.1rem;
            font-size: 12px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            a {
                font-size: 12px !important;
                font-weight: bold !important;
                color: #fff !important;
                margin: 0;
                padding: 0 !important;
            }
        }
        .active {
            background-color: #fff;
            color: #000;
            text-align: center;
            border-radius: 60px;
            a {
                color: #000 !important;
            }
        }
    }
    }
}
}