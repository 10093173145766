@import "../css/themes.scss";
@import "../css/mixins.scss";

.congratulations-jp {
    display: block;
    // background: $white-color;
    background-image: url("https://cdn.upsure.io/newui2023/contact/start_now_bg2.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    min-height: 537px;
    width: 100%;
    @media screen and (max-width: 767px) {
      min-height: 490px;
    }
    h1 {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: 600;
        color: $dark-color;
        text-align: center;
        padding: 125px 0px 0px;
        margin: 0px;
        @media screen and (max-width: 767px) {
            padding: 120px 0px 0px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            padding: 120px 0px 0px;
        }
    }
    p {
        display: block;
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        color: $text-color;
        text-align: center;
        padding: 20px 0px 0px;
        margin: 0px;
        @media screen and (max-width: 767px) {
            padding: 20px 20px 0px;
        }
    }
}
.polygon-container-jp {
    background-image: url("https://cdn.upsure.io/newui2023/contact/start_now_bg2.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    // min-height: 537px;
    // min-height: 1400px;
    min-height: 1000px;
    background: #1B818D;
    // min-height: 65vh;
    @media screen and (max-width: 767px) {
        height: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        height: 100%;
    }
    .container {
      position: relative;
      .position-sec-jp {
        position: absolute;
        top: -250px;
        left: 0px;
        .startnow-from-sec-jp {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: row wrap;
            width: 100%;
            @media screen and (max-width: 767px) {
                margin-top: 0px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                margin-top: 0px;
            }
            .startnow-form-bx-jp {
                flex: 0 auto;
                width: 685px;
                position: relative;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                }
                .frm-bx-jp {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    min-height: 440px;
                    background: $white-color;
                    padding: 35px 30px;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                    border-radius: 36px;
                    @media screen and (max-width: 767px) {
                        min-height: 350px;
                        position: inherit;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        min-height:220px;
                    }
                        .h-100 {
                            height: 100px !important;
                        }
                        .frmbx-flex-jp {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-flow: row wrap;
                            width: 100%;
                            .form-floating label {
                                color: #B4B4B4 !important;
                            }
                            .frmitem-lft-jp {
                                flex: 0 auto;
                                width: 48%;
                                margin-bottom: 20px;
                            }
                            .frmitem-rgt-jp {
                                flex: 0 auto;
                                width: 48%;
                                margin-bottom: 20px;
                            }
                            .frmitem-wth {
                                flex: 0 auto;
                                width: 100%;
                                margin-bottom: 25px;
                                p.agreed-con {
                                  display: flex;
                                  justify-content: flex-start;
                                  align-items: center;
                                  label {
                                    font-size: 14px;
                                    font-weight: 500;
                                  }
                                }
                                p.lb-hd {
                                    width: 100%;
                                    font-size: 15px;
                                    font-weight: 500;
                                    color: $text-color;
                                    text-align: left;
                                    line-height: 24px;
                                    letter-spacing: -1.1%;
                                    padding: 0px 0px 0px 0px;
                                    margin: 0px 0px 20px 0px;
                                }
                                p.dec {
                                    width: 100%;
                                    font-size: 15px;
                                    font-weight: 400;
                                    color: $text-color;
                                    text-align: justify;
                                    line-height: 20px;
                                    letter-spacing: -1.1%;
                                    padding: 0px 0px 0px 0px;
                                    margin: 0px 0px 20px 0px;
                                }
                                .rdbtn-main {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    flex-flow: row wrap;
                                    .rd-btn {
                                        flex: 0 auto;
                                        margin-right: 13px;
                                        margin-bottom: 15px;
                                        .radio-button-container {
                                            color: #707C7F;
                                            display: block;
                                            position: relative;
                                            padding-left: 35px;
                                            line-height: 25px;
                                            margin-bottom: 12px;
                                            margin-left: 0px;
                                            cursor: pointer;
                                            font-size: 14px;
                                            -webkit-user-select: none;
                                            -moz-user-select: none;
                                            -ms-user-select: none;
                                            user-select: none;
                                          }                                          
                                          /* Hide the browser's default radio button */
                                          .radio-button-container input {
                                            position: absolute;
                                            opacity: 0;
                                            cursor: pointer;
                                          }                                          
                                          /* Create a custom radio button */
                                          .checkmark {
                                            position: absolute !important;
                                            top: 0 !important;
                                            left: 0 !important;
                                            height: 26px !important;
                                            width: 26px !important;
                                            background-color: rgba(0,0,0,0) !important;
                                            border-radius: 50%;
                                            border: 2px solid rgba(0,0,0,0.25) !important;
                                            transition: all 0.3s;
                                          }
                                          /* On mouse-over, add a grey background color */
                                          .radio-button-container:hover input ~ .checkmark {
                                            border-color: rgba(0,0,0,0.5) !important;
                                          }                                          
                                          /* When the radio button is checked */
                                          .radio-button-container input:checked ~ .checkmark {
                                            background-color: rgba(0,0,0,0) !important;
                                            border-color: #1B818D !important;
                                          }
                                          /* Create the indicator (the dot/circle - hidden when not checked) */
                                          .checkmark:after {
                                            content: "";
                                            position: absolute;
                                            display: none !important;
                                          }                                          
                                          /* Show the indicator (dot/circle) when checked */
                                          .radio-button-container input:checked ~ .checkmark:after {
                                            display: block !important;
                                          }                                          
                                          /* Style the indicator (dot/circle) */
                                          .radio-button-container .checkmark:after {
                                               top: 3px !important;
                                              left: 3px !important;
                                              width: 16px !important;
                                              height: 16px !important;
                                              border-radius: 50% !important;
                                              background: #1B818D !important;
                                          }
                                    }
                                }
                                button.submit {
                                    background: $primary-color;
                                    width: 100%;
                                    border: none;
                                    outline: none;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: $white-color;
                                    line-height: 24px;
                                    letter-spacing: -1.1%;
                                    border-radius: 6px;
                                    padding: 18px 0px;
                                }
                                .agreed-con label{
                                    font-size:13px;
                                    font-weight: 600;
                                    color:#667477;
                                    line-height: 20px;
                                    letter-spacing: -1.1%;
                                }
                                input[type=checkbox] {
                                  position: relative;
                                    border: 2px solid #C4C7C8;
                                    border-radius: 6px;
                                    background: none;
                                    cursor: pointer;
                                    line-height: 0;
                                    margin: 0 .6em 0 0;
                                    outline: 0;
                                    padding: 0 !important;
                                    vertical-align: text-top;
                                    height: 22px;
                                    width: 22px;
                                    -webkit-appearance: none;
                                  opacity: .5;
                                }
                                
                                input[type=checkbox]:hover {
                                  opacity: 1;
                                }
                                
                                input[type=checkbox]:checked {
                                  background-color: #1B818D;
                                  opacity: 1;
                                    border: 2px solid #1B818D;
                                }
                                
                                input[type=checkbox]:before {
                                  content: '';
                                  position: absolute;
                                  right: 50%;
                                  top: 50%;
                                  width: 6px;
                                  height: 12px;
                                  border: solid #FFF;
                                  border-width: 0 2px 2px 0;
                                  margin: -1px -1px 0 -1px;
                                  transform: rotate(45deg) translate(-50%, -50%);
                                  z-index: 2;
                                }
                            }
                        }
                    // }
                }
            }
            .frm-rgt-sec {
                flex: 0 auto;
                width: 40%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    padding-top: 30px;
                    margin-left: 20px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                    padding-top: 30px;
                    margin-left: 20px;
                }
                .frm-rgtsec-flx {
                    flex: 0 auto;
                    width: 100%;
                    // margin-top: 70%;
                    margin-top: 20%;
                    h3 {
                        font-size: 30px;
                        font-weight: 600;
                        color: $white-color;
                        padding: 0px 0px 30px 0px;
                        margin: 0px 0px 0px 0px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        span {
                            flex: 0 auto;
                            font-size: 30px;
                            font-weight: 600;
                            color: $white-color;
                        }
                        span.arrow-img {
                            flex: 0 auto;
                            margin-left: 20px;
                            img {
                                width: 20px;
                            }
                        }
                    }
                    p {
                        font-size: 15px;
                        font-weight: 400;
                        color: $white-color;
                        padding: 0px 0px 0px 0px;
                        margin: 0px 0px 20px 0px;
                        a {
                            color: $white-color;
                            text-decoration: none;
                        }
                    }
                    p.l1-l2 {
                        font-size: 15px;
                        font-weight: 400;
                        color: $white-color;
                        padding: 0px 0px 0px 0px;
                        margin: 0px 0px 5px 0px;
                        a {
                            color: $white-color;
                            text-decoration: none;
                        }
                    }
                    .mt-50 {
                        margin-top: 50px !important;
                    }
                    .mt-70 {
                        margin-top: 70px !important;
                    }
                    .mt-100 {
                        margin-top: 100px !important;
                    }
                }
            }
        }
      }
    }
  }
  .ftbgtop {
    background: #1B818D;
    float: left;
    width: 100%;
    height: 200px;
  }

.error-msg {
  font-size: 15px;
  font-weight: 400;
  color: #f00;
  margin: 10px 0px 0px 0px !important;
  padding: 0px !important;
}
.ji-cust-modal{
    margin:auto;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
  z-index: 9999;
    @include flexbox();
    align-items: center;
   // display: table-cell;
    vertical-align: middle;
    width:auto;
    //min-height: 200px;
    height: auto;
    overflow-y: auto;
    //height:fit-content;
    -webkit-animation: modal-anim .3s ease-in-out forwards;
   .jiva-mod-content{
    //    @include custom-border-radius(3px);
       border-radius: 3px;
      background: $white-color;
      z-index: 100;
     position: relative;
      margin: auto;
    //   @include cus-boxshadow(0, 5px, 15px, rgba(0,0,0,.5));
     .cls-btn{
       position: absolute;
       top: 10px;
       right: 16px;
       color:rgba(0,0,0, 46%);
      cursor: pointer;
       z-index: 99;
       .fa{
         font-size: 14px;
         color:rgba(0,0,0, 80%);
       }
     }
      .modal-header{
        position: relative;
        padding:26px 20px;
      border-bottom: 0px solid rgba(0,0,0, 94%);
        display: block !important;
        h2{
          color: $primary-color;
          text-transform: uppercase;
          font-size:16px;
        }
        img{
          max-width: 140px;
        }
      }
     .modal-body{
       padding:0px ;
       height: auto;
       img.tickimg {
        width: 80px;
        height: 80px;
        margin: 20px 0px;
       }
       p.thanks-msg {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: 500;
        color: $dark-color;
        padding: 0px 40px 20px;
       }
  
     }
     .modal-footer{
       padding:14px;
       background: #1B818D;
       .ok-btn {
        background: #fff;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: 600;
        color: $link-color;
        border-radius: 30px;
        padding: 8px 25px;
        cursor: pointer;
        transition: .1s ease-in-out 0s;
       }
     }
    }
  &::before{
    content: '';
    width:100%;
    height:100%;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    background: #000;
    opacity: .7;
    z-index: -1;
  }
  }
  .sm{
    max-width: 480px;
    min-height: 240px;
  }