@import "themes.scss";
@import 'mixins';
.terms-banner-bg {
    background-image: url('https://cdn.upsure.io/newui2023/businesslines/lifeinsurance_banner.svg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: center;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
            }
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: center;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
  .terms-bdy-main {
    float: left;
    width: 100%;
    padding: 50px 0px 100px;
    margin: 482px 0px 0px 0px;
    .container {
       h2, h3 {
            float: left;
            width: 100%;
            font-size: 22px;
            font-weight: 500;
            color: #012932;
            text-align: left;
            padding: 0px;
            margin: 20px 0px;
        }
        p {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: justify;
            line-height: 25px;
            padding: 0px;
            margin: 10px 0px;
        }
        ul li {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: justify;
            line-height: 25px;
            padding: 0px;
            margin: 7px 0px;
        }
    }
  }