@import "themes.scss";


.finserbanner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/businesslines/lifeinsurance_banner.svg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: center;
            padding: 145px 0px 0px;
            margin: 0px;
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: center;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }

  .affinity-banner-dwn {
    background: #F5FBFB;
    float: left;
    width: 100%;
    height: 459px;
    margin-top: 489px;
    .container {
        h2.page-heading {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            font-style: normal;
            color: #012932;
            line-height: 33px;
            text-align: center;
            padding: 50px 0px 0px;
            margin: 0px 0px 50px;
        }
        p.contxt {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: justify;
            margin: 10px 0px;
            padding: 0px;
        }
        .finser-bx {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            padding-top: 40px;
            .finserbx-item {
                flex: 0 auto;
                background: #F5FBFB;
                border: 1px solid #BBDFDF;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;
                margin: 0px 25px;
                width: 200px;
                height: 128px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                .item-img {
                    flex: 0 auto;
                    width: 37px;
                    height: 46px;
                }
                .item-dec {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 500;
                    text-align: center;
                    padding-top: 9px;
                }
            }
        }
    }
  }
  .affinity-bdycon {
    float: left;
    width: 100%;
    height: 100%;
    padding: 100px 0px;
    .container {
        h3.becu-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: center;
        }
        p.contxt {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: justify;
            margin: 10px 0px;
            padding: 0px;
        }
        h4.titlebnk {
            float: left;
            width: 100%;
            font-size: 22px;
            font-weight: 500;
            color: #012932;
            text-align: left;
            margin: 20px 0px 0px;
        }

    .affinity-pro-bx {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        padding-top: 30px;
        .landlord-item-img {
            flex: 0 auto;
            width: 33%;
            img {
                float: left;
                text-align: left;
            }
        }
        .finser-item-cnt {
            flex: 0 auto;
            width: 45%;
            h5 {
                float: left;
                width: 100%;
                font-size: 30px;
                font-weight: 600;
                color: #012932;
                text-align: left;
                margin: 0px 0px 10px;
                padding: 0px;
            }
            p.contxt {
                float: left;
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                color: #667477;
                text-align: justify;
                margin: 10px 0px;
                padding: 0px;
            }
        }
    }
    }
  }

  .affinity-slidebg {
    width: 100%;
    margin: 0;
  overflow:hidden;
  position: relative;
  &::after {
    content:"";
    position:absolute;
    top:0;
    left:0;
    height: 100%;
    width:100%;
    transform: skewY(-3deg);
    background: #F5FBFB;
    transform-origin: bottom right;
    z-index: -1;
  }
  .affinityslidebg {
    background: #F5FBFB;
    float: left;
    width: 100%;
    padding-bottom: 100px;
    // min-height: 1000px;
    margin-top: 130px;
    .container {
        .affinity-owner-sec-one {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: row wrap;
            width: 100%;
            .affiinity-hmowner-itemlft {
                flex: 0 auto;
                width: 55%;
                h3 {
                    display: block;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: #012932;
                    font-style: normal;
                    text-align: left;
                }
                p {
                    display: block;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    font-style: normal;
                    text-align: justify;
                }
                ul {
                    float: left;
                    width: 100%;
                    margin: 0px;
                    padding: 10px 0px 10px 15px;
                    li {
                        font-size: 15px;
                        font-weight: 500;
                        color: #000;
                        padding: 0px;
                        margin: 0px;
                    }
                }
            }
            .affinity-hmowner-itemrgt {
                flex: 0 auto;
                width: 40%;
            }
        }
    }
  }
}
