@import "../css/themes.scss";
@import '../css/mixins.scss';

.videobanner {
  float: left;
  width: 100%;
  height: 786px;
  padding-top: 100px;
}
.video-banner {
position: absolute;
left: 0;
right: 0;
top: 0px;
z-index: -1;
overflow-x: hidden;
/*height: 768px;*/
overflow: hidden;
}

// HOME PAGE HEADER CSS STARTS HERE
.hm-header {
    display: block;
    width: 100%;
    background-image: url('https://cdn.upsure.io/newui2023/homepage/homepage_banner_02.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    // background: #14110C;
    // background: linear-gradient(45deg, #14110C, #1C1812);
    min-height: 1045px;
    .video-banner {    
    position: absolute;    
    left: 0;    
    right: 0;    
    top: 0px;    
    z-index: -1;    
    overflow-x: hidden;    
  /*height: 768px;*/    
    overflow: hidden;    
    }
    @media screen and (max-width: 765px) {
      min-height: 569px !important;
    }

    .header-wrapper{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      .upsure-logo{
        img{
          margin-top: 10px;
        }
      }
      .upsure-menu{
        // @media screen and (max-width: 600px) {
        //   width: 100%;
        // }
        @include upsure-md {
          width: 100%;
        }
        @include upsure-lg {
          width: auto;
        }
        #menu, #menu ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }    
    #menu {
      width: 960px;
      margin: 20px auto;
      border: 0px solid #222;
      
      // background-color: #111;
      // background-image: -moz-linear-gradient(#444, #111); 
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111));	
      // background-image: -webkit-linear-gradient(#444, #111);	
      // background-image: -o-linear-gradient(#444, #111);
      // background-image: -ms-linear-gradient(#444, #111);
      // background-image: linear-gradient(#444, #111);
      // -moz-border-radius: 6px;
      // -webkit-border-radius: 6px;
      // border-radius: 6px;
      // -moz-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      // -webkit-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      // box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
      }


    }    
    #menu:before,
    #menu:after {
      content: "";
      display: table;
    }    
    #menu:after {
      clear: both;
    }    
    #menu {
      zoom:1;
    }    
    #menu li {
      float: left;
      // border-right: px solid #222;
      // -moz-box-shadow: 1px 0 0 #444;
      // -webkit-box-shadow: 1px 0 0 #444;
      // box-shadow: 1px 0 0 #444;
      position: relative;
    }    
    #menu a {
      float: left;
      padding: 12px 30px;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      text-shadow: 0 0px 0 #000;
      margin: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        padding: 12px 10px;
        font-size: 11px;
      }
      .fa{
          font-size: 20px;
          margin-left: 10px;
      }
    }    
    #menu li:hover > a {
      color: #fafafa;
    }    
    *html #menu li a:hover { /* IE6 only */
      color: #fafafa;
    }    
    #menu ul {
      margin: 20px 0 0 0;
      _margin: 0; /*IE6 only*/
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 38px;
      left: 0;
      z-index: 1;    
      background: #fff;
      width: 210px;
      // background: -moz-linear-gradient(#444, #111);
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111));
      // background: -webkit-linear-gradient(#444, #111);    
      // background: -o-linear-gradient(#444, #111);	
      // background: -ms-linear-gradient(#444, #111);	
      // background: linear-gradient(#444, #111);
      // -moz-box-shadow: 0 -1px rgba(255,255,255,.3);
      // -webkit-box-shadow: 0 -1px 0 rgba(255,255,255,.3);
      // box-shadow: 0 -1px 0 rgba(255,255,255,.3);	
      padding: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;  
      @media only screen and (min-width: 320px) and (max-width: 767px) {
      visibility: visible !important;
      }
    }    
    #menu li:hover > ul {
      opacity: 1;
      visibility: visible;
      margin: 0;
    }    
    #menu ul ul {
      top: 0;
      left: 150px;
      margin: 0 0 0 20px;
      _margin: 0; /*IE6 only*/
      // -moz-box-shadow: -1px 0 0 rgba(255,255,255,.3);
      // -webkit-box-shadow: -1px 0 0 rgba(255,255,255,.3);
      // box-shadow: -1px 0 0 rgba(255,255,255,.3);		
    }    
    #menu ul li {
      float: none;
      display: block;
      border: 0;
      _line-height: 0; /*IE6 only*/
      // -moz-box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      // -webkit-box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      // box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      color: #667477;
      padding-left: 5px;      
    }
    
    #menu ul li:last-child {   
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;    
    }
    
    #menu ul a {    
      padding: 10px;
      // width: 194px;
      _height: 10px; /*IE6 only*/
      display: block;
      white-space: nowrap;
      float: none;
      text-transform: none;
      // font-family: 'Segoe UI', sans-serif !important;
      font-family: 'Graphik';
      font-size: 15px;
      font-weight: 400;
      color: #667477;
      padding-left: 5px;
      @media only screen and (min-width: 320px) and (max-width: 768px) {
        color: #999;
      }
    }
    
    #menu ul a:hover {
      background-color: #EFF8F9;
      // font-family: 'Segoe UI', sans-serif !important;
      font-family: 'Graphik';
      font-size: 15px;
      font-weight: 400;
      color: #1B818D;
      border-left: solid 2px #1B818D;
      // width: 180px;
      display: block;
      // background-image: -moz-linear-gradient(#04acec,  #0186ba);	
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#04acec), to(#0186ba));
      // background-image: -webkit-linear-gradient(#04acec, #0186ba);
      // background-image: -o-linear-gradient(#04acec, #0186ba);
      // background-image: -ms-linear-gradient(#04acec, #0186ba);
      // background-image: linear-gradient(#04acec, #0186ba);
    }
    
    #menu ul li:first-child > a {
      -moz-border-radius: 3px 3px 0 0;
      -webkit-border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0 0;
    }
    
    #menu ul li:first-child > a:after {
      content: '';
      position: absolute;
      left: 40px;
      top: -16px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
    }
    
    #menu ul ul li:first-child a:after {
      left: -6px;
      top: 50%;
      margin-top: -6px;
      border-left: 0;	
      border-bottom: 6px solid transparent;
      border-top: 6px solid transparent;
      border-right: 6px solid #3b3b3b;
      color: #667477;
    }
    
    #menu ul li:first-child a:hover:after {
      border-bottom-color: #fff; 
    }
    
    #menu ul ul li:first-child a:hover:after {
      border-right-color: #fff; 
      border-bottom-color: transparent; 	
    }
    
    #menu ul li:last-child > a {
      -moz-border-radius: 0 0 3px 3px;
      -webkit-border-radius: 0 0 3px 3px;
      border-radius: 0 0 3px 3px;
    }
    
    /* Mobile */
    #menu-trigger {
      display: none;
    }
    
    @media screen and (max-width: 768px) {
    
      /* nav-wrap */
      #menu-wrap {
        position: relative;
      }
    
      #menu-wrap * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    
      /* menu icon */
      #menu-trigger {
        display: block; /* show menu icon */
        height: 40px;
        line-height: 40px;
        cursor: pointer;		
        padding: 0 0 0 35px;
        border: 1px solid #222;
        color: #fafafa;
        font-weight: bold;
        background-color: #111;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -moz-linear-gradient(#444, #111); 
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -webkit-linear-gradient(#444, #111);	
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -o-linear-gradient(#444, #111);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -ms-linear-gradient(#444, #111);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, linear-gradient(#444, #111);
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        -moz-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
        -webkit-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
        box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;


        @media only screen and (min-width: 320px) and (max-width: 768px) {
width: 90px;
right: 0px;
top: -40px;
position: absolute;
background-color: transparent;
background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center;
border: solid 0px;
box-shadow: none;

        }
      }
      
      /* main nav */
      #menu:after {
        content: '';
        position: absolute;
        right: 25px;
        top: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
        @media only screen and (min-width: 320px) and (max-width: 768px) {
          right: 40px;
        }
      }	
    
      #menu ul {
        position: static;
        visibility: visible;
        opacity: 1;
        margin: 0;
        background: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;				
      }
    
      #menu ul ul {
        margin: 0 0 0 20px !important;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
      }
    
      #menu li {
        position: static;
        display: block;
        float: none;
        border: 0;
        margin: 5px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;			
      }
    
      #menu ul li{
        margin-left: 20px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
      }
    
      #menu a{
        display: block;
        float: none;
        padding: 0;
        color: #999;
      }
    
      #menu a:hover{
        color: #fafafa;
      }	
    
      #menu ul a{
        padding: 0;
        width: auto;	
          
      }
    
      #menu ul a:hover{
        background: none;	
      }
    
      #menu ul li:first-child a:after,
      #menu ul ul li:first-child a:after {
        border: 0;
      }		
    
    }
    
    @media screen and (min-width: 600px) {
      #menu {
        display: block !important;
      }
    }	
    
    /* iPad */
    .no-transition {
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
      opacity: 1;
      visibility: visible;
      display: none;  		
    }
    
    #menu li:hover > .no-transition {
      display: block;
    }
}
}
}
.container {
  .head-con {
    float: left;
    width: 100%;
    @include upsure-xs {
      padding-top: 115px;
    }
    @include upsure-md {
      padding-top: 230px;
    }
    @include upsure-xl {
      padding-top: 100px;
    }
    h1.main-head {
      float: left;
      width: 100%;
        font-size: 80px;
        font-weight: 500;
        color: $white-color;
        text-align: center;
        margin: 0px;
        padding: 0px;
        line-height: 75px;
        // letter-spacing: 5px;
        @include upsure-xs {
          font-size: 2.5rem;
          line-height: 3rem;
        }
        @include upsure-md {
          font-size: 75px;
          line-height: initial;
        }
        @include upsure-lg {
          font-size: 60px;
          font-weight: 900;
          // letter-spacing: 0px;
        }
        .japan-ft-bt-txt {
          float: right;
          width: 100%;
          font-size: 25px;
          font-weight: normal;
          color: #81AFB3;
          text-align: right;
          letter-spacing: 0px;
          @include upsure-xs {
            padding-right: 0px;
            font-size: 20px;
            text-align: center;
          }
          @include upsure-md {
            font-size: 25px;
            padding-right: 0px;
            text-align: right;
          }
          @include upsure-lg {
            font-size: 25px;
            padding-right: 235px;
            text-align: right;
          }
        }
    }
    p.main-decs {
      float: left;
      width: 100%;
      font-weight: normal;
      color: #C77334;
      text-align: center;
      padding: 0px 0px;
      @include upsure-xs {
        font-size: 18px;
        padding: 20px 0px;
        width: 100%;
      }
      @include upsure-md {
        font-size: 21px;
        padding: 20px 0px;
      }
      @include upsure-lg {
        font-size: 21px;
        padding: 20px 0px;
      }
    }
  }
    .banner-btns {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row;
        width: 100%;
        @include upsure-xs {
          padding-top: 0px;
        }
        @include upsure-md {
          padding-top: 20px;
        }
        @include upsure-lg {
          padding-top: 20px;
        }
        .startnow-btn {
            flex: 0 auto;
            background: #46BFCA;
            font-weight: 400;
            color: $white-color;
            line-height: 24px;
            letter-spacing: -1.1%;
            text-decoration: none;
            border-radius: 6px;
            outline: none;
            border: none;
            padding: 16px 50px;
            margin: 10px 20px;
            @include upsure-xs {
              font-size: 14px;
              padding: 10px 15px;
              margin: 0.625rem;
            }
            @include upsure-md {
              font-size: 16px;
              padding: 16px 50px;
            }
            @include upsure-lg {
              font-size: 14px;
              padding: 14px 50px;
            }
        }
        .learnmore-btn {
            flex: 0 auto;
            background: $white-color;
            font-size: 16px;
            font-weight: 400;
            color: #1B818D;
            line-height: 24px;
            letter-spacing: -1.1%;
            text-decoration: none;
            border-radius: 6px;
            outline: none;
            border: none;
            @include upsure-xs {
              padding: 10px 15px;
              margin: 0.625rem;
              font-size: 14px;
            }
            @include upsure-md {
              padding: 16px 50px;
              margin: 10px 10px;
              font-size: 16px;
            }
            @include upsure-lg {
              padding: 14px 50px;
              margin: 10px 20px;
              font-size: 14px;
            }
        }
    }
    .banner-mobile-img {
      display: block;
      width: 100%;
      text-align: center;
      @include upsure-xs {
      }
      @include upsure-md {
      }
      @include upsure-lg {
      }
      img {
        width: 100%;
      }
    }
}
.main-banner-img {
    background: #0096A4;
    float: left;
    width: 100%;
    @include upsure-xs {
      min-height: 150px;
    }
    @include upsure-md {
      min-height: 380px;
    }
    @include upsure-lg {
      min-height: 300px;
    }
    .container {
        position: relative;
        .img-sec {
            position: absolute;
            top: -320px;
            left: 0px;
            right: 0px;
            text-align: center;
            @include upsure-xs {
              top: -60px;
            }
            @include upsure-md {
              top: -160px;
            }
            @include upsure-xl{
              top: -320px;
            }
            @media only screen and (min-width: 600px) and (max-width: 767px) {
              top: -320px;
            }
            img {
              width: 100%;
              text-align: center;
              @include upsure-xs {
                width: 90%;
              }
              @include upsure-md {
                width: 100%;
              }
              @include upsure-lg {
                width: 70%;
              }
            }    
        }
    }
}
// HOME PAGE HEADER CSS ENDS HERE

// INNER PAGE HEADER CSS STARTS HERE
.innerheader-main {
    // background: $primary-color;
    // background: #000;
    display: block;
    width: 100%;
    min-height: 82px;
    .header-wrapper{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        flex-flow: row;
      }
      .upsure-logo{
        img{
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
      .upsure-menu{
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        #menu, #menu ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }    
    #menu {
      // width: 460px;
      margin: 20px auto;
      border: 0px solid #222;
      
      // background-color: #111;
      // background-image: -moz-linear-gradient(#444, #111); 
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111));	
      // background-image: -webkit-linear-gradient(#444, #111);	
      // background-image: -o-linear-gradient(#444, #111);
      // background-image: -ms-linear-gradient(#444, #111);
      // background-image: linear-gradient(#444, #111);
      // -moz-border-radius: 6px;
      // -webkit-border-radius: 6px;
      // border-radius: 6px;
      // -moz-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      // -webkit-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      // box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
      }


    }    
    #menu:before,
    #menu:after {
      content: "";
      display: table;
    }    
    #menu:after {
      clear: both;
    }    
    #menu {
      zoom:1;
    }    
    #menu li {
      float: left;
      // border-right: px solid #222;
      // -moz-box-shadow: 1px 0 0 #444;
      // -webkit-box-shadow: 1px 0 0 #444;
      // box-shadow: 1px 0 0 #444;
      position: relative;
    }    
    #menu a {
      float: left;
      padding: 12px 30px;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      text-shadow: 0 0px 0 #000;
      margin: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      // @media only screen and (min-width: 769px) and (max-width: 1024px) {
      //   padding: 12px 10px;
      //   font-size: 14px;
      // }
      .fa{
          font-size: 20px;
          margin-left: 10px;
      }
    }    
    #menu li:hover > a {
      color: #fafafa;
    }    
    *html #menu li a:hover { /* IE6 only */
      color: #fafafa;
    }    
    #menu ul {
      margin: 20px 0 0 0;
      _margin: 0; /*IE6 only*/
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 38px;
      left: 0;
      z-index: 1;    
      background: #fff;
      width: 210px;
      // background: -moz-linear-gradient(#444, #111);
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111));
      // background: -webkit-linear-gradient(#444, #111);    
      // background: -o-linear-gradient(#444, #111);	
      // background: -ms-linear-gradient(#444, #111);	
      // background: linear-gradient(#444, #111);
      // -moz-box-shadow: 0 -1px rgba(255,255,255,.3);
      // -webkit-box-shadow: 0 -1px 0 rgba(255,255,255,.3);
      // box-shadow: 0 -1px 0 rgba(255,255,255,.3);	
      padding: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;  
      @media only screen and (min-width: 320px) and (max-width: 767px) {
      visibility: visible !important;
      }
    }    
    #menu li:hover > ul {
      opacity: 1;
      visibility: visible;
      margin: 0;
    }    
    #menu ul ul {
      top: 0;
      left: 150px;
      margin: 0 0 0 20px;
      _margin: 0; /*IE6 only*/
      // -moz-box-shadow: -1px 0 0 rgba(255,255,255,.3);
      // -webkit-box-shadow: -1px 0 0 rgba(255,255,255,.3);
      // box-shadow: -1px 0 0 rgba(255,255,255,.3);		
    }    
    #menu ul li {
      float: none;
      display: block;
      border: 0;
      _line-height: 0; /*IE6 only*/
      // -moz-box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      // -webkit-box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      // box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      color: #667477;
      padding-left: 5px;      
    }
    
    #menu ul li:last-child {   
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;    
    }
    
    #menu ul a {    
      padding: 10px;
      // width: 194px;
      _height: 10px; /*IE6 only*/
      display: block;
      white-space: nowrap;
      float: none;
      text-transform: none;
      // font-family: 'Segoe UI', sans-serif !important;
      font-family: 'Graphik';
      font-size: 15px;
      font-weight: 400;
      color: #667477;
      padding-left: 5px;
      @media only screen and (min-width: 320px) and (max-width: 768px) {
        color: #999;
      }
    }
    
    #menu ul a:hover {
      background-color: #EFF8F9;
      // font-family: 'Segoe UI', sans-serif !important;
      font-family: 'Graphik';
      font-size: 15px;
      font-weight: 400;
      color: #1B818D;
      border-left: solid 2px #1B818D;
      // width: 180px;
      display: block;
      // background-image: -moz-linear-gradient(#04acec,  #0186ba);	
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#04acec), to(#0186ba));
      // background-image: -webkit-linear-gradient(#04acec, #0186ba);
      // background-image: -o-linear-gradient(#04acec, #0186ba);
      // background-image: -ms-linear-gradient(#04acec, #0186ba);
      // background-image: linear-gradient(#04acec, #0186ba);
    }
    
    #menu ul li:first-child > a {
      -moz-border-radius: 3px 3px 0 0;
      -webkit-border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0 0;
    }
    
    #menu ul li:first-child > a:after {
      content: '';
      position: absolute;
      left: 40px;
      top: -16px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
    }
    
    #menu ul ul li:first-child a:after {
      left: -6px;
      top: 50%;
      margin-top: -6px;
      border-left: 0;	
      border-bottom: 6px solid transparent;
      border-top: 6px solid transparent;
      border-right: 6px solid #3b3b3b;
      color: #667477;
    }
    
    #menu ul li:first-child a:hover:after {
      border-bottom-color: #fff; 
    }
    
    #menu ul ul li:first-child a:hover:after {
      border-right-color: #fff; 
      border-bottom-color: transparent; 	
    }
    
    #menu ul li:last-child > a {
      -moz-border-radius: 0 0 3px 3px;
      -webkit-border-radius: 0 0 3px 3px;
      border-radius: 0 0 3px 3px;
    }
    
    /* Mobile */
    #menu-trigger {
      display: none;
    }
    
    @media screen and (max-width: 767px) {
    
      /* nav-wrap */
      #menu-wrap {
        position: relative;
      }
    
      #menu-wrap * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    
      /* menu icon */
      #menu-trigger {
        display: block; /* show menu icon */
        height: 40px;
        line-height: 40px;
        cursor: pointer;		
        padding: 0 0 0 35px;
        border: 1px solid #222;
        color: #fafafa;
        font-weight: bold;
        background-color: #111;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -moz-linear-gradient(#444, #111); 
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -webkit-linear-gradient(#444, #111);	
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -o-linear-gradient(#444, #111);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -ms-linear-gradient(#444, #111);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, linear-gradient(#444, #111);
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        -moz-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
        -webkit-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
        box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;


        @media only screen and (min-width: 320px) and (max-width: 768px) {
width: 90px;
right: 0px;
top: -40px;
position: absolute;
background-color: transparent;
background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center;
border: solid 0px;
box-shadow: none;

        }
      }
      
      /* main nav */
      #menu {
        margin: 0; padding: 10px;
        position: absolute;
        top: 40px;
        width: 300px;
        z-index: 1;
        background-color: #fff;
        display: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
        border-radius: 10px;
        right: 0px;
      }
    
      #menu:after {
        content: '';
        position: absolute;
        right: 25px;
        top: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
        @media only screen and (min-width: 320px) and (max-width: 768px) {
          right: 40px;
        }
      }	
    
      #menu ul {
        position: static;
        visibility: visible;
        opacity: 1;
        margin: 0;
        background: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;				
      }
    
      #menu ul ul {
        margin: 0 0 0 20px !important;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
      }
    
      #menu li {
        position: static;
        display: block;
        float: none;
        border: 0;
        margin: 5px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;			
      }
    
      #menu ul li{
        margin-left: 20px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
      }
    
      #menu a{
        display: block;
        float: none;
        padding: 0;
        color: #999;
      }
    
      #menu a:hover{
        color: #fafafa;
      }	
    
      #menu ul a{
        padding: 0;
        width: auto;	
          
      }
    
      #menu ul a:hover{
        background: none;	
      }
    
      #menu ul li:first-child a:after,
      #menu ul ul li:first-child a:after {
        border: 0;
      }		
    
    }
    
    @media screen and (min-width: 600px) {
      #menu {
        display: block !important;
      }
    }	
    
    /* iPad */
    .no-transition {
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
      opacity: 1;
      visibility: visible;
      display: none;  		
    }
    
    #menu li:hover > .no-transition {
      display: block;
    }
}
.web-menu{
  @include upsure-xs{
    display: none;
  }
  @include upsure-sm{
    display: block;
  }
  @include upsure-lg{
    display: block;
  }
  @include upsure-md{
    display: block;
  }
}
.mob-menu{
  @include upsure-xs{
    display: block;
  }
  @include upsure-sm{
    display: none;
  }
  @include upsure-lg{
    display: none;
  }
  @include upsure-md{
    display: none;
  }

  button.home-menu-btn {

    float: right;
  //   background-image: url('https://cdn.upsure.io/VBP/HealthIcons/header/menu_white.svg');
  //   background-position: center;
  //   background-size: contain;
  //   width: 28px;
  //   height: 21px;
  //   background-color:transparent;
    outline:none !important;
    border:none;
    border: 0px solid #cdcdcd !important;
    cursor:pointer;
    position: relative;
    background: transparent;
    width: 26px;
    height: 30px;
   span.top-line {
      background: #fff;
      float: right;
      width: 16px;
      height: 3px;
      margin-bottom: 8px;
      border-radius: 3px;
  }
  span.center-line {
      background: #fff;
      float: right;
      width: 24px;
      height: 3px;
      margin-bottom: 8px;
      border-radius: 3px;
  }
  span.bottom-line {
      background: #fff;
      float: right;
      width: 16px;
      height: 3px;
      margin-bottom: 0px;
  }
  }

  .sidepage {
    position:fixed;
    top: 0;
    right: -40vw;
    opacity:0;
    z-index: 999;
    // background-color: rgb(26, 22, 22);
    background: #000;
    color: $white-clr;
    // height: 100vh;
    height: 100%;
    width: 20vw;
    padding: 50px 10px;
    transition: all 500ms ease-in-out;
    overflow-y: scroll; /* Add the ability to scroll */
    -webkit-box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    -moz-box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    @include upsure-xs {
        right: -90vw;
        width: 85vw;
        padding: 50px 15px;
        overflow-x: hidden;
    }
    @include upsure-sm {
        width: 40vw;
    }
    @include upsure-md {
        width: 28vw;
        overflow-x: hidden;
    }
    @media screen and (min-width: 900px) and (max-width: 1400px) {
width: 33vw;
overflow-x: hidden;
    }


    .sidepage-close-button {
        position: absolute;
        width: 2.50rem;
        height: 2.50rem;
        text-align: center;
        cursor:pointer;
        color: $white-clr;
        top: 10px;
         right: 20px;
         i {
            float: left;
            font-size: 1.20rem;
            text-align: center;
            color: $white-clr;
         }
    }
    
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sidepage::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidepage {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.animateSidePage{
    opacity: 1;
    right: 0;
}  
.animateListItems{   
    animation: animate cubic-bezier(0, 0, 0.6, 1.24) 1s;
  animation-fill-mode:forwards;
}
/*************************/
animateListItem1{
  animation-delay: 0ms;
}
animateListItem2{
  animation-delay: 100ms;
}
animateListItem3{
  animation-delay: 200ms;
}
animateListItem4{
  animation-delay: 300ms;
}
animateListIte5{
  animation-delay: 1s;
}
/**********************/
@keyframes animate{
  to{
    transform:translate(0);
  }
}
.upsure-sublist{
  // background-color: transparent;    
  background-color: #000 !important; 
            float: left;
            width: 80%;
            margin: 0px 0px 0px 50px;
            padding: 0px 0px 20px 0px;
            font-size: 22px;
            border: solid 0px;
            @include upsure-xs{
              width: 70%;
            }
            @include upsure-sm{
              width: 70%;
            }
            a.custom-accordion-title{
              padding: 0.5rem 0rem;
              color: $white-clr !important;
              @include upsure-xs{
                @include flexbox();
                align-items: center;
                justify-content: space-between;
              }
              @include upsure-sm{
                @include flexbox();
                align-items: center;
                justify-content: space-between;
              }
              .fa{
                  font-size: 16px;
                }
              span.acc-heading{
                color: $white-clr !important;
                font-size: 18px;
                
                [aria-expanded="true"] i.fa-chevron-down {
                  -webkit-transform: rotate(180deg);
                  -moz-transform: rotate(180deg);
                  transform: rotate(180deg);
              }
              }
              
            }
  .collapse{
    background-color: #000 !important; 
    border: solid 0px;
    .card{
      background-color: #000 !important; 
      
    }
    .card-body{
      width: 100%;
                    // background-color: transparent;
  background-color: #000 !important; 
                    border: solid 0px;
                    padding: 0px 0px 0px 0px;
                    margin: 0px;
                    ul.sub-menu{
                      background-color: transparent !important;
                      list-style-type: none;
                      padding-left: 1rem;
                      li{
                        color: $white-clr !important;
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                        color: $white-clr !important;
                        a{
                          color: $white-clr !important;
                        }
                      }
                    }
                   
    }
    .card{
           --bs-card-cap-bg:#000 !important;
    }

    .accordion-button:not(.collapsed) {
      color: #0c63e4;
      background-color: #FEB7EE;
  }


  }
  .collapsing{
    .card{
      background-color: transparent;
      margin: 0px;
      padding: 0px;
      ul.sub-menu{
       
        list-style-type: none !important;
        padding-left: 1rem !important;
                      li{
                        color: $white-clr !important;
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                        color: $white-clr !important;
                        list-style-type: none !important;
                        a{
                          color: $white-clr !important;
                          font-size: 18px;
                        }
                       
                      }
                      li::marker {
                        color:$white-clr !important;
                        list-style-type: none !important;
                      }
      }
    }
  }
}

}




}
.altheader{
  // background-color: #1B818D !important;
  background-color: #000 !important;
  -webkit-box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
-moz-box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
@include upsure-xs {
  padding-bottom: 1rem;
}
}

}
// INNER PAGE HEADER CSS ENDS HERE


// CONTACT PAGE HEADER CSS STARTS HERE
.contactheader-main {
  background: $white-color;
  // display: block;
  float: left;
  width: 100%;
  min-height: 82px;
  // -webkit-box-shadow: 0 5px 6px -6px #555;
  // -moz-box-shadow: 0 5px 6px -6px #555;
  // box-shadow: 0 5px 6px -6px #555;
  -webkit-box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
-moz-box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
  .header-wrapper{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    .upsure-menu{
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        #menu, #menu ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }    
    #menu {
      width: 566px;
      margin: 20px auto;
      border: 0px solid #222;
      
      // background-color: #111;
      // background-image: -moz-linear-gradient(#444, #111); 
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111));	
      // background-image: -webkit-linear-gradient(#444, #111);	
      // background-image: -o-linear-gradient(#444, #111);
      // background-image: -ms-linear-gradient(#444, #111);
      // background-image: linear-gradient(#444, #111);
      // -moz-border-radius: 6px;
      // -webkit-border-radius: 6px;
      // border-radius: 6px;
      // -moz-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      // -webkit-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      // box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
      
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
      }


    }    
    #menu:before,
    #menu:after {
      content: "";
      display: table;
    }    
    #menu:after {
      clear: both;
    }    
    #menu {
      zoom:1;
    }    
    #menu li {
      float: left;
      // border-right: px solid #222;
      // -moz-box-shadow: 1px 0 0 #444;
      // -webkit-box-shadow: 1px 0 0 #444;
      // box-shadow: 1px 0 0 #444;
      position: relative;
    }    
    #menu a {
      float: left;
      padding: 12px 30px;
      color: #012932;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      text-shadow: 0 0px 0 #000;
      margin: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      // @media only screen and (min-width: 769px) and (max-width: 1024px) {
      //   padding: 12px 10px;
      //   font-size: 14px;
      // }
      .fa{
          font-size: 20px;
          margin-left: 10px;
      }
    }    

    .menu-container .manu ul li a{
      display: block;
      padding: 10px 25px;
      color: #012932;
      font-size: 15px;
      transition: .2s;
      text-decoration: none;
      //font-weight: 600;

      @media only screen and (min-width: 320px) and (max-width: 767px) {
        color: $white-color;
      }


    }


    #menu li:hover > a {
      color: #667477;
    }    
    *html #menu li a:hover { /* IE6 only */
      color: #fafafa;
    }    
    #menu ul {
      margin: 20px 0 0 0;
      _margin: 0; /*IE6 only*/
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 38px;
      left: 0;
      z-index: 1;    
      background: #fff;
      width: 210px;
      // background: -moz-linear-gradient(#444, #111);
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111));
      // background: -webkit-linear-gradient(#444, #111);    
      // background: -o-linear-gradient(#444, #111);	
      // background: -ms-linear-gradient(#444, #111);	
      // background: linear-gradient(#444, #111);
      // -moz-box-shadow: 0 -1px rgba(255,255,255,.3);
      // -webkit-box-shadow: 0 -1px 0 rgba(255,255,255,.3);
      // box-shadow: 0 -1px 0 rgba(255,255,255,.3);	
      padding: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;  
      @media only screen and (min-width: 320px) and (max-width: 767px) {
      visibility: visible !important;
      }
    }    
    #menu li:hover > ul {
      opacity: 1;
      visibility: visible;
      margin: 0;
    }    
    #menu ul ul {
      top: 0;
      left: 150px;
      margin: 0 0 0 20px;
      _margin: 0; /*IE6 only*/
      // -moz-box-shadow: -1px 0 0 rgba(255,255,255,.3);
      // -webkit-box-shadow: -1px 0 0 rgba(255,255,255,.3);
      // box-shadow: -1px 0 0 rgba(255,255,255,.3);		
    }    
    #menu ul li {
      float: none;
      display: block;
      border: 0;
      _line-height: 0; /*IE6 only*/
      // -moz-box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      // -webkit-box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      // box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      color: #667477;
      padding-left: 5px;      
    }
    
    #menu ul li:last-child {   
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;    
    }
    
    #menu ul a {    
      padding: 10px;
      // width: 194px;
      _height: 10px; /*IE6 only*/
      display: block;
      white-space: nowrap;
      float: none;
      text-transform: none;
      // font-family: 'Segoe UI', sans-serif !important;
      font-family: 'Graphik';
      font-size: 15px;
      font-weight: 400;
      color: #667477;
      padding-left: 5px;
      @media only screen and (min-width: 320px) and (max-width: 768px) {
        color: #999;
      }
    }
    
    #menu ul a:hover {
      background-color: #EFF8F9;
      // font-family: 'Segoe UI', sans-serif !important;
      font-family: 'Graphik';
      font-size: 15px;
      font-weight: 400;
      color: #1B818D;
      border-left: solid 2px #1B818D;
      // width: 180px;
      display: block;
      // background-image: -moz-linear-gradient(#04acec,  #0186ba);	
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#04acec), to(#0186ba));
      // background-image: -webkit-linear-gradient(#04acec, #0186ba);
      // background-image: -o-linear-gradient(#04acec, #0186ba);
      // background-image: -ms-linear-gradient(#04acec, #0186ba);
      // background-image: linear-gradient(#04acec, #0186ba);
    }
    
    #menu ul li:first-child > a {
      -moz-border-radius: 3px 3px 0 0;
      -webkit-border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0 0;
    }
    
    #menu ul li:first-child > a:after {
      content: '';
      position: absolute;
      left: 40px;
      top: -16px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
    }
    
    #menu ul ul li:first-child a:after {
      left: -6px;
      top: 50%;
      margin-top: -6px;
      border-left: 0;	
      border-bottom: 6px solid transparent;
      border-top: 6px solid transparent;
      border-right: 6px solid #3b3b3b;
      color: #667477;
    }
    
    #menu ul li:first-child a:hover:after {
      border-bottom-color: #fff; 
    }
    
    #menu ul ul li:first-child a:hover:after {
      border-right-color: #fff; 
      border-bottom-color: transparent; 	
    }
    
    #menu ul li:last-child > a {
      -moz-border-radius: 0 0 3px 3px;
      -webkit-border-radius: 0 0 3px 3px;
      border-radius: 0 0 3px 3px;
    }
    
    /* Mobile */
    #menu-trigger {
      display: none;
    }
    
    @media screen and (max-width: 767px) {
    
      /* nav-wrap */
      #menu-wrap {
        position: relative;
      }
    
      #menu-wrap * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    
      /* menu icon */
      #menu-trigger {
        display: block; /* show menu icon */
        height: 40px;
        line-height: 40px;
        cursor: pointer;		
        padding: 0 0 0 35px;
        border: 1px solid #222;
        color: #fafafa;
        font-weight: bold;
        background-color: #111;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -moz-linear-gradient(#444, #111); 
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -webkit-linear-gradient(#444, #111);	
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -o-linear-gradient(#444, #111);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -ms-linear-gradient(#444, #111);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, linear-gradient(#444, #111);
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        -moz-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
        -webkit-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
        box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;


        @media only screen and (min-width: 320px) and (max-width: 768px) {
width: 90px;
right: 0px;
top: -40px;
position: absolute;
background-color: transparent;
background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center;
border: solid 0px;
box-shadow: none;

        }
      }
      
      /* main nav */
      #menu {
        margin: 0; padding: 10px;
        position: absolute;
        top: 40px;
        width: 300px;
        z-index: 1;
        background-color: #fff;
        display: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
        border-radius: 10px;
        right: 0px;
      }
    
      #menu:after {
        content: '';
        position: absolute;
        right: 25px;
        top: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
        @media only screen and (min-width: 320px) and (max-width: 768px) {
          right: 40px;
        }
      }	
    
      #menu ul {
        position: static;
        visibility: visible;
        opacity: 1;
        margin: 0;
        background: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;				
      }
    
      #menu ul ul {
        margin: 0 0 0 20px !important;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
      }
    
      #menu li {
        position: static;
        display: block;
        float: none;
        border: 0;
        margin: 5px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;			
      }
    
      #menu ul li{
        margin-left: 20px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
      }
    
      #menu a{
        display: block;
        float: none;
        padding: 0;
        color: #999;
      }
    
      #menu a:hover{
        color: #fafafa;
      }	
    
      #menu ul a{
        padding: 0;
        width: auto;	
          
      }
    
      #menu ul a:hover{
        background: none;	
      }
    
      #menu ul li:first-child a:after,
      #menu ul ul li:first-child a:after {
        border: 0;
      }		
    
    }
    
    @media screen and (min-width: 600px) {
      #menu {
        display: block !important;
      }
    }	
    
    /* iPad */
    .no-transition {
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
      opacity: 1;
      visibility: visible;
      display: none;  		
    }
    
    #menu li:hover > .no-transition {
      display: block;
    }

    .nav_menu_toggler_icon span{
      @media only screen and (min-width: 320px) and (max-width: 767px) {
        color: $text-color;
      }
    }

}

.web-menu{
  @include upsure-xs{
    display: none;
  }
  @include upsure-sm{
    display: block;
  }
  @include upsure-lg{
    display: block;
  }
  @include upsure-md{
    display: block;
  }
}
.mob-menu{
  @include upsure-xs{
    display: block;
  }
  @include upsure-sm{
    display: none;
  }
  @include upsure-lg{
    display: none;
  }
  @include upsure-md{
    display: none;
  }

  button.home-menu-btn {

    float: right;
  //   background-image: url('https://cdn.upsure.io/VBP/HealthIcons/header/menu_white.svg');
  //   background-position: center;
  //   background-size: contain;
  //   width: 28px;
  //   height: 21px;
  //   background-color:transparent;
    outline:none !important;
    border:none;
    border: 0px solid #cdcdcd !important;
    cursor:pointer;
    position: relative;
    background: transparent;
    width: 26px;
    height: 30px;
   span.top-line {
      background: #000;
      float: right;
      width: 16px;
      height: 3px;
      margin-bottom: 8px;
      border-radius: 3px;
  }
  span.center-line {
      background: #000;
      float: right;
      width: 24px;
      height: 3px;
      margin-bottom: 8px;
      border-radius: 3px;
  }
  span.bottom-line {
      background: #000;
      float: right;
      width: 16px;
      height: 3px;
      margin-bottom: 0px;
  }
  }

  .sidepage {
    position:fixed;
    top: 0;
    right: -40vw;
    opacity:0;
    z-index: 999;
    // background-color: rgb(26, 22, 22);
    background: #000;
    color: $white-clr;
    // height: 100vh;
    height: 100%;
    width: 20vw;
    padding: 50px 10px;
    transition: all 500ms ease-in-out;
    overflow-y: scroll; /* Add the ability to scroll */
    -webkit-box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    -moz-box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    @include upsure-xs {
        right: -90vw;
        width: 85vw;
        padding: 50px 15px;
        overflow-x: hidden;
    }
    @include upsure-sm {
        width: 40vw;
    }
    @include upsure-md {
        width: 28vw;
        overflow-x: hidden;
    }
    @media screen and (min-width: 900px) and (max-width: 1400px) {
width: 33vw;
overflow-x: hidden;
    }


    .sidepage-close-button {
        position: absolute;
        width: 2.50rem;
        height: 2.50rem;
        text-align: center;
        cursor:pointer;
        color: $white-clr;
        top: 10px;
         right: 20px;
         i {
            float: left;
            font-size: 1.20rem;
            text-align: center;
            color: $white-clr;
         }
    }
    
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sidepage::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidepage {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.animateSidePage{
    opacity: 1;
    right: 0;
}  
.animateListItems{   
    animation: animate cubic-bezier(0, 0, 0.6, 1.24) 1s;
  animation-fill-mode:forwards;
}
/*************************/
animateListItem1{
  animation-delay: 0ms;
}
animateListItem2{
  animation-delay: 100ms;
}
animateListItem3{
  animation-delay: 200ms;
}
animateListItem4{
  animation-delay: 300ms;
}
animateListIte5{
  animation-delay: 1s;
}
/**********************/
@keyframes animate{
  to{
    transform:translate(0);
  }
}
.upsure-sublist{
  // background-color: transparent;    
  background-color: #000 !important; 
            float: left;
            width: 80%;
            margin: 0px 0px 0px 50px;
            padding: 0px 0px 20px 0px;
            font-size: 22px;
            border: solid 0px;
            @include upsure-xs{
              width: 70%;
            }
            @include upsure-sm{
              width: 70%;
            }
            a.custom-accordion-title{
              padding: 0.5rem 0rem;
              color: $white-clr !important;
              @include upsure-xs{
                @include flexbox();
                align-items: center;
                justify-content: space-between;
              }
              @include upsure-sm{
                @include flexbox();
                align-items: center;
                justify-content: space-between;
              }
              .fa{
                  font-size: 16px;
                }
              span.acc-heading{
                color: $white-clr !important;
                font-size: 18px;
                
                [aria-expanded="true"] i.fa-chevron-down {
                  -webkit-transform: rotate(180deg);
                  -moz-transform: rotate(180deg);
                  transform: rotate(180deg);
              }
              }
              
            }
  .collapse{
    background-color: #000 !important; 
    border: solid 0px;
    .card{
      background-color: #000 !important; 
      
    }
    .card-body{
      width: 100%;
                    // background-color: transparent;
  background-color: #000 !important; 
                    border: solid 0px;
                    padding: 0px 0px 0px 0px;
                    margin: 0px;
                    ul.sub-menu{
                      background-color: transparent !important;
                      list-style-type: none;
                      padding-left: 1rem;
                      li{
                        color: $white-clr !important;
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                        color: $white-clr !important;
                        a{
                          color: $white-clr !important;
                        }
                      }
                    }
                   
    }
    .card{
           --bs-card-cap-bg:#000 !important;
    }

    .accordion-button:not(.collapsed) {
      color: #0c63e4;
      background-color: #FEB7EE;
  }


  }
  .collapsing{
    .card{
      background-color: transparent;
      margin: 0px;
      padding: 0px;
      ul.sub-menu{
       
        list-style-type: none !important;
        padding-left: 1rem !important;
                      li{
                        color: $white-clr !important;
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                        color: $white-clr !important;
                        list-style-type: none !important;
                        a{
                          color: $white-clr !important;
                          font-size: 18px;
                        }
                       
                      }
                      li::marker {
                        color:$white-clr !important;
                        list-style-type: none !important;
                      }
      }
    }
  }
}

}



}

.altheader{
  background-color: #fff !important;
  -webkit-box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
-moz-box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
box-shadow: 0px 5px 5px -6px rgba(0,0,0,0.43);
}


}


.hm-header {
  display: block;
  width: 100%;
  background-image: url('https://cdn.upsure.io/newui2023/homepage/homepage_banner_02.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  // background: #14110C;
  // background: linear-gradient(45deg, #14110C, #1C1812);
  min-height: 1045px;
  @include upsure-xs {
    min-height: 1045px;
  }
  @include upsure-md {
    min-height: 870px;
  }
  @include upsure-lg {
    min-height: 1045px;
  }
    
    .header-wrapper{
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      .upsure-logo{
        img{
          margin-top: 10px;
          // @include upsure-sm {
          //   width: 80%;
          //   margin-top: 0px;
          // }
        }
      }
      .upsure-menu{
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        #menu, #menu ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }    
    #menu {
      width: 566px;
      margin: 20px auto;
      border: 0px solid #222;      
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        width: 100%;
      }


    }    
    #menu:before,
    #menu:after {
      content: "";
      display: table;
    }    
    #menu:after {
      clear: both;
    }    
    #menu {
      zoom:1;
    }    
    #menu li {
      float: left;
      // border-right: px solid #222;
      // -moz-box-shadow: 1px 0 0 #444;
      // -webkit-box-shadow: 1px 0 0 #444;
      // box-shadow: 1px 0 0 #444;
      position: relative;
    }    
    #menu a {
      float: left;
      padding: 12px 30px;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
      text-shadow: 0 0px 0 #000;
      margin: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      // @media only screen and (min-width: 769px) and (max-width: 1024px) {
      //   padding: 12px 10px;
      //   font-size: 14px;
      // }
      .fa{
          font-size: 20px;
          margin-left: 10px;
      }
    }    
    #menu li:hover > a {
      color: #fafafa;
    }    
    *html #menu li a:hover { /* IE6 only */
      color: #fafafa;
    }    
    #menu ul {
      margin: 20px 0 0 0;
      _margin: 0; /*IE6 only*/
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 38px;
      left: 0;
      z-index: 1;    
      background: #fff;
      width: 210px;
      // background: -moz-linear-gradient(#444, #111);
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#444), to(#111));
      // background: -webkit-linear-gradient(#444, #111);    
      // background: -o-linear-gradient(#444, #111);	
      // background: -ms-linear-gradient(#444, #111);	
      // background: linear-gradient(#444, #111);
      // -moz-box-shadow: 0 -1px rgba(255,255,255,.3);
      // -webkit-box-shadow: 0 -1px 0 rgba(255,255,255,.3);
      // box-shadow: 0 -1px 0 rgba(255,255,255,.3);	
      padding: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;  
      @media only screen and (min-width: 320px) and (max-width: 767px) {
      visibility: visible !important;
      }
    }    
    #menu li:hover > ul {
      opacity: 1;
      visibility: visible;
      margin: 0;
    }    
    #menu ul ul {
      top: 0;
      left: 150px;
      margin: 0 0 0 20px;
      _margin: 0; /*IE6 only*/
      // -moz-box-shadow: -1px 0 0 rgba(255,255,255,.3);
      // -webkit-box-shadow: -1px 0 0 rgba(255,255,255,.3);
      // box-shadow: -1px 0 0 rgba(255,255,255,.3);		
    }    
    #menu ul li {
      float: none;
      display: block;
      border: 0;
      _line-height: 0; /*IE6 only*/
      // -moz-box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      // -webkit-box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      // box-shadow: 0 1px 0 #111, 0 2px 0 #666;
      color: #667477;
      padding-left: 5px;      
    }
    
    #menu ul li:last-child {   
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;    
    }
    
    #menu ul a {    
      padding: 10px;
      // width: 194px;
      _height: 10px; /*IE6 only*/
      display: block;
      white-space: nowrap;
      float: none;
      text-transform: none;
      // font-family: 'Segoe UI', sans-serif !important;
      font-family: 'Graphik';
      font-size: 15px;
      font-weight: 400;
      color: #667477;
      padding-left: 5px;
      @media only screen and (min-width: 320px) and (max-width: 768px) {
        color: #999;
      }
    }
    
    #menu ul a:hover {
      background-color: #EFF8F9;
      // font-family: 'Segoe UI', sans-serif !important;
      font-family: 'Graphik';
      font-size: 15px;
      font-weight: 400;
      color: #1B818D;
      border-left: solid 2px #1B818D;
      // width: 180px;
      display: block;
      // background-image: -moz-linear-gradient(#04acec,  #0186ba);	
      // background-image: -webkit-gradient(linear, left top, left bottom, from(#04acec), to(#0186ba));
      // background-image: -webkit-linear-gradient(#04acec, #0186ba);
      // background-image: -o-linear-gradient(#04acec, #0186ba);
      // background-image: -ms-linear-gradient(#04acec, #0186ba);
      // background-image: linear-gradient(#04acec, #0186ba);
    }
    
    #menu ul li:first-child > a {
      -moz-border-radius: 3px 3px 0 0;
      -webkit-border-radius: 3px 3px 0 0;
      border-radius: 3px 3px 0 0;
    }
    
    #menu ul li:first-child > a:after {
      content: '';
      position: absolute;
      left: 40px;
      top: -16px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
    }
    
    #menu ul ul li:first-child a:after {
      left: -6px;
      top: 50%;
      margin-top: -6px;
      border-left: 0;	
      border-bottom: 6px solid transparent;
      border-top: 6px solid transparent;
      border-right: 6px solid #3b3b3b;
      color: #667477;
    }
    
    #menu ul li:first-child a:hover:after {
      border-bottom-color: #fff; 
    }
    
    #menu ul ul li:first-child a:hover:after {
      border-right-color: #fff; 
      border-bottom-color: transparent; 	
    }
    
    #menu ul li:last-child > a {
      -moz-border-radius: 0 0 3px 3px;
      -webkit-border-radius: 0 0 3px 3px;
      border-radius: 0 0 3px 3px;
    }
    
    /* Mobile */
    #menu-trigger {
      display: none;
    }
    
    @media screen and (max-width: 767px) {
    
      /* nav-wrap */
      #menu-wrap {
        position: relative;
      }
    
      #menu-wrap * {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    
      /* menu icon */
      #menu-trigger {
        display: block; /* show menu icon */
        height: 40px;
        line-height: 40px;
        cursor: pointer;		
        padding: 0 0 0 35px;
        border: 1px solid #222;
        color: #fafafa;
        font-weight: bold;
        background-color: #111;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -moz-linear-gradient(#444, #111); 
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -webkit-linear-gradient(#444, #111);	
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -o-linear-gradient(#444, #111);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, -ms-linear-gradient(#444, #111);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center, linear-gradient(#444, #111);
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        border-radius: 6px;
        -moz-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
        -webkit-box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;
        box-shadow: 0 1px 1px #777, 0 1px 0 #666 inset;


        @media only screen and (min-width: 320px) and (max-width: 768px) {
width: 90px;
right: 0px;
top: -40px;
position: absolute;
background-color: transparent;
background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPCAMAAADeWG8gAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjE2QjAxNjRDOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjE2QjAxNjREOUNEOTExRTE4RTNFRkI1RDQ2MUYxOTQ3Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTZCMDE2NEE5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MTZCMDE2NEI5Q0Q5MTFFMThFM0VGQjVENDYxRjE5NDciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz42AEtnAAAABlBMVEX///////9VfPVsAAAAAnRSTlP/AOW3MEoAAAAWSURBVHjaYmAgFzBiACKFho6NAAEGAD07AG1pn932AAAAAElFTkSuQmCC) no-repeat 10px center;
border: solid 0px;
box-shadow: none;

        }
      }
      
      /* main nav */
      #menu {
        margin: 0; padding: 10px;
        position: absolute;
        top: 40px;
        width: 300px;
        z-index: 1;
        background-color: #fff;
        display: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
        border-radius: 10px;
        right: 0px;
      }
    
      #menu:after {
        content: '';
        position: absolute;
        right: 25px;
        top: -8px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
        @media only screen and (min-width: 320px) and (max-width: 768px) {
          right: 40px;
        }
      }	
    
      #menu ul {
        position: static;
        visibility: visible;
        opacity: 1;
        margin: 0;
        background: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;				
      }
    
      #menu ul ul {
        margin: 0 0 0 20px !important;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
      }
    
      #menu li {
        position: static;
        display: block;
        float: none;
        border: 0;
        margin: 5px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;			
      }
    
      #menu ul li{
        margin-left: 20px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;		
      }
    
      #menu a{
        display: block;
        float: none;
        padding: 0;
        color: #999;
      }
    
      #menu a:hover{
        color: #fafafa;
      }	
    
      #menu ul a{
        padding: 0;
        width: auto;	
          
      }
    
      #menu ul a:hover{
        background: none;	
      }
    
      #menu ul li:first-child a:after,
      #menu ul ul li:first-child a:after {
        border: 0;
      }		
    
    }
    
    @media screen and (min-width: 600px) {
      #menu {
        display: block !important;
      }
    }	
    
    /* iPad */
    .no-transition {
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
      opacity: 1;
      visibility: visible;
      display: none;  		
    }
    
    #menu li:hover > .no-transition {
      display: block;
    }
}
.web-menu{
  @include upsure-xs{
    display: none;
  }
  @include upsure-sm{
    display: block;
  }
  @include upsure-lg{
    display: block;
  }
  @include upsure-md{
    display: block;
  }
}
.mob-menu{
  @include upsure-xs{
    display: block;
  }
  @include upsure-sm{
    display: none;
  }
  @include upsure-lg{
    display: none;
  }
  @include upsure-md{
    display: none;
  }

  button.home-menu-btn {

    float: right;
  //   background-image: url('https://cdn.upsure.io/VBP/HealthIcons/header/menu_white.svg');
  //   background-position: center;
  //   background-size: contain;
  //   width: 28px;
  //   height: 21px;
  //   background-color:transparent;
    outline:none !important;
    border:none;
    border: 0px solid #cdcdcd !important;
    cursor:pointer;
    position: relative;
    background: transparent;
    width: 26px;
    height: 30px;
   span.top-line {
      background: #fff;
      float: right;
      width: 16px;
      height: 3px;
      margin-bottom: 8px;
      border-radius: 3px;
  }
  span.center-line {
      background: #fff;
      float: right;
      width: 24px;
      height: 3px;
      margin-bottom: 8px;
      border-radius: 3px;
  }
  span.bottom-line {
      background: #fff;
      float: right;
      width: 16px;
      height: 3px;
      margin-bottom: 0px;
  }
  }

  .sidepage {
    position:fixed;
    top: 0;
    right: -40vw;
    opacity:0;
    z-index: 999;
    // background-color: rgb(26, 22, 22);
    background: #000;
    color: $white-clr;
    // height: 100vh;
    height: 100%;
    width: 20vw;
    padding: 50px 10px;
    transition: all 500ms ease-in-out;
    overflow-y: scroll; /* Add the ability to scroll */
    -webkit-box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    -moz-box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    box-shadow: -10px -1px 26px -1px rgba(0,0,0,0.32);
    @include upsure-xs {
        right: -90vw;
        width: 85vw;
        padding: 50px 15px;
        overflow-x: hidden;
    }
    @include upsure-sm {
        width: 40vw;
    }
    @include upsure-md {
        width: 28vw;
        overflow-x: hidden;
    }
    @media screen and (min-width: 900px) and (max-width: 1400px) {
width: 33vw;
overflow-x: hidden;
    }


    .sidepage-close-button {
        position: absolute;
        width: 2.50rem;
        height: 2.50rem;
        text-align: center;
        cursor:pointer;
        color: $white-clr;
        top: 10px;
         right: 20px;
         i {
            float: left;
            font-size: 1.20rem;
            text-align: center;
            color: $white-clr;
         }
    }
    
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sidepage::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidepage {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.animateSidePage{
    opacity: 1;
    right: 0;
}  
.animateListItems{   
    animation: animate cubic-bezier(0, 0, 0.6, 1.24) 1s;
  animation-fill-mode:forwards;
}
/*************************/
animateListItem1{
  animation-delay: 0ms;
}
animateListItem2{
  animation-delay: 100ms;
}
animateListItem3{
  animation-delay: 200ms;
}
animateListItem4{
  animation-delay: 300ms;
}
animateListIte5{
  animation-delay: 1s;
}
/**********************/
@keyframes animate{
  to{
    transform:translate(0);
  }
}
.upsure-sublist{
  // background-color: transparent;    
  background-color: #000 !important; 
            float: left;
            width: 80%;
            margin: 0px 0px 0px 50px;
            padding: 0px 0px 20px 0px;
            font-size: 22px;
            border: solid 0px;
            @include upsure-xs{
              width: 70%;
            }
            @include upsure-sm{
              width: 70%;
            }
            a.custom-accordion-title{
              padding: 0.5rem 0rem;
              color: $white-clr !important;
              @include upsure-xs{
                @include flexbox();
                align-items: center;
                justify-content: space-between;
              }
              @include upsure-sm{
                @include flexbox();
                align-items: center;
                justify-content: space-between;
              }
              .fa{
                  font-size: 16px;
                }
              span.acc-heading{
                color: $white-clr !important;
                font-size: 18px;
                
                [aria-expanded="true"] i.fa-chevron-down {
                  -webkit-transform: rotate(180deg);
                  -moz-transform: rotate(180deg);
                  transform: rotate(180deg);
              }
              }
              
            }
  .collapse{
    background-color: #000 !important; 
    border: solid 0px;
    .card{
      background-color: #000 !important; 
      
    }
    .card-body{
      width: 100%;
                    // background-color: transparent;
  background-color: #000 !important; 
                    border: solid 0px;
                    padding: 0px 0px 0px 0px;
                    margin: 0px;
                    ul.sub-menu{
                      background-color: transparent !important;
                      list-style-type: none;
                      padding-left: 1rem;
                      li{
                        color: $white-clr !important;
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                        color: $white-clr !important;
                        a{
                          color: $white-clr !important;
                        }
                      }
                    }
                   
    }
    .card{
           --bs-card-cap-bg:#000 !important;
    }

    .accordion-button:not(.collapsed) {
      color: #0c63e4;
      background-color: #FEB7EE;
  }


  }
  .collapsing{
    .card{
      background-color: transparent;
      margin: 0px;
      padding: 0px;
      ul.sub-menu{
       
        list-style-type: none !important;
        padding-left: 1rem !important;
                      li{
                        color: $white-clr !important;
                        font-size: 18px;
                        padding-bottom: 0.5rem;
                        color: $white-clr !important;
                        list-style-type: none !important;
                        a{
                          color: $white-clr !important;
                          font-size: 18px;
                        }
                       
                      }
                      li::marker {
                        color:$white-clr !important;
                        list-style-type: none !important;
                      }
      }
    }
  }
}

}
}
}
// INNER PAGE HEADER CSS ENDS HERE


.altheader{
  background: rgba(0,0,0,0.9);
  @include upsure-xs {
    padding-bottom: 1rem;
  }

  // .upsure-menu #menu li:hover > a{
  //   color: #fff !important;
  // }

  // .upsure-menu #menu li a{
  //   color: #fff !important;
  // }
 
}

.inn-altheader{
  background: rgba(0,0,0,0.9) !important;
 
}

.hmpage-fixed-header{
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
  @include upsure-xs {
    padding: 10px 0px;
  }
 

}



/********new menu  date 15-05-2023*********/


.menu-container{
	width:100%;
	display: none;
	z-index: 100;
	margin: 0 auto 0 auto;
	//background-image: linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.2),rgba(0,0,0,.0))
}
.menu-container:after,
.menu-container:before{
    content: "";
    clear: both;
}
.menu-container:after{
    display: table;
}
.menu-container .manu{
	// width:95%;
	display: block;
	position: relative;
	margin: 0 auto 0 auto;
}
.menu ul{
}
.menu ul:before,
.menu ul:after {
    content: "";
    display: table;
}
.menu ul:after {
    clear: both;
}
.menu:before,
.menu:after {
    content: "";
    display: table;
}
.menu:after {
    clear: both;
}
.menu ul li ul:before,
.menu ul li ul:after {
    content: "";
    display: table;
}
.menu ul li ul:after {
    clear: both;
}
.menu-container .manu ul li {
	float: left;
	background-color: transparent;
  list-style-type: none;
}
.menu-container .manu ul li a {
	display: block;
	font-size: 14px;
  font-weight: 400;
	color: #fff;
	transition: .2s;
  text-decoration: none;
  @include upsure-md {
    padding: 7px 8px !important;
    font-size: 14px !important;
  }
  @include upsure-lg {
    padding: 7px 15px !important;
  }
}
.menu-container .manu ul li a span{
	transition: .3s all ease-out;
	line-height: 0px;
}
.menu-container .manu ul li a:hover{
	color:#fff;
}
.menu-container .manu ul li a:hover span{
	transform: rotate(180deg);
	padding-right: 5px;
}
.menu-container .manu ul li a:hover{
	border-bottom: 0px solid #fff;
}
span.drop_down_icon{
	color:#fff;
	padding-left: 5px;
	font-size: 20px;
	position: absolute;
	margin-top: 11px;
}
.menu-container .manu ul li .have_dropdown{
	background-color:#fff;
	position: absolute;
/*	left: 0;*/
	display: none;
	border-radius: 10px;
  padding: 10px 0px 9px 0px;
	width: 30%;
  box-shadow:0px 0px 2px #888888;
}
.menu-container .manu ul li .have_dropdown li{
	width:100%;
	// float:none;
  list-style-type: none;
}

.menu-container .manu ul li .have_dropdown li a:after{
	content: "";
	position: absolute;
	left: 40px;
	top: -6px;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #fff;
	
}
.menu-container .manu ul li .have_dropdown_n_style{
	background-color: transparent;
	position: absolute;
	left: 0;
	display: none;
	width: 100%;
}
.menu-container .manu ul li .have_dropdown_n_style li a{
	color: #0076db;
	border-bottom: 1px solid #fff;
}
.menu-container .manu ul li .have_dropdown_n_style li a:hover{
	font-weight: bold;
}
.menu-container .manu ul li .have_dropdown_n_style li ul li a{
	color: #285284;
	border-bottom: none;
}
.menu-container .manu ul li .have_dropdown_n_style li ul li a:hover{
	color: #0076db;
	font-weight: normal;
	background-color: #fafafa;
	text-decoration: underline;
}
.menu-container .manu ul li .have_dropdown_n_style li{
	width:100%;
	float:left;
}
.menu-container .manu ul li .have_dropdown_n_style{
	background-color: #eee;
}
.menu-container .manu ul li .have_dropdown li a {
	width: 100%;
	text-align: left;
	float: left;
  font-size: 12px;
	color:#012932;
  padding: 3px 15px;
	background-color: #fff;
	font-weight: normal;
  border-left: 2px solid #fff;
}
.menu-container .manu ul li .have_dropdown li a:hover{
	// background-color: #FAFAFA;
	// color: #14a0f9;
  background-color: #EFF8F9;
  font-size: 12px;
  font-weight: 400;
  color: #1B818D;
  border-left: solid 2px #1B818D;
  display: block;

}
.menu-container .manu ul li .have_dropdown li ul li{
	display: none;
}
.menu-container .manu ul li .have_dropdown_n_style li ul li{
	background-color: #fff;
	height: auto;
	float: none;
	display: block;
	width: 100%;
}
.nav_menu_toggler_icon{
	cursor: pointer;
	position: absolute;
	right: 5%;
	top: 3%;
	background: none;
	color: #fff;
	font-size: 25px;
	z-index: 150;
	display: none;
	border: none;
}
.nav_menu_toggler_icon span{
	padding: 13px 7px;
	border-radius: 8px;
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    //color: $text-color;
  }
  @include upsure-xs {
    padding-top: 23px;
  }
}
.nav_menu_toggler_icon:active{
	color: #ddd;
}
.container_help{
	text-align: center;
	margin-top: 150px;
}
.container_help h2,.container_help h1{
	color: #fff;
	font-size: 30px;
	font-weight: normal;
}
@media only screen and (max-width: 756px) {
	.nav_menu_toggler_icon{
		display:block;
	}
	.menu-container{
		background: rgba(0, 0, 0, 0.9);
	}
	.menu-container .manu{
		display: none;
	}
	.menu-container .manu ul li{
		float: none;
		display: block;
	}
	.menu-container .manu ul li .have_dropdown{
		background-color:#fff;
		display: none;
		position: relative;
		padding: 20px 0px 2px 0px;
		border-radius: 10px;
    width: 90%;
    visibility: visible !important;
    margin-left: 5%;
	}
	.menu-container .manu ul li .have_dropdown li{
		display: block;
		float: none;
		width: 100%;
		background-color: #fff;
	}
	.menu-container .manu ul li .have_dropdown li a{
		display: block;
		float: none;
		width: 100%;
		padding: 15px 0px;
    color: #012932 !important;
	}
	.menu-container .manu ul li .have_dropdown_n_style li{
		width:100%;
		float: none;
	}
	.menu-container .manu ul li .have_dropdown_n_style li a{
		float: none;
	}
	.menu-container .manu ul li .have_dropdown_n_style{
		position: relative;
	}

  .menu-container .manu ul li .have_dropdown li a:hover{
    padding-left: 5px;
  }

}
