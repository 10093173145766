@import "themes.scss";
@import 'mixins';
.whoweare-banner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/whatwedo/why_we_do.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: left;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
            }
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: left;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
  .whatwedo-bdy {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 464px 0px 0px;
    .whatwedo-flex {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        width: 100%;
        margin: 150px 0px 150px;
        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            margin: 50px 0px 100px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);
            margin: 50px 0px 100px;        
        }
        .item-bx {
            width: 421px;
            min-height: 286px;
            position: relative;
            border-radius: 10px;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
            .arrowbg {
                background-image: url("https://cdn.upsure.io/newui2023/team_arrow_bg.svg");
                background-repeat: no-repeat;
                background-position: left bottom;
                background-size: contain;
                width: 251px;
                height: 146px;
                position: absolute;
                left: 0px;
                bottom: 0px;
            }
            .team-dec-flex {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
            }
            .wwdo-dec-flex {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                .wwdo-img {
                    flex: 0 auto;
                    padding: 70px 0px 0px 21px;
                    @media screen and (max-width: 767px) {
                        padding: 30px 0px 0px 30px;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        padding: 30px 0px 0px 30px;
                    }
                }
                .wwdo-title {
                    flex: 0 auto;
                    font-size: 30px;
                    font-weight: 600;
                    color: $white-color;
                    padding: 18px 0px 0px 21px;
                    margin: 0px;
                }
                .team-mem-bio {
                    flex: 0 auto;
                    font-size: 15px;
                    font-weight: 400;
                    color: $white-color;
                    line-height: 24px;
                    padding: 20px 44px 40px 21px;
                    margin: 0px;
                    @media screen and (max-width: 767px) {
                        padding: 20px 30px 40px 21px;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        padding: 20px 30px 40px 21px;
                    }
                }
            }
            .team-mem-pic {
                position: absolute;
                bottom: 0px;
                right: 0px;
                img {
                    width: 267px;
                }
                img.kiran-img {
                    width: 258px;
                }
                img.hemanth-img {
                    width: 202px;
                }
                img.jaline-img {
                    width: 179px;
                }
            }
        }
        .darkbg {
            background: $dark-color;box-shadow: 0px 4px 15px #629CA3;
        }
        .lightbg {
            background: #2CA1AF;box-shadow: 0px 4px 15px #629CA3;
        }
    }
  }