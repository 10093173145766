/************************************* created by chakri ************************************/
/**************** varibles, mixins, classes, Common Regular css And Component Css *****************/
/**************** This is the main css file. we can apply any component css in this file only and no need to write any where *****************/
// $ji-font-family : 'Poppins', sans-serif;
$theme_color:#317EFB;
$ji-org-color:#f1873d;
$ji-yel-color: #f5b11e;
$ji-bg-color: #fafafa;
$ji-white: #ffffff;
$ji-black: #000000;
$ji-darkgray-color    :#505050;
$ji-darkred-color     :#a31e21;
$ji-red-color         :#d81e29;
$ji-green-color       :#43a160;
$ji-sucess-color      :#3AB152;
$ji-small-text         :14px;
$ji-dft-text          :16px;
$ji-title-18          :18px;
$ji-title-26          :26px;
$ji-title-46          :46px;
$ji-font-600          :600;
$ji-font-400          :400;
$ji-font-200          :200;
$ji-line-height       :130%;
$bk-clr               :#000000;
$wt-clr               :#ffffff;
$dr-bl-clr            :#3898cb;
$rd-color             :#d81e29;
$sm-ft:12px;
$nr-ft:14px;
$upsure-footer-bg :#364c5d;
$lv-grey:#4F4F4F;
//$grdt-grn-col:45deg,  #00bc95 16%, #4ec475 100%;
$second-color: #f5b21b;
$background-color: #fafafa;
$cus-white: #ffffff;
$cus-black: #000000;
$cus-blue: #3898cb;
$upsure-blu:#4A00E9;
$rd-clr:#d81e29;
$bl-cr:#68A0DB;
$bkdemo-clr:#EED1F7;
$aboutus-clr:#f6b22b;
$up-rd-clr:#FA1847;
$more-from-cl:#5a67ad;
$table_body_height:420px;
$screen-xs: 576px;
// Small tablets (portrait view)
$screen-sm: 768px;
// Tablets and small desktops
$screen-md: 992px;
// Large tablets and desktops
$screen-xl: 1200px;

@mixin xs {
  @media all and (max-width: #{$screen-xs - 1px}) {
    @content;
  }
}

// Medium devices
@mixin sm {
  @media all and (min-width: #{$screen-xs }) and (max-width: #{$screen-sm - 1px}) {
    @content;
  }
}

// Large devices
@mixin md {
  @media all and (min-width: #{$screen-sm }) and (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

// Extra large devices
@mixin lg {
  @media all and (min-width: #{$screen-md }) and (max-width: #{$screen-xl - 1px}) {
    @content;
  }
}

@mixin xl {
  @media all and (min-width: #{$screen-xl}) {
    @content;
  }
}

// Custom devices
@mixin mx($screen) {
  @media all and (max-width: $screen+'px') {
    @content;
  }
}

@mixin mi($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}
@mixin xs {
  @media all and (max-width: #{$screen-xs - 1px}) {
    @content;
  }
}

// Medium devices
@mixin sm {
  @media all and (min-width: #{$screen-xs }) and (max-width: #{$screen-sm - 1px}) {
    @content;
  }
}

// Large devices
@mixin md {
  @media all and (min-width: #{$screen-sm }) and (max-width: #{$screen-md - 1px}) {
    @content;
  }
}

// Extra large devices
@mixin lg {
  @media all and (min-width: #{$screen-md }) and (max-width: #{$screen-xl - 1px}) {
    @content;
  }
}

@mixin xl {
  @media all and (min-width: #{$screen-xl}) {
    @content;
  }
}

// Custom devices
@mixin mx($screen) {
  @media all and (max-width: $screen+'px') {
    @content;
  }
}

@mixin mi($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin avatar($size, $circle: false) {
  width: $size;
  height: $size;
  @if $circle {
    border-radius: $size / 2;
  }
}

.square-av { @include avatar(100px, $circle: false); }
.circle-av { @include avatar(100px, $circle: true); }

.circle-av-sm { @include avatar(30px, $circle: true); }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
h1,h2,h3,h4,h5,h6,p {
  // font-family: 'Segoe UI', sans-serif !important;
  font-family: 'Graphik' !important;

}

input[type=number] {-moz-appearance: textfield !important;}
*:focus {
  outline: 0 !important;
}
a{
  cursor: pointer;
  text-decoration: none;
}
.border-none{border: none !important;}
.float-right { float: right;}
.ovr-fl-hid{overflow: hidden;}
.dis-no{display: none;}
.ovt-fl-vis{overflow: visible !important;}
.sub-tit-drk{color: lighten($cus-black, 24%);font-size: 30px;text-transform: capitalize;}
.greentxt{color:$ji-green-color !important;}
.blktxt-30{ color:lighten($ji-black, 30%);}
.blktxt-45{color:lighten($ji-black, 45%);}
.blktxt-50{color:lighten($ji-black, 50%);}
.blktxt-60{ color:lighten($ji-black, 60%) !important;}
.blktxt-70{ color:lighten($ji-black, 70%) !important;}
.blkcolor-20,.blktxt-20{ color: lighten($ji-black, 20%) !important;}
.orgtxt{color:$ji-org-color !important;}
.orgtxt-10{color:lighten($ji-org-color, 10%);}
.whitetxt, .white-txt{color:$ji-white !important;}
.ftwt-200{font-weight: 200 !important;}
.ftwt-600{font-weight: 600 !important;}
.cl-fix{  clear: both;}
.min-height-240{  min-height: 240px !important;}
.mr-10{
  margin: 10px !important;
  @include mx(767){
    margin: 0px !important;
  }
}
.hor-margin-20{  margin: 0px 20px !important;}
.hr-mr-10{  margin: 0px 10px !important;}
.hr-mr-5{  margin: 0px 5px !important;}
.pd-lt-5{padding-left: 5px !important;}
.mr-rt-10{margin-right: 10px !important;}
.hr-pd-5{  padding: 0px 5px !important;}

.bold-18 {
  @extend .title-1;
  font-size: 30px;
  font-weight: 600;
}
.lht-bl-bg{
  //background: lighten($cus-blue, 45%);
  background: #F0F9FC;
}
.lht-gry-bg{
  background: lighten($ji-black, 96%);
}
.position-sticky{
  position: -webkit-sticky;
  position: sticky;
}
.width-100{
  width:100% !important;
}

.wt-bg{
  background: $wt-clr !important;
}

.min-wd-340 {
  min-width: 340px ;
  @include mx(600){
    min-width: 100% !important;
    width: 100%;
  }
}
.mx-wd-340 {
  max-width: 340px !important;
  margin: auto;
}
.mx-wd-420 {
  max-width: 420px !important;
  margin: auto;
}
.mr-auto{
  margin: auto;
}
.mx-wd-768 {
  max-width: 768px !important;
  margin: auto;
  @include mx(600){
    width: 100%;
    max-width: 100%;
  }
}
.mx-wd-640 {
  max-width: 640px !important;
  margin: auto;
  @include mx(600){
    width: 100%;
    max-width: 100%;
  }
}

.mx-wd-560 {
  max-width: 560px !important;
  margin: auto;
  @include mx(600){
    width: 100%;
    max-width: 100%;
  }
}
.df-fm-wd{
  width: 320px !important;
}
.min-ht-100{
  min-height:100% !important;
}
.line-height-150{
  line-height: 150% !important;
}
.small-text{font-size: 12px !important;color:lighten($ji-black, 20%);line-height:calc(100% + 36%);margin: 0px !important;}
.p-content, .nor-text  { @extend .small-text;font-size: 14px !important;  color: lighten($ji-black, 24%) }
.jiva-form-field{
  margin-bottom: 16px;
}

.title-1{
  @extend .p-content; font-size: 16px !important;line-height: $ji-line-height;
}
.sb-tit{
  @extend .title-1; font-size: 18px !important;
}
.title-2 {
  @extend .title-1;font-size: 22px !important;
  @media all and (max-width: 767px) {font-size: 16px !important;}
  @media only screen and (max-device-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {font-size: 16px !important;}
}
.title-3 {
  @extend .title-2;font-size: 32px !important;
  @include mx(767){font-size: 24px !important;}
 }

.title-4 {
  font-size: 46px !important;line-height: $ji-line-height ;@extend .title-3;
  @media all and (min-width: 641px) and (max-width: 1200px) {font-size: 32px !important;}
  @media only screen and (min-device-width: 641px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 32px !important;
  }
  @media all and (max-width: 640px) {font-size: 28px !important;}
  @media only screen and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 28px !important;
  }
}
.text-uppercase {text-transform: uppercase !important;}
.text-capitalize { text-transform: capitalize !important;}
.text-normal {text-transform: inherit !important;}
.ver-marg-10{margin:10px auto !important; }
.v-mr-5{margin:5px auto !important; }
.ver-marg-20{margin:20px auto !important; }
.vert-margin-30{
  margin:30px 0px !important;
  @media all and (max-width: 768px){ margin:10px 0px !important;}
}
.padding-left20{padding-left: 20px !important;
  @media all and (min-width:768px) and (max-width: 992px){  padding-left: 10px !important;}
  @media all and (max-width: 767px){ padding-left: 0px !important;}
}

.org-txt{color:$ji-org-color;}
.bl-clr{color: $bl-cr !important;}
.dk-y-cr{color:#C27D05;}
.dr-rd-cr{color:darken($up-rd-clr, 16%);}
.no-padding {padding:0px !important;}
.no-margin{margin: 0px !important;}
.padding-8{  padding: 8px !important;}
.padding-10 { padding:10px !important;}
.padding-12{  padding: 12px !important;}
.padding-20 { padding:20px !important;

}
.mb-mr-10{
  @include mx(767){
    margin: 10px !important;
  }
}
.padding-40 {
  padding: 40px !important;
 @include mx(767) {
  padding: 20px !important;
}
}

.pd-bt-10{
  padding-bottom: 10px !important;
}
.pd-tp-20{
  padding-top: 20px;
}
.pd-tp-30{
  padding-top: 30px;
}
.pd-tp-40{
  padding-top: 40px;
}
.pd-tp-10{
  padding-top: 10px !important;
}
.pd-tp-5{
  padding-top: 5px !important;
}
.hr-pd-10{
  padding: 0px 10px !important;
}
.ver-padding-6{padding:6px 0px !important;}
.padding-vert-8, .ver-padding-8{ padding: 8px 0px !important;}
.ver-padding-10 { padding:10px 0px!important;}
.ver-padding-20 {padding:20px 0px!important;}
.minus-margin20 {
  margin-right: -20px;
  margin-left: -20px;
  margin-top:-20px ;
  }
.text-center {  text-align: center !important;}
.text-left {  text-align: left;}
.text-right {text-align: right;}
.text-justify{text-align: justify;}
.errormsg{color: $ji-red-color ;font-size: 12px;margin:3px 0px;}
.hidden-mobile, .jiva-hidden-xs{
  @media all and (max-width: 767px){display: none !important;}
}
.hidden-fr-tab, .hidden-nor-device, .jiva-hidden-sm{
  @media all and (min-width: 768px) and (max-width: 991px) {display: none !important;}
}
.jiva-hidden-md{
  @media all and (min-width: 992px) and (max-width: 1199px) {display: none !important;}
}
.jiva-hidden-lg{
  @media all and (min-width: 1200px){display: none !important;}
}

/**************** Mixins Starts Here ******************/
@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}
//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}
@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
@mixin x-small() {
  @media all and (max-width: 480px) {
    @content;
  }
}

@mixin small() {
  @media all and (min-width:481px) and (max-width: 767px) {
    @content;
  }
}

@mixin medium() {
  @media all and (min-width:767px) and (max-width: 1024px) {
    @content;
  }
}

@mixin large() {
  @media all and (min-width:1025px) and (max-width: 1200px) {
    @content;
  }
}

@mixin x-large() {
  @media all and (min-width:1201px)  {
    @content;
  }
}

@mixin custom-border-radius($radius) {
  -webkit-border-radius: $radius; -moz-border-radius: $radius;
  -ms-border-radius: $radius;border-radius: $radius;
}
@mixin column($percent){
  -webkit-flex: 0 $percent;-ms-flex: 0 $percent;flex: 0 $percent;-moz-box-flex: 0 $percent;
}
@mixin flexbox() {
  display: -webkit-box ;display: -moz-box ;display: -ms-flexbox ;
  display: -webkit-flex ;display:flex ;
}
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}
@mixin cus-boxshadow($top, $left, $blur, $color) {
  -webkit-box-shadow:$top $left $blur $color ;box-shadow:$top $left $blur $color;
  -moz-box-shadow:$top $left $blur $color;
}
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;-moz-box-sizing: $box-model; box-sizing: $box-model;
}
/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;position: relative;line-height: $lineHeight;max-height: $lineHeight * $lineCount;
  text-align: justify;margin-right: -1em;padding-right: 1em;
  &:before {
    content: '...';position: absolute;right: 0;bottom: 0;
  }
  &:after {
    content: '';position: absolute;right: 0; width: 1em; height: 1em;
    margin-top: 0.2em;background: $bgColor;
  }
}
.custom-transition {
  -webkit-transition: all 250ms cubic-bezier(0.615, 0, 0.400, 0.995); /* older webkit */
  -webkit-transition: all 250ms cubic-bezier(0.615, -0.010, 0.400, 0.995);
  -moz-transition: all 250ms cubic-bezier(0.615, -0.010, 0.400, 0.995);
  -o-transition: all 250ms cubic-bezier(0.615, -0.010, 0.400, 0.995);
  transition: all 250ms cubic-bezier(0.615, -0.010, 0.400, 0.995); /* custom */
  -webkit-transition-timing-function: cubic-bezier(0.615, 0, 0.400, 0.995); /* older webkit */
  -webkit-transition-timing-function: cubic-bezier(0.615, -0.010, 0.400, 0.995);
  -moz-transition-timing-function: cubic-bezier(0.615, -0.010, 0.400, 0.995);
  -o-transition-timing-function: cubic-bezier(0.615, -0.010, 0.400, 0.995);
  transition-timing-function: cubic-bezier(0.615, -0.010, 0.400, 0.995); /* custom */
}
.cust-box-shadow {
  -webkit-box-shadow: 0px 1px 2px 2px #ccc !important;box-shadow: 0px 1px 2px 2px #ccc !important;
  -moz-box-shadow: 0px 1px 2px 2px #ccc !important;
}
/**************** Mixins Ends Here ******************/
.margin-30 { margin:30px auto !important;}
.margin-10{margin:10px !important;
@media all and (max-width: 767px){
  margin: 0px !important;
}
}
.margin-15{margin:15px !important;
  @media all and (max-width: 767px){
    margin: 0px !important;
  }
}
.margin-20{margin:20px !important;
  @media all and (max-width: 767px){
    margin: 5px 0px !important;
  }
}
.margin-6{margin:6px !important;
 /* @media all and (max-width: 767px){
    margin: 0px !important;
  }*/
}
.marginleft-10{margin-left: 10px !important;
@media all and (max-width: 480px){
  margin: 0px !important;
}
}
.dif-margin {margin:40px auto 0px !important;}
.jiva-cust-divider , .cust-divider{
  position: relative;
  height: 1px !important;user-select: none;
  width: 100%;
  background: lighten($ji-black, 85%);
  margin:16px auto;
}
.jiva-cust-ver-divider{
  position: relative;
  width: 1px;
  min-height:40px;
  background-color: lighten($ji-black, 88%);
  float: initial;
}
.lt-org-bg{
  background: lighten($ji-org-color, 34%);
}
.violet-clr{color:darken($bkdemo-clr, 24%) !important;}
.yellow-clr{color: $aboutus-clr !important;}

.icon-divider {
  @extend .cust-divider;
  &::after{
    content: '\f078';
    color:lighten($cus-black, 60%);
    border:1px solid lighten($cus-black, 60%);
    width:26px;height:26px;
    @include custom-border-radius(50%);
    font-family: FontAwesome;
    position: absolute;
    margin:auto;font-size: 14px;
    left:0;right:0;top: -10px;
   // padding: 5px;
    line-height: 24px;
    text-align: center;
    background: $cus-white;
    box-sizing: border-box;
  }
}
.txt-hor-divider{
  @extend .jiva-cust-divider;
  text-transform: uppercase;
  margin:20px auto !important;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  color:lighten($ji-black, 40%);
  &::before, &::after{
    content: '';
    position: relative;
    height: 1px;
    width: 44%;
    vertical-align: middle;
    display: inline-block;
    background: #ccc;
    margin:0px 10px;
  }
  &::after{ margin-right: -50%;}
  &::before{margin-left: -50%; }
}
.even-flexbox{
  flex: 1 auto;

}
/********************* Jiva Layout **************************/
.ups-fluidcontainer{
  width:100%;
  padding:0px 10px ;
  box-sizing: border-box;
  //overflow: visible;
  // @media all and (max-width: 600px){ padding:0px !important; }
}
.ups-container{
  max-width:90%;
  width: 100%;
  margin:0px auto;padding:10px ;
  box-sizing: border-box;
 // overflow: hidden;
  @include mx(599){
    max-width: 100%;
    padding:10px !important;
  }
}
 .wrapper{
  @extend .ups-container;
  max-width: 1024px;
  width: fit-content;
  margin: auto;

  padding: 0px;
  @media all and (max-width:767px){
    padding: 10px 0px !important;
    max-width: 100%;
    width: 100%;
  }
}
.ups-row{
  @include flexbox();
  // width: 100%;
  flex: auto;
  //width: fit-content;
  flex-flow:row wrap;
  //flex: auto;
  margin-right:-10px !important;
  margin-left:-10px !important;
  &::before{
    display: table;
    content: " ";
  }
  &::after{
    display: table;
    content: " ";
    clear: both;
  }
  /*@include mx(767){
    margin: 0px !important;
  }*/
}

.width-100{
  width:100% !important;
}

.min-wd-340 {
  min-width: 340px ;
  @include mx(600){
    min-width: 100% !important;
    width: 100%;
  }
}
.mx-wd-340 {
  max-width: 340px !important;
  margin: auto;
}
.mr-auto{
  margin: auto;
}
.mx-wd-768 {
  max-width: 768px !important;
  margin: auto;
  @include mx(600){
    width: 100%;
    max-width: 100%;
  }
}
.mx-wd-640 {
  max-width: 640px !important;
  margin: auto;
  @include mx(600){
    width: 100%;
    max-width: 100%;
  }
}

.mx-wd-560 {
  max-width: 560px !important;
  margin: auto;
  @include mx(600){
    width: 100%;
    max-width: 100%;
  }
}
.mr-auto{
  margin: auto !important;
}
.df-fm-wd{
  width: 320px !important;
}

.salescont{
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  clear: both;
  @include mx(600){
    padding:10px 0px;
  }
}
.salesform{
  padding: 20px ;
  box-sizing: border-box;
  //width: 100%;
  width: auto;
  //min-width: 500px;
  overflow: inherit;
  @include mx(600){
    padding:5px;
  }
}

.mi-wd-640{
  min-width: 640px;
  @include mx(600){
    min-width: 100%;
  }
}

.main-pg {
  padding: 0px;margin: 0px;
  width: 100%;min-height: 100%;height: auto;
  position: relative;
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
  padding: 0rem;
  margin: 0rem;
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    padding: 0;
  }
  @media all and (max-width: 992px) {
    overflow-y: auto !important;
  }
}
@media only screen and (min-device-width: 280px) and (max-device-width: 1080px) {
  .app-container::-webkit-scrollbar {
    display: none;
  }
}
::-webkit-scrollbar {
  background-color: #cdcdcd;
  width: 0.50rem;
}
::-webkit-scrollbar-thumb {
  background-color: #667477;
  border-radius: 100px;
}
.pg-con{
  position: relative;
  height:auto;
  min-height: 100vh;
  padding-top: 70px ;
  margin:0px;
  box-sizing: border-box;
  @media all and (max-width: 768px){
    padding-top: 60px ;
  }

  .section{
    min-height: calc(100vh - 70px) ;
    padding: 40px ;
    @media all and (max-width: 1024px) {
      height: auto !important;
      min-height: 100%;
     // padding: 20px !important;
    }
    .demo-cont{
      max-width: 80%;
      @media all and (max-width: 768px){
        max-width: 100%;
      }
    }
    .contactus_box {
      @include cus-boxshadow(2px,1px,19px,3px rgba(194,194,194,0.8));
      min-height: 180px;
     // margin:0 20px auto;
      background: $ji-white;
      @include custom-border-radius(5px);
      padding: 20px;
      max-width: 100%;
      overflow: hidden;
      position: relative;
      @media all and (max-width: 768px) {
        margin: 12px auto !important;
      }
      //min-width: 450px;
.con_icon{
position: absolute;
  top: -20px;
  left: 50px;
max-width: 80px;
  @media all and (max-width:600px) {
    left: 40px;
    max-width: 70px;
  }
}
      .con_text{
        padding-top: 40px;
        h3{
font-size: 30px;
          color: lighten($ji-black, 24%);
        }
        p{
          color: lighten($ji-black, 20%);
          font-weight: 600;
          margin-top: 15px;
          font-size: 13px;
          &:nth-of-type(2) {
            margin-top: 5px;
            padding-left: 6px;
            color:$ji-green-color !important;
            font-weight: 200 !important;
          }
        }
      }
    }
  }
  .foot-sec{
    background: $ji-white;
    height: auto !important;

    // @include flexbox();
    //align-items: center;
    @media all and (max-width: 768px) {
      padding: 0px !important;
    }
  }
}

.res-pad-10{
  @include mx(767){
    padding: 10px !important;
  }
}
.res-pad-0{
  @include mx(767){
    padding: 0px !important;
  }
}
.res-pad-20{
  @include mx(767){
    padding: 20px !important;
  }
}

.ly-wr{
  max-width: 850px;
  margin: auto;
  @include flexbox();
  flex-flow: row wrap;
  width:100%;
  height:auto;
  padding: 0px;
  margin:auto;
}
.lt-ct  {
  width:100%;
  max-width: 500px;
  padding:0px;
  @media all and (min-width: 768px) and  (max-width: 960px){
    width:440px !important;
  }
  @media all and(max-width: 767px){
    width:100%;
    padding:0px;
    margin: auto;
    max-width: 100%;
  }
}
.rt-ct{
  width:100%;
  max-width: 350px;
  padding-left: 20px;
  @media all and (min-width: 768px) and  (max-width: 960px){
    width:300px !important;
  }
  @media all and (max-width: 767px){
    width:100%;
    padding:0px;
    margin: auto;
    max-width: 100%;
  }
}

.line-title{
  border-bottom: 1px solid #e1e1e1;
  margin: 30px 0;
  span{
    position: relative;
    top: 10px;
    left: 30px;
    font-size: 16px;
    text-transform: uppercase;
    background: #fafafa;
    padding: 15px;
    color: #c5c5c5;
  }
}

.fx-48{
  width: 48% !important;
  flex: 0 auto !important;
  margin-right: 10px !important;
  &:nth-child(even) {
    margin-right: 0px !important;
  }
  @include mx(599){
    width: 100% !important;
    margin: 0px 10px !important;
  }
}
/**********************Flex columns css*************************/
.cl-xs-1{
  @include column(8.33%);padding:10px;float: left;width:8.33%;
  box-sizing: border-box;
  @media all and (max-width:767px){
    @include column(100% !important);
    float: none !important;width:100% !important;
  }
}
.cl-xs-off-1{ margin-left: 8.33%;}
.cl-xs-2{
  @extend .cl-xs-1;width:16.66% ;@include column(16.66% );
  @media all and (max-width:767px){
    @include column(100% !important);
    float: none !important;width:100% !important;
  }
}
.cl-xs-off-2{ margin-left: 16.66%;}
.cl-xs-3{
  @include column(25% );@extend .cl-xs-1; width:25% ;
  @media all and (max-width:767px){
    @include column(100% !important);
    float: none !important;width:100% !important;
  }
}
.cl-xs-off-3{ margin-left: 25%;}
.cl-xs-4{
  @include column(33.33% ); @extend .cl-xs-1;width:33.33% ;
  @media all and (max-width:767px){
    @include column(100% !important);
    float: none !important;width:100% !important;
  }
}
.cl-xs-off-4{ margin-left: 33.33%;}
.cl-xs-5{
  @include column(42% );@extend .cl-xs-1;width:42% ;
  @media all and (max-width:767px){
    @include column(100% !important);
    float: none !important;width:100% !important;
  }
}
.cl-xs-off-5{ margin-left: 42%;}
.cl-xs-6{
  @include column(50% );@extend .cl-xs-1;width:50% ;
  @media all and (max-width:767px){
    @include column(100% !important);
    float: none !important;width:100% !important;
  }
}
 .cl-xs-off-6{ margin-left:50%;}
 .cl-xs-7{
  @include column(58% !important);@extend .cl-xs-1;width:58% ;
  @media all and (max-width:767px){
    @include column(100% );
    float: none !important;width:100% !important;
  }
}
 .cl-xs-off-7{ margin-left:58%;}
 .cl-xs-8{
  @include column(66.66666667% );@extend .cl-xs-1;width:66.66666667% ;
  @media all and (max-width:767px){
    @include column(100% );
    float: none !important;width:100% !important;
  }
}
 .cl-xs-off-8{ margin-left:66.66666667%;}
 .cl-xs-9{
  @include column(75% !important);@extend .cl-xs-1;width:75% ;
  @media all and (max-width:767px){
    @include column(100% !important);
    float: none !important;width:100% !important;
  }
}
 .cl-xs-off-9{ margin-left:75%;}
 .cl-xs-10{
  @include column(83.33333333% !important);@extend .cl-xs-1;width:83.33333333% ;
}
 .cl-xs-off-10{ margin-left:83.33333333%;}
 .cl-xs-11{
  @include column(91.66666667%);@extend .cl-xs-1;width:91.66666667% ;
}
 .cl-xs-off-11{ margin-left:91.66666667%;}
 .cl-xs-12{
  @include column(100% );@extend .cl-xs-1;width:100% ;
}
.cl-xs-off-12{ margin-left:100%;}
/*********for small screens ********/
@media all and (min-width:768px) {
 .cl-sm-1{
    @include column(8.33%);padding:10px;float: left;width:8.33%;
    box-sizing: border-box;
    @media all and (max-width:767px){
      @include column(100%);width:100%;float: none !important;
    }
  }
 .cl-sm-off-1{ margin-left: 8.33%;}
.cl-sm-2{@extend .cl-sm-1;@include column(16.66%); width:16.66%;}
 .cl-sm-off-2{ margin-left:16.66%;}
 .cl-sm-3{ @extend .cl-sm-1;@include column(25%);width:25%;}
.cl-sm-off-3{ margin-left:25%;}
 .cl-sm-4{@extend .cl-sm-1;@include column(33.33%);width:33.33%;}
 .cl-sm-off-4{ margin-left:33.33%;}
 .cl-sm-5{@extend .cl-sm-1;@include column(42%);width:42%;}
 .cl-sm-off-5{ margin-left:42%;}
 .cl-sm-6{ @extend .cl-sm-1;@include column(50%);width:50%;}
 .cl-sm-off-6{ margin-left:50%;}
 .cl-sm-7{@extend .cl-sm-1;@include column(58%);width:58%;}
 .cl-sm-off-7{ margin-left:58%;}
 .cl-sm-8{  @extend .cl-sm-1;@include column(66.66666667%);width:66.66666667%;}
 .cl-sm-off-8{ margin-left:66.66666667%;}
 .cl-sm-9{ @extend .cl-sm-1;@include column(75%);width:75%;}
 .cl-sm-off-9{ margin-left:75%;}
 .cl-sm-10{ @extend .cl-sm-1;@include column(83.3333333%);width:83.3333333%;}
 .cl-sm-off-10{ margin-left:83.3333333%;}
.cl-sm-11{@extend .cl-sm-1;@include column(91.66666667%);width:91.66666667%;}
.cl-sm-off-11{ margin-left:91.66666667%;}
 .cl-sm-12{ @extend .cl-sm-1;@include column(100%);width:100%; }
.cl-sm-off-12{ margin-left:100%;}
}
/********************For desktops **********************/
@media all and (min-width:992px) {
 .cl-md-1{
    @include column(8.33%);padding:10px;float: left;width:8.33%;
    box-sizing: border-box !important;
    @media all and (max-width:767px){
      @include column(100%); width:100%;float: none !important;
    }
  }
 .cl-md-off-1{ margin-left: 8.33%;}
 .cl-md-2{@extend .cl-md-1;@include column(16.66%); width:16.66%;}
 .cl-md-off-2{   margin-left:16.66%; }
.cl-md-3{ @extend .cl-md-1;@include column(25%);  width:25%;}
 .cl-md-off-3{  margin-left:25%;}
.cl-md-4{ @extend .cl-md-1;@include column(33.33%); width:33.33%;}
 .cl-md-off-4{margin-left:33.33%;}
 .cl-md-5{ @extend .cl-md-1;@include column(42%);  width:42%;}
 .cl-md-off-5{margin-left:42%;}
.cl-md-6{ @extend .cl-md-1;@include column(50%); width:50%; }
  .cl-md-off-6{margin-left:50%;}
.cl-md-7{ @extend .cl-md-1;@include column(58%); width:58%;}
 .cl-md-off-7{ margin-left:58%;}
 .cl-md-8{ @extend .cl-md-1;@include column(66.66666667%);  width:66.66666667%;}
 .cl-md-off-8{  margin-left:66.66666667%; }
 .cl-md-9{@extend .cl-md-1;@include column(75%);  width:75%;}
 .cl-md-off-9{ margin-left:75%; }
 .cl-md-10{@extend .cl-md-1;@include column(83.3333333%);  width:83.3333333%;}
 .cl-md-11{ @extend .cl-md-1;@include column(91.66666667%);  width:91.66666667%; }
 .cl-md-12{ @extend .cl-md-1;@include column(100%);  width:100%;}
}
/***********For large screens ************/
@media all and (min-width:1200px) {
 .cl-lg-1{
    @include column(8.33%);padding:10px;float: left;width:8.33%;
    box-sizing: border-box;
    @media all and (max-width:767px){
      @include column(100%);width:100%;float: none !important;
    }
  }
  .cl-lg-off-1{ margin-left: 8.33%;}
  .cl-lg-2{ @extend .cl-lg-1;@include column(16.66%);width:16.66%;}
  .cl-lg-off-2{ margin-left:16.66%;}
  .cl-lg-3{@extend .cl-lg-1;@include column(25%);width:25%;}
   .cl-lg-off-3{ margin-left:25%;}
   .cl-lg-4{@extend .cl-lg-1;@include column(33.33%); width:33.33%;}
  .cl-lg-off-4{ margin-left:33.33%;}
 .cl-lg-5{@extend .cl-lg-1;@include column(42%); width:42%;}
 .cl-lg-off-5{ margin-left:42%;}
  .cl-lg-6{ @extend .cl-lg-1;@include column(50%); width:50%; }
  .cl-lg-off-6{ margin-left:50%;}
  .cl-lg-7{ @extend .cl-lg-1;@include column(58%); width:58%; }
  .cl-lg-off-7{ margin-left:58%;}
 .cl-lg-8{ @extend .cl-lg-1;@include column(66.66666667%); width:66.66666667%;}
  .cl-lg-off-8{ margin-left:66.66666667%;}
  .cl-lg-9{ @extend .cl-lg-1;@include column(75%); width:75%;}
 .cl-lg-off-9{ margin-left:75%;}
   .cl-lg-10{@extend .cl-lg-1;@include column(83.3333333%); width:83.3333333%;}
 .cl-lg-off-10{ margin-left:83.3333333%;}
 .cl-lg-11{ @extend .cl-lg-1;@include column(91.66666667%); width:91.66666667%;}
  .cl-lg-off-11{ margin-left:91.66666667%;}
.cl-lg-12{ @extend .cl-lg-1;@include column(100%); width:100%;}
  .cl-lg-off-12{ margin-left:100%;}
}
/**********************Flex columns css*************************/

/*********************msg box**********************/

.msg-box {
  position: fixed !important;
  top: 0;
}
/*********************msg box**********************/

/**************** flexbox css added here *******************/
.fl-bx{
  @include flexbox();
  flex-wrap: wrap;
}
.fl-it-ct{align-items: center;}
.fl-it-st{align-items: flex-start;}
.fl-ct {justify-content: center ;}
.fl-st{@extend .fl-ct;justify-content: flex-start !important;}
.fl-sp-bt{@extend .fl-ct;justify-content: space-between !important;}
.fl-sp-ar {@extend .fl-ct;justify-content:space-around;}
.fl-rw{flex-flow: row wrap;}
.fl-col{flex-flow: column wrap;}
.flex-auto{flex: 0 auto;}
.fl-sl-ct{align-self: center !important;}
/**************** flexbox css ends  here *******************/
/**************** cards css starts here *******************/

.nr-checkbox-con{
  width: 100%;
  @include flexbox();
  align-items: center;
  justify-content: flex-start;
  flex-flow:  wrap;
  .ch-bx{
    flex:0 auto;
    max-width: 40%;
    width: auto;
    min-width: 20%;
    label{
      width: auto;
      position: relative;
      cursor: pointer;
      input[type="checkbox"] {
        width: 20px;
        height:20px;
        opacity: 0;
display: inline-block;
        vertical-align: middle;
        pointer-events: none;
      }
      .txt{
        padding-left: 10px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        &::before{
          content:'';
          width: 20px;
          height:20px;
          position: absolute;
          top: 1px;
@include custom-border-radius(3px);
          left: -20px;
          border: 1px solid lighten($bk-clr, 80%);
        }
        &::after{
          content:'\f00c';
          width: 14px;
          height:14px;
          font-family: FontAwesome;
          position: absolute;
          top: 0px;
          left: -18px;
          opacity: 0;
          font-size: 16px;
          font-weight: 200;
          color:$bl-cr;
          background: transparent;
        }
      }
      input[type="checkbox"]:checked ~ .txt::after {
       /// background: $bl-cr !important;
        opacity: 1;
      }
      input[type="checkbox"]:checked ~ .txt::before {
 border: 1px solid $bl-cr;
      }
      input[type="checkbox"]:checked ~ .txt {
        color: $bl-cr;
      }
    }
  }
}

.prem-card{
  text-align: center;
  width: 92% !important;
  min-height: 270px !important;
  @include custom-border-radius(5px !important);
  @include cus-boxshadow(0px, 0px, 1px, 2px #a3a3a3 !important) ;
  margin:20px auto !important;
  @media all and (max-width: 768px){
    margin:8px auto !important;
  }
  .prem-card-heading{
    padding:0px 6px;
    font-weight: 200;
    font-size: 16px !important;
  }
  .prem-card-body{
    padding:10px 0px 5px;
    .pre-con{
      padding:0px 0px 10px;
      p.title-1{
        color:lighten($ji-black, 50%);
        font-size: 12px;
        margin:0px;
      }
      h2.title-3{
        color:lighten($ji-green-color, 26%);
        margin:0px;
        .fa{
          font-size: 16px;
          margin-right: 2px;
          vertical-align: top;
          margin-top: 6px;
        }
      }
      &:nth-of-type(2){
        padding:6px 0px 0px !important;
        h2.title-3{
          color:lighten($ji-black, 24%);
          font-size: 30px !important;font-weight: 200;
        }
      }
    }
  }
}
 .nr-crd {
  width: 100%;position: relative;
  min-height: 80px;background: $cus-white;
  @include cus-boxshadow(1px, 1px, 6px, 0px #bfbfbf ) ;
  @include custom-border-radius(4px);
  .claim{
    position: absolute;
    top:0px;
    right:20px;
    @media all and (max-width: 600px){
      position: relative;
      padding-right: 20px;
      width:100%;right:0px;
      text-align: right;
    }
    span{
      vertical-align: middle; display: inline-block; color:$ji-org-color; font-size: 14px;
    }
    .fa{
      margin-left: 10px;
      background:lighten($ji-org-color, 10%);
      width:40px;  height:45px;text-align: center;
      vertical-align: middle;display: inline-block;
      color:$ji-white;font-size: 22px;
      padding-top: 4px;cursor: pointer;position: relative;
      &::after{
        content: '';
        background: transparent;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 14px solid $ji-white;
        position: absolute;
        bottom: 0px;left: 0;right: 0;
        width: 0px;height: 0px; margin: auto;
      }
    }
  }
  .fullimage-con{
    width:100%;
    padding:0px;
    margin:0px;
    height:auto;
    overflow: hidden;
    .gog-map{
      height:280px;
      width: 100%;
    }
    img{ width:100%; }
  }
  .card-header{
    padding-bottom:12px;
    h4{
      .label{
        @include custom-border-radius(50%);
        width:30px;height:30px;
        padding:7px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        color:$ji-white;
        background: $ji-org-color;
      }
      .title-2{display: inline-block; vertical-align: middle;margin-left: 6px;}
    }
    p.title-1{padding-left: 36px;}
  }
  .card-body{
    position: relative;
    .chips-box{padding:0px 12px 14px 52px; }
  }
  .hosp-bd{
    padding: 20px;
    .add-con{
      @include flexbox();
      justify-content: flex-start;
      flex-flow: wrap row;

      .inner-con{
        flex:0 74%;
        &:nth-of-type(1){
          flex:0 20%;
          img{
            max-width:100px;
            @include custom-border-radius(3px);
          }
        }
        &:nth-of-type(2){padding-left: 8px;}
      }
    }
  }
}

.jrny-card{
  @extend .nr-crd;
  min-height:260px;
  position: relative;
  border-top: 2px solid $ji-org-color;
  @include flexbox();
  align-items: center;
  justify-content: center;
  flex-flow: column;
 // background:url("https://cdn.upsure.io/staticpages/journey.png") no-repeat;
 // background-position: right center;
 // background-size: contain;
  .jrny-title{
    padding:20px 20px 30px !important;
    color:lighten($ji-black, 10%) !important;
    font-size: $ji-title-18;
    max-width: 90%;
  }
}
/*.policy-card{
  @extend .jiva-cust-card;
  position: relative;
  padding: 0px !important;
  margin: 0px auto !important;
  @include custom-border-radius(3px);
  max-width: 100% !important;
  height: auto !important;
  .abs-heading{
    height:50px;
    @include custom-border-radius(3px);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: $ji-org-color;
   line-height: 28px;
    font-size: 17px;
    padding:12px;
    color:$ji-white;
    text-transform: capitalize;
 }
  .order-sum-header{
    display: block !important;
  }
  .card-header{
    border: none !important;
    min-height: 70px !important;
    padding:0px !important;
    //max-height: 70px !important;
    .pri-logo{
      margin:0px auto;
      max-width: 170px;
      height: auto;
      img{
        max-width: 100% !important;
      }
    }
    p.p-content{
      flex: 1 100%;
      text-align: center;
    }
  }

  .card-body{
    padding:10px 0px  !important;
.brk-pnt-div{
  @include flexbox();
  justify-content: space-between;
  flex-flow:row wrap;
  span{
    flex: 0 65%;
    align-self: center;
    &:nth-of-type(even){
      text-align: right;
      flex: 0 35%;
    }

  }

}
  }
}*/
.jiva-normal-chip {
  background: lighten($cus-black, 90%);
  @include custom-border-radius(3px);
  padding:5px 10px;
  font-size: 13px;
  min-width:min-content;
  word-wrap: break-word;display: inline-block; vertical-align: middle;
  text-align: center;
  margin:4px  !important;
  width: auto;
  color: lighten($ji-black, 20%) !important;
  text-decoration: none;
}
.jiva-pri-chip{
  @extend .jiva-normal-chip;
  background-color: lighten($ji-org-color, 32%);
  min-width:min-content;
}
.jiva-rounded-chip {
  @extend .jiva-normal-chip;
  padding:0px 15px ;@include custom-border-radius(24px);
}
.jiva-manage-nondelete-pri-cust-chips {
  @extend .jiva-normal-chip;
  max-width: 190px !important;
}
.jiva-rounded-remove-chip {
  @extend .jiva-rounded-chip;
  position: relative;
  .icon{ margin-left: 9px;cursor: pointer;  }
}
.jiva-pri-rounded-chip{
  @extend .jiva-rounded-chip;
  background: lighten($ji-org-color, 24%) !important;
  color: lighten($ji-black, 24%);
  font-weight: $ji-font-600;
}
.jiva-pri-rounded-remove-chip {
  @extend .jiva-rounded-remove-chip;
  background: lighten($ji-org-color, 24%) !important;
  color: lighten($ji-black, 24%);
  position: relative;
  font-weight: $ji-font-600;
  .icon{
    margin-left: 9px;  cursor: pointer;
  }
}
/**************** Extend && Inheritance Component Styles Starts Here ******************/
/**************** Text Button Styles Starts Here******************/
.df-bt{
  background: lighten($bk-clr, 90%);
  padding: 10px 24px;
  @include custom-border-radius(50px);
  color: lighten($bk-clr, 32%);
  text-transform: uppercase;
  font-size:$sm-ft;
  font-weight:600;
  border: none;
  outline: none !important;
  cursor: pointer;
  margin-bottom: 5px;
  //text-shadow: 0.8px 0px  lighten($bk-clr, 25%);
 // letter-spacing: 1.3px;
  // font-family: 'Segoe UI', sans-serif !important;
}
.txt-bt{
  @extend .df-bt;
  background: transparent;
  padding: 6px 14px;

}
.pr-bt{
  @extend .df-bt;
  background: $bl-cr;
  color: $wt-clr;
  cursor: pointer;
 // min-width: 120px;
  transition: .1s ease-in-out 0s;
  //text-shadow: 1px 0px $wt-clr;
 // letter-spacing:2px;

  &:hover{
    background:darken($bl-cr, 8%) ;
    @include cus-boxshadow(0px, 2px, 12px, -2px lighten($bl-cr, 6%));
  }
}
.bk-bt{
  @extend .pr-bt;
  background: $bk-clr;
  color: $wt-clr;
  &:hover{
    background: $bk-clr;
    color: $wt-clr;
  }
}

.lg-br-blk-btn{
  @extend .txt-bt;
  width: 100%;
  padding: 12px 24px;
  border: 1px solid lighten($bk-clr, 70%);
}

.lg-bt{
  min-width: 160px;
  padding: 14px 24px !important;
  font-size: 16px !important;
  .tx-lb{
    font-size: 16px !important;
    flex: 1 auto;
  }
}

.rd-bg-bt{
  background: $rd-clr;
  &:hover{
    background: $rd-clr;
  }
}

.rd-br-bt{
  background: $wt-clr;
  color:$rd-clr;
  &:hover{
    background: $wt-clr;
    color:$rd-clr;
  }
}

.org-bg-bt{
  background: darken($ji-org-color, 12%);
  &:hover{
    background:  darken($ji-org-color, 12%);
  }
}

.rd-ic-bt, .rd-bt{
  @extend .pr-bt;
  background: $wt-clr;
  @include custom-border-radius(40px);
  padding: 10px 18px;
  color:$up-rd-clr;
  position: relative;
  text-align: left;
  outline: none;
  text-transform: capitalize;
  @include flexbox();
  align-items: center;
  justify-content: center;
  @include cus-boxshadow(0px, 2px, 6px, -2px #ce8191);
  &:hover{
    background: $wt-clr; @include cus-boxshadow(0px, 2px, 6px, -2px #ce8191);
  }
  .tx-lb{
    font-size: 12px;
    flex: 1 auto;
  }
.icon{
 // position: absolute;
  //right:2px;
  width:30px;
  height:30px;
 // flex: 1 auto;
  position: absolute;
  @include custom-border-radius(20px);
  background: $up-rd-clr;
  margin: auto;
 // top: 0;
 // bottom: 0;
//  transition: all 0.3s ease ;
  &:after {
    content:'\f178';
    position: absolute;
    top:0;
    bottom:0;
    right:0px;
    left:0px;
    margin:auto;
    font-size: 16px;
    text-align: center;
    color:$wt-clr;
    padding: 6px;
    font-family: FontAwesome;

  }
 /* &:hover{
    -webkit-animation:trans .6s ease-in-out 0s forwards;
    -moz-animation:trans .6s ease-in-out 0s forwards;
    animation: trans .6s ease-in-out 0s forwards;

  }*/
}
}
.bl-rd-bt{
  @extend .rd-bt;
  color:#68A1DB !important;
  @include cus-boxshadow(0px, 2px, 6px, -2px #68A1DB);
  &:hover{
    background: $wt-clr; @include cus-boxshadow(0px, 2px, 6px, -2px #68A1DB);
  }
}

.yl-rd-bt{
  @extend .rd-bt;
  color:#f6b22b !important;
  @include cus-boxshadow(0px, 2px, 6px, -2px #f6b22b);
  &:hover{
    background: $wt-clr; @include cus-boxshadow(0px, 2px, 6px, -2px #f6b22b);
  }
}

.rd-org-rd-bt{
  @extend .rd-bt;
  background:#fc725e !important;
  color:$ji-white !important;
  @include cus-boxshadow(0px, 2px, 6px, -2px #fc725e);
  &:hover{
    background: #fc725e; @include cus-boxshadow(0px, 2px, 6px, -2px #fc725e);
  }
}

.gr-rd-bt{
  @extend .rd-bt;
  color:#A8C564 !important;
  @include cus-boxshadow(0px, 2px, 6px, -2px #A8C564);
  &:hover{
    background: $wt-clr; @include cus-boxshadow(0px, 2px, 6px, -2px #A8C564);
  }
}

.dr-bl-rd-bt{
  @extend .rd-bt;
  color:#6554B3 !important;
  @include cus-boxshadow(0px, 2px, 6px, -2px #6554B3);
  &:hover{
    background: $wt-clr; @include cus-boxshadow(0px, 2px, 6px, -2px #6554B3);
  }
}

.pkrd-rd-bt{
  @extend .rd-bt;
  color:#F16086 !important;
  @include cus-boxshadow(0px, 2px, 6px, -2px #F16086);
  &:hover{
    background: $wt-clr; @include cus-boxshadow(0px, 2px, 6px, -2px #F16086);
  }
}

.pkrd-btn{
  background-color: #ff725e;
  color:$ji-white !important;
  @include cus-boxshadow(0px, 2px, 6px, -2px #ff725e);
  &:hover{
    background: $ji-white; @include cus-boxshadow(0px, 2px, 6px, -2px #ff725e);
    color:#ff725e !important;
  }
}


.voilet-rd-bt{
  @extend .rd-bt;
  color:#bb00e7 !important;
  @include cus-boxshadow(0px, 2px, 6px, -2px #bb00e7);
  &:hover{
    background: $wt-clr; @include cus-boxshadow(0px, 2px, 6px, -2px #bb00e7);
  }
}

.rd-ic-bt{
padding:12px 40px 12px 18px !important;

  .icon{position: absolute;right:3px;}
}

.ye-rd-ic-bt{
  padding:12px 40px 12px 18px !important;
  color:#CBC42A;
  .icon{position: absolute;right:3px;background:#CBC42A; }
}

.pur-rd-ic-bt{
  padding:12px 40px 12px 18px !important;
  color:#CC02F3;
  .icon{position: absolute;right:3px;background:#CC02F3; }
}
.org-rd-ic-bt{
  padding:12px 40px 12px 18px !important;
  color:#FF7700;
  .icon{position: absolute;right:3px;background:#FF7700; }
}

.cir-bt{
  width: 40px !important;
  height:40px !important;
  border: none;
  @include custom-border-radius(50%);
  padding: 10px;
  color:$wt-clr;
  background: $up-rd-clr;
  position: relative;
  @include cus-boxshadow(1px, 1px, 4px, 0px lighten($up-rd-clr, 24%));
    &:after {
      content:'\f178';
      position: absolute;
      top:5px;
      bottom:0;
      right:0px;
      left:0px;
      margin:auto;
      font-size: 16px;
      text-align: center;
      color:$wt-clr;
      padding: 6px;
      font-family: FontAwesome;

    }
    /* &:hover{
       -webkit-animation:trans .6s ease-in-out 0s forwards;
       -moz-animation:trans .6s ease-in-out 0s forwards;
       animation: trans .6s ease-in-out 0s forwards;

     }*/

}
.ygrn-cir-bt{
  @extend .cir-bt;
  background: #CBC42A;
  @include cus-boxshadow(1px, 1px, 4px, 0px lighten(#CBC42A, 20%));
  &:after {
    color:$wt-clr;
  }
}

.pur-cir-bt{
  @extend .cir-bt;
  background: #CC03F3;
  @include cus-boxshadow(1px, 1px, 4px, 0px lighten(#CBC42A, 20%));
  &:after {
    color:$wt-clr;
  }
}

.org-cir-bt{
  @extend .cir-bt;
  background: #FF7600;
  @include cus-boxshadow(1px, 1px, 4px, 0px lighten(#FF7600, 20%));
  &:after {
    color:$wt-clr;
  }
}

.wt-rdtxt-cir-bt{
  background: $wt-clr;
  &:after {
    color:$up-rd-clr;
  }
}

.ygrn-cir-rev-bt{
  background: $wt-clr;
  @include cus-boxshadow(1px, 1px, 4px, 0px lighten(#CBC42A, 20%));
  &:after {
    color:#CBC42A;
  }
}

.pur-cir-rev-bt{
  background: $wt-clr;
  @include cus-boxshadow(1px, 1px, 4px, 0px lighten(#CBC42A, 20%));
  &:after {
    color:#CC02F3;
  }
}

.org-cir-rev-bt{
  background: $wt-clr;
  @include cus-boxshadow(1px, 1px, 4px, 0px lighten(#CBC42A, 20%));
  &:after {
    color:#FF7700;
  }
}



@keyframes trans {
  0% {right: 12px;}
  100% {left: 12px;}

}


/***************absolute btn for any card, relative should be give to parent div**********/

.abs-div, .ab-cr-btn{
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
}


.dis-bt{
  background: lighten($bk-clr, 90%) ;
  padding: 10px 24px;
  @include custom-border-radius(50px);
  color: lighten($bk-clr, 75%);
  text-transform: uppercase;
  font-size:$sm-ft;
  font-weight:600;
  border: none;
  outline: none;
  pointer-events: none;
  //min-width: 145px;
  //text-shadow: 0.7px 0px lighten($bk-clr, 75%);
 // letter-spacing: 1.2px;
  // font-family: 'Segoe UI', sans-serif !important;
}

.ld-bt {
  @extend .dis-bt;
  position: relative;
@include cus-boxshadow(0px,1px,12px,-3px lighten($bk-clr, 76%));
  &::before {
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 4px solid $ji-white;
    border-radius: 50%;
    border-top: 4px solid darken($bl-cr,18%);
    width:25px;
    border-bottom: 4px solid darken($bl-cr, 18%);
    border-left:4px solid darken($bl-cr, 18%);
    height: 25px;
    -webkit-animation: spin .9s linear infinite; /* Safari */
    animation: spin .9s linear infinite;
    //  margin: 0px;
    margin: auto;
    box-sizing: border-box;
  }
  &::after {
    content: '';
    @include custom-border-radius(20px);
    position: absolute;top: 0; left: 0;
    width: 100%;height: 100%;
    background: rgba(255, 255, 255, .5);
    padding: 0px; margin: 0px;
  }
  /* &::before {
     -webkit-animation: fa-spin 1.5s infinite linear !important;
     animation: fa-spin 1.5s infinite linear !important;
     content: '\f110' !important;
     position: absolute !important;
     top:0 !important;left:0 !important;
     font-size: 30px;padding:11px 16px !important;
     font-family: FontAwesome !important;
   }
   */
  //&:hover { background: #dddddd !important;cursor: default; }
}
.count-loader {
  border: 6px solid $wt-clr;
  border-radius: 50%;
  border-top: 6px solid darken($bl-cr,16%);
  width:48px;
  border-bottom: 6px solid darken($bl-cr, 16%);
  border-left: 6px solid darken($bl-cr, 16%);
  height: 48px;
  -webkit-animation: spin .9s linear infinite; /* Safari */
  animation: spin .9s linear infinite;
  //  margin: 0px;
  margin: auto;
  box-sizing: border-box;
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}



@-webkit-keyframes loader {
  0% {
    transform: rotate( 0deg );
  }
  100% {
    transform: rotate( 359deg );
  }
}

@keyframes loader {
  0% {
    transform: rotate( 0deg );

  }
  100% {
    transform: rotate( 359deg );

  }
}

/********************************loading Button styles Ends Here ******************/

/******* progress bar comp *******/
.progress {
  background: lighten($bk-clr, 94%);
  position: relative;
  padding: 0;
  height: 8px;
  width: 100%;
  margin: 0px auto 10px;
  .progress-value {
    // animation: load 3s normal forwards;
    box-shadow: 0 8px 40px -8px $wt-clr;
    // border-radius: 100px;
    background:$dr-bl-clr;
    height: 100%;
    //width: 10%;
  }
}

.prog-9{
  animation: load9 2s normal forwards;
}
.prog-18{
  animation: load18 2s normal forwards;
}
.prog-27{
  animation: load27 2s normal forwards;
}
.prog-36{
  animation: load36 2s normal forwards;
}
.prog-45{
  animation: load45 2s normal forwards;
}
.prog-54{
  animation: load54 2s normal forwards;
}
.prog-63{
  animation: load63 2s normal forwards;
}
.prog-72{
  animation: load72 2s normal forwards;
}
.prog-81{
  animation: load81 2s normal forwards;
}
.prog-90{
  animation: load90 2s normal forwards;
}
.prog-100{
  animation: load100 2s normal forwards;
}


@keyframes load9 {
  0% { width: 0%; }
  100% {width:9%;}
}

@keyframes load18 {
  0% { width: 9%; }
  100% {width:18%;}
}

@keyframes load27 {
  0% { width: 18%; }
  100% {width:27%;}
}

@keyframes load36 {
  0% { width: 27%; }
  100% {width:36%;}
}

@keyframes load45 {
  0% { width: 36%; }
  100% {width:45%;}
}

@keyframes load54 {
  0% { width: 45%; }
  100% {width:54%;}
}

@keyframes load63 {
  0% { width: 54%; }
  100% {width:63%;}
}

@keyframes load72 {
  0% { width: 63%; }
  100% {width:72%;}
}

@keyframes load81 {
  0% { width: 72%; }
  100% {width:81%;}
}

@keyframes load90 {
  0% { width:81%; }
  100% {width:90%;}
}

@keyframes load100 {
  0% { width: 90%; }
  100% {width:100%;}
}


/*********************************input Form styles****************************/
.lf-formfield, .up-formfield{
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  // overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  .br-input{
    position: relative;
    height:auto;
    min-height:60px;
    width:100% !important;
    ::-webkit-input-placeholder { /* Edge */
      color: lighten($bk-clr, 84%);
      font-size: 30px;
      // font-family: 'Segoe UI', sans-serif !important;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: lighten($bk-clr, 84%);
      font-size: 14px;
    }
    ::placeholder {
      color: lighten($bk-clr, 84%);
      font-size: 14px;
    }
    label{color:lighten($bk-clr, 36%);
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      font-weight: 600;
      margin-bottom: 2px;
      // font-family: 'Segoe UI', sans-serif !important;
      display: block;
    }
    .no-label{
      margin-top: 18px;
    }
    input[type="text"], input[type="search"], input[type="number"], input[type="file"],input[type="time"],input[type="date"]{
      border: 1px solid lighten($bk-clr, 85%) !important;
      padding: 10px;
      width: 100%;
      box-sizing: border-box;
      @include custom-border-radius(6px);
      font-size: 16px;
      height: 50px;
      outline: none !important;
      color: lighten($bk-clr, 28%);
      // font-family: 'Segoe UI', sans-serif !important;
     // text-transform: capitalize;
      @include cus-boxshadow(0px,3px, 16px,-4px rgba(0,0,0,0.1));
    }

    input[type="date"] {
      -moz-appearance: textfield;
      position: relative;

    }
    input[type="date"]::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0;
    }
    input::-webkit-calendar-picker-indicator{
      width: 96%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 88%;
      opacity: 0;
    }
    .dlr{
      position: relative;
      input[type] {
        padding: 10px 0px 10px 20px !important;
      }
      &::before{
        content:'';
        position: absolute;
        top:17px;
        left: 4px;
        bottom:0px;
        background: url("https://cdn.upsure.io/lifevest/dollarcur.svg") no-repeat;
        width: 16px;
        height:16px;
        background-size: cover;
        // font-family: FontAwesome;
      }
    }
    .per{
      position: relative;
      input[type] {
        padding: 10px  !important;
      }
      &::before{
        content:'';
        position: absolute;
        top:17px;
        left: 40px;
        bottom:0px;
        background: url("https://cdn.upsure.io/lifevest/per.svg") no-repeat;
        width: 16px;
        height:16px;
        background-size: cover;
        // font-family: FontAwesome;
      }
    }
    .sr-icon{
      position: absolute;
      top:38px;
      right:20px;
      color:lighten($bk-clr, 80%);
    }
    .mn-symbol{
      position: absolute;
      top: 14px;
      left: 15px;
      color: #000;
    }
    .mn-tspace{
      padding-left: 35px !important;
    }

    // input:focus{ border: 1px solid lighten($rd-color,10%) !important;}
    // input:valid{ border: 1px solid darken($sc-clr, 3%) !important;}
    input:disabled{
      background:lighten($ji-black, 97%) !important;
      color:lighten($ji-black, 80%) !important;
    }
    /* input:required {
       border: 1px solid $ji-darkred-color;
     }*/
    p.errormsg{
      color: $rd-clr;line-height: 18px !important;font-size: 12px;margin:0px;
      // font-family: 'Segoe UI', sans-serif !important;
      margin-top: 4px;
    }
  }
  .dob{
    position: relative;
    &::after{
      content:'';
      position: absolute;
      top: 15px;
      right:15px;
      width:18px;
      height:18px;
      background: url('https://cdn.upsure.io/upsure/calendaricon.svg') no-repeat;
 opacity: .5;
      pointer-events: none;
      background-size: contain;
    }
    input[type=date]:required:invalid::-webkit-datetime-edit {
      color: transparent;
    }
    input[type=date]:focus::-webkit-datetime-edit {
      color: black !important;
    }
  }
  .n-br-input{
    //   width: auto;
    @extend .br-input;
    input[type] {
      border: none !important;
      @include cus-boxshadow(0px,0px,0px,0px #fff);
    }
  }

}
.n-br-sl{
  .lf-formfield{
    .selectdropdown{
      min-height: auto;
      margin-top: 8px;
      &::after{
        top:18px;
      }
      select{
        height:40px;}
    }
  }

}

.dob-input-con{
  @include flexbox();
  align-items: baseline;
  justify-content: flex-start;
  position: relative;
  max-width:100%;
  width:100%;
  min-height: 60px;
  margin-bottom:10px;
  ::-webkit-input-placeholder { /* Edge */
    color: lighten($bk-clr, 70%);
    font-size: 30px;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: lighten($bk-clr, 70%);
    font-size: 16px;
  }
  ::placeholder {
    color: lighten($bk-clr, 70%);
    font-size: 16px;
  }
  label{color:lighten($bk-clr, 36%);
    font-size: 16px;
    line-height: 18px;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 2px;
    display: block;
    // font-family: 'Segoe UI', sans-serif !important;
  }
  .no-label{
    margin-top: 18px;
  }
  input[type] {
    flex: 1 auto;
    padding: 10px;
    box-sizing: border-box;
    padding: 0px 10px ;
    font-size: 16px;
    height: 50px;
    border: 1px solid lighten($bk-clr, 85%);
    @include custom-border-radius(6px);
    outline: none !important;
    color: lighten($bk-clr, 28%);
    // font-family: 'Segoe UI', sans-serif !important;
    text-transform: capitalize;
    // border: 0px ;
    @include custom-border-radius(4px);
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    box-shadow: none;
    text-align: center;
    max-width: 33%;
    // width:33%;

    // border-right:1px soli !important;
    // @include cus-boxshadow(10px, 4px,18px,0px rgba(0,0,0,0.1));
    &:first-child{
      border-right: 0px;
    }
    &:last-child {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-right: 1px solid lighten($bk-clr, 85%);
      border-left: 0px;

    }
    &:not(:first-of-type){
      border-top-right-radius: 0px ;
      border-bottom-right-radius: 0px ;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;

    }

  }
  //input:focus{ border: 1px solid lighten($rd-color,10%) !important;}
  // input:valid{ border: 1px solid darken($sc-clr, 3%) !important;}
  input:disabled{
    background:lighten($ji-black, 97%) !important;
    color:lighten($ji-black, 80%) !important;
  }
}

.lf-formfield{
  position: relative;
  height:auto;
  margin-bottom: 10px;
  box-sizing: border-box;

  .no-label{
    margin-top: 16px;
  }
  .selectdropdown{
    position: relative;
    min-height:60px;
    ::-webkit-input-placeholder { /* Edge */
      color: lighten($bk-clr, 70%);
      font-size: 16px;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: lighten($bk-clr, 70%);
      font-size: 16px;
    }
    ::placeholder {
      color: lighten($bk-clr, 70%);
      font-size: 16px;
    }
    label{color:lighten($bk-clr, 36%);
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      display: block;
      margin-bottom: 2px;
      font-weight: 600;
      // font-family: 'Segoe UI', sans-serif !important;
    }

    select{
      border: 1px solid lighten($bk-clr, 85%) !important;
      padding: 10px;
      // font-family: 'Segoe UI', sans-serif !important;
      width: 100%;
      box-sizing: border-box;
      @include custom-border-radius(6px);
      background: $wt-clr;
      font-size: 16px;  height: 50px;outline: none !important;
      @include cus-boxshadow(0px,3px, 12px,0px rgba(0,0,0,0.1));
      -webkit-appearance: none;
      -moz-appearance: none;
      option{
        font-size: 16px !important;
        line-height: 24px;
      }

      &[disabled], &:disabled{
        opacity: 0.4;
        cursor: not-allowed;
      }
      select:invalid { color: lighten($rd-color, 10%); }
      &:not(:focus):invalid {
        color: lighten($ji-black, 80%) !important;
      }
    }

    .disabled{
      background:lighten($ji-black, 97%) !important;
      color:lighten($ji-black, 80%) !important;
    }
    /*  select:focus{
        border: 1px solid lighten($rd-color,10%) !important;
      }*/
    /*  select:valid {
        border:1px solid $sc-clr !important;
        // outline: none;
      }*/
    &:after{
      content: '';
      position: absolute;
      right: 20px;
      top: 40px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right:6px solid transparent;
      border-top:6px solid lighten($cus-black, 50%);
      pointer-events: none;
    }
    &:before{
      content: '';
      position: absolute;
      right: 20px;
      top: 24px;
      width: 15px;
      height: 15px;
      background: $wt-clr;
      opacity: .7;
      pointer-events: none;
    }
  }
  .no-label{
    &:after {
      top:24px;
    }
    }

  .selection{
    width:100% !important;height:40px !important; border:none !important;
    border-bottom: 1px solid lighten($cus-black, 75%) !important;
    @include custom-border-radius(0px !important);
    margin:0px !important;padding:0px !important; box-shadow: none !important;
    color:lighten($cus-black, 35%) !important;background: transparent !important;
    input[type="text"]{
      font-size: 13px;
      padding:16px 0px 0px !important;
    }
    .icon{top: 26px !important;right: 4px !important;padding: 6px !important;}
    .menu{
      max-height: 240px !important; box-shadow: 0px 0px 1px 0px lighten($cus-black, 75%) !important;
      border: none !important;@include custom-border-radius(0px);
    }
    .default{opacity: 0;}
    a.label{
      padding: 6px !important; margin-top: 9px !important;color: lighten($ji-black, 30%);
      line-height: 18px !important; font-size: 13px !important;
      .delete{padding:0px !important;}
    }
    .text{padding-top: 24px;font-size: 14px; }
  }
}

.multidropdown{
  position: relative;
  height:auto;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: 30px;
  min-height: 60px;
  width: 100%;
  .ui.multiple.dropdown{
    @include flexbox();
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    min-height: 50px;
    height: auto;
  }
  .ui.multiple.search.dropdown > .text {
    font-size: 16px;
    margin: 10px;
  }
  .ui.multiple.dropdown>.label{
    background: $lv-grey;
    @include custom-border-radius(20px);
    color: $wt-clr;
    font-size: 14px;
    height: 32px;
    line-height: 20px;
    padding: 6px;
    .icon{
      background: $wt-clr;
      color:$lv-grey ;
      opacity: 1;
      padding: 0px 4px;
      font-size: 11px;
      width: 18px;
      height: 18px;
      margin: 0px 0px 0px 3px;
      position: relative;
      @include custom-border-radius(50%);
      display: inline-block;
      vertical-align: middle;
      &::before{
        content: url('https://cdn.upsure.io/lifevest/close.svg');
        //content: "\274c";
        position: absolute;
        top: -7px;
        left: 0;
        background: no-repeat;
        width: 9px;
        height: 9px;
        right: 0;
        //  font-size: 16px;
        bottom: 0px;
        margin: auto;
      }
    }
  }
  .menu{
    max-height: 230px;
    overflow-y: auto;
  }
}

.jiva-cus-input {
  position: relative;
  height:auto;width:100% !important;
  min-height: 62px;
  label{
   // position: absolute;top:0px;left: 0px;
    color:lighten($ji-black, 40%) !important;
    font-weight: 600;
    font-size: 12px !important;
   // margin-bottom: 8px;
    text-transform: capitalize !important;
    span.imp{
      color: lighten($ji-darkred-color, 20%) !important;

    }
  }
  @include placeholder{
    color: lighten($ji-black, 70%);
    font-size: 12px;
  }
  .input{ width:100%; }
  input{
    height:auto !important;
    margin-top:2px !important;
    padding: 10px !important;
    background: #f5f8fa ;
    border: 1px solid lighten( $cus-blue,40%) !important;
   // border-bottom: 1px solid lighten($cus-black, 75%) !important;
    @include custom-border-radius(3px !important);
   font-size: 14px !important;
    color:lighten($cus-black, 35%) !important;outline: none !important;
    width:100% !important;
    box-sizing: border-box;

  }
  input[type]:focus ::after{
    content: 'sample';position: absolute;min-width:120px;
    background: $ji-darkgray-color;height:auto;
    @include custom-border-radius(3px);color:$ji-white;
  }
  input:disabled{
    background:lighten($ji-black, 97%) !important;
    color:lighten($ji-black, 80%) !important;
  }
 .searchicon{
    position: absolute;top:18px; right:0px;
    color: lighten($ji-black, 60%);
  }
  p.errormsg{
    color: $ji-darkred-color;font-size: 11px;margin:0px;
  }
}
.jiva-cus-inputfile{
  @extend .jiva-cus-input;
  input[type="file"]{padding:12px 0px !important;}
}
.jiva-normal-cus-selectfield{
  height:65px;margin-bottom: 10px; position: relative;
  label{
    position: absolute; top:0px;left: 0px;
    color:$ji-org-color !important;margin:0px !important;
    text-transform: capitalize !important;font-size: 12px !important;
  }
  select{
    height:50px !important;margin:0px !important;border:none !important;
    border-bottom: 1px solid lighten($cus-black, 75%) !important;
    @include custom-border-radius(0px !important);-moz-appearance: none;
    padding:0px !important;background: transparent !important;
    color:lighten($cus-black, 35%) !important;outline:none !important;
    width:100% !important;box-shadow: none !important;
    font-size: 14px !important; -webkit-appearance: none;
  } &::after{
      content:''; pointer-events: none;
      position: absolute;top:24px;right:0px;
      width:0px; height: 0px;background: transparent;
      border-left:4px solid transparent;border-right:4px solid transparent;
      border-top:4px solid lighten($ji-black, 30%);
    }
}
.jiva-cus-selectfield{
  position: relative;height:69px;
  label{
    position: relative;
    font-size: 12px !important;color:$ji-org-color !important;
    text-transform: capitalize !important;margin:0px !important;
  }
.selectdropdown{
  position: relative;
  select{
    height:30px !important; border:none !important;
    position: relative;
    width:100%;
    border-bottom: 1px solid lighten($cus-black, 75%) !important;
    @include custom-border-radius(0px !important);
    margin:0px !important;padding:0px !important; box-shadow: none !important;
    color:lighten($cus-black, 35%) !important;background: transparent !important;
    outline: none !important;
    font-size: 14px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    option{
      font-size: 14px !important;
      line-height: 24px;
    }
    select:invalid { color: lighten($rd-color, 10%); }
    &:not(:focus):invalid {
      color: lighten($ji-black, 80%) !important;
    }
  }
  &:after{
    content: '';
    position: absolute;
    right: 2px;
    top: 12px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid lighten($cus-black, 65%);
  }
}
  .selection{
    width:100% !important;height:46px !important; border:none !important;
    border-bottom: 1px solid lighten($cus-black, 75%) !important;
    @include custom-border-radius(0px !important);
    margin:0px !important;padding:0px !important; box-shadow: none !important;
    color:lighten($cus-black, 35%) !important;background: transparent !important;
    input[type="text"]{
      font-size: 13px;
      padding:16px 0px 0px !important;
    }
    .icon{top: 26px !important;right: 4px !important;padding: 6px !important;}
    .menu{
      max-height: 240px !important; box-shadow: 0px 0px 1px 0px lighten($cus-black, 75%) !important;
      border: none !important;@include custom-border-radius(0px);
    }
    .default{opacity: 0;}
    a.label{
      padding: 6px !important; margin-top: 9px !important;color: lighten($ji-black, 30%);
      line-height: 18px !important; font-size: 13px !important;
      .delete{padding:0px !important;}
    }
    .text{padding-top: 24px;font-size: 14px; }
  }}

.jiva-cus-textarea{
  @extend .jiva-cus-input;
  height: 85px;
  textarea{
    max-height: 50px !important;outline: none;
    background: transparent;border:none !important;
    margin-top: 24px !important;max-width: 100%;
    font-size:13px !important;padding:0px !important;
    border-bottom:1px  solid #ccc !important;
  }
}
.jiva-nor-checkbox{
  position: relative;
  margin: 6px 0px;
  input[type="checkbox"]{
    width:18px;
    height:18px;opacity: 0;
    margin-top:3px;
   // position: absolute;
  }
  label{
    width:18px;
    height:18px;
    border:1px solid lighten($ji-black, 30%);
    @include custom-border-radius(4px);
    top:3px;
    left:0;
    position: absolute;
    padding:0px;
    pointer-events: none;
    cursor: pointer;
  }
  span{
    padding-left: 8px;
    display: inline-block;
    vertical-align: top;
    width:94%;
    font-size: 14px;
    text-align: justify;
    @media all and (max-width: 480px) {
      width: 92%;
    }
  }
  input[type="checkbox"]:checked + label{
    border:1px solid $ji-org-color !important;
  }
  input[type="checkbox"]:checked + label::before{
    content: '\f00c';
    position: absolute;
    top:-3px;
    left:1px;
    font-family: FontAwesome;
    font-size: 14px;
    color:$ji-org-color;
  }
}

.jiva-tic-checkbox{
  position: relative;min-width: 100px !important; margin:6px; padding: 0px !important;
  label{
    padding:12px 0px 12px 38px!important;
    @include custom-border-radius(4px);
    border: 1px solid lighten($ji-black, 60%);
    text-align:left !important;color:lighten($ji-black, 60%);
  }
  label::before{opacity: 0; }
  input[type="checkbox"]:checked ~ label:after{
    color:$ji-org-color !important;font-size:26px !important;top:10px;left:10px;
  }
  input[type="checkbox"]:checked ~ label{
    color:$ji-org-color !important;border: 1px solid $ji-org-color;
  }
}

.jiva-cus-checkbox, .nr-check-bx{
  position: relative !important;margin:10px auto;
  label {
    color: lighten($cus-black, 40%) !important;text-transform: capitalize;
text-align: justify;    font-size: 14px !important;
    &::before {
      border: 1px solid lighten($cus-black, 45%) !important;
      @include custom-border-radius(4px !important);
    }
  }
  input:checked ~ label::before { border: 1px solid $bl-cr !important;}
  input:checked ~ label::after {color: $bl-cr !important;}
}

/* img checkbox styles */
.up-cbx-con{
  @include flexbox();
  flex-flow: row wrap;
  height: auto;
  justify-content: center;
  .bx{
    width: 130px;
    position: relative;
    height: auto;
    margin: 12px;
  }
  label {
  width: 130px;
cursor: pointer;
    min-height: 110px;
    position: relative;

    input[type="checkbox"]{width: 105px;
position: absolute;
      top: 0;
      opacity: 0;
      left:0;right:0;
     margin: auto;
      height: 90px;}
    img {
      width:105px;height:100%;position: absolute;
      top: 0;
      left: 0;
      right:0;
         margin: auto;
      width: 90px;
      filter:grayscale(1);
      height: 90px;
    }
    p.title{position: absolute;

text-align: center;
      font-weight: 600;
      text-transform: capitalize;
      left:0;right:0;
      bottom:-10px;margin: auto;}
  }
  input[type="checkbox"]:checked + img{filter:grayscale(0);}

}

.rel-comp{
  margin-top: 20px;
 border-top: 1px solid lighten($bk-clr, 90%);
  padding-top: 30px;
  max-width: 600px;
  width: 100%;
  min-width: 540px;
  @include flexbox();
  flex-flow: column ;
  justify-content: flex-start;
  @include mx(540){
    width: 100%;
    min-width: 100%;
  }
  .rw{width: 100%;flex:1 auto;@include flexbox();flex-flow: row wrap;align-items: center;justify-content: flex-start;

&:nth-of-type(1){padding-bottom: 20px;}
  .bx{
    flex: 0 auto;width: 25% ;padding: 0px 4px;
  &:nth-of-type(2){width: 50%;
    @include mx(540){
      width: 100%;
    }
  }
    @include mx(540){
        width: 100%;
    }
    .ag-cn{
      @include flexbox();
      justify-content: space-between;
      align-items: center;
      .n-br-sl{width: 90%;}
      img{max-width: 24px;padding-left: 6px;cursor: pointer;filter:grayscale(1);}
    }
  }
    @include mx(480){
     margin-bottom: 10px;
    }
  }
}

.bsdl-crd{
  max-width:160px;
  min-height: 90px;
  padding:10px 20px;
  background: $wt-clr;
  margin: auto;
  @include custom-border-radius(4px);
  border: 1px solid lighten($bk-clr, 75%);
  img{display: block;margin: auto;max-width: 100px;object-fit: contain;
    height: 60px;}
}

.jiva-cus-radio{
  position: relative;margin-right: 15px;
  label {
    color: lighten($cus-black, 40%) !important;
    text-transform: capitalize;padding-left: 20px !important;
    &::before {border: 1px solid lighten($cus-black, 45%) !important;}
  }
  input[type="radio"]:checked ~ label::before {border: 1px solid $ji-org-color !important;}
  input:checked ~ label::after {background: $ji-org-color !important; }
}

.jiva-radio-input-text-con{
  position: relative;
.jiva-radio-input-text{
  @include flexbox();
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  label{
    flex: 0 33.33%;
    align-self: center;
    border: 1px solid #ccc;
    padding:10px 6px;
    text-align: center;
    border-right: none;
    &:nth-of-type(3){
      border-right: 1px solid #ccc;
    }
    //box-sizing: border-box;
    input[type="radio"]{
      opacity: 0;
    }
    span{
      font-size: 14px;
      text-transform: capitalize;
      .fa{
        color:lighten($ji-black, 60%);
        margin-right: 4px;
        @media all and (max-width: 480px){
          margin-right: 2px;
        }
      }
    }
    input[type="radio"]:checked + span{
      color: $ji-org-color;
      .fa{
        color: $ji-org-color;
      }
    }
  }

}
  .abs-form{
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px ;
    text-align: left;
    width: 100%;
    height: 100%;
    //display: none;
    span{
      line-height: 43px;
      color:$ji-org-color;
      text-align: center;
      float: left;
      width: calc(100% - 80%);
      background: $ji-white;
      border:1px solid #ccc;
      border-right: none;
      @media all and (max-width: 480px){
        width: calc(100% - 75%);
        height: 100%;
      }
      .fa{
        color:$ji-org-color;
        margin-right: 4px;
        @media all and (max-width: 480px){
          margin-right: 2px;
        }
      }
    }
    .input-btn-con{
      width: 80%;
      @media all and (max-width: 480px){
        width: 75%;
      }
      height: 100%;
      @include flexbox();
      justify-content: flex-end;
      flex-flow: row;
      // display: none;
      float: right;
      background: $ji-white;
      border: 1px solid #ccc;
      input[type="text"], .txt-btn{
        flex: 0 80%;
        align-self: center;
        height: 100%;
        padding-left: 10px;
        font-size: 14px;
        outline: none;
        border: none;
      }
      .txt-btn{
        flex: 0 20%;
        color:$ji-org-color;
        text-transform: capitalize;
        border: none;
        background: transparent;
        outline: none;
      }
    }
  }
}

/*********************************input Form styles****************************/
/************************* Cards styles starts here *************************/
.jivabasiccard {
  width: 250px !important;
  margin: 10px auto !important;
  min-height: 330px !important;
  overflow: hidden;
  @extend .cust-box-shadow;
  .card-cont {
    padding: 0px !important;border: none;
    .cus-card-header {
      border-bottom: 1px solid #ccc;
      @include flexbox();
      justify-content: space-between;flex-flow: row wrap;
      .cushed, .progressimage {
        flex: 0 54%;
        align-self: center;
        h3.font-size-26 { color: lighten($cus-black, 45%); }
        .cust-stat {
          .cust-stat-lab {
            text-align: left !important;font-size: 13px;line-height: 18px;
            color: lighten($cus-black, 68%);
          }
          .cust-stat-val {
            text-align: left;font-weight: 600;
            font-size: 54px !important;word-spacing: -8px;
            color: lighten($cus-black, 30%) !important;
            .fa {
              font-size: 30px !important; display: inline-block;
              vertical-align: top;margin-top: 9px;
            }
          }
        }
      }
      .progressimage {
        flex: 0 42%;overflow: hidden;align-self: auto;
        img{max-width:160px;}
      }
      .cushed { padding: 24px 0px 0px 18px; }
    }
    .cust-des {
      padding: 10px 20px;margin: 0px !important;
      ul {
        padding: 0px; margin: 0px;
        li {
          line-height: 32px;color: lighten($cus-black, 40%);
          .fa {color: $ji-org-color;margin-right: 5px;}
        }
      }
    }
  }
  .card-footer {border: none; padding: 14px 20px; }
}

.jivamanagebasiccard {
  @extend .jivabasiccard;
  width: 100% !important;
  .cushed {padding: 0px !important;
    h3.font-size-26 {
      text-transform: capitalize;text-align: center !important; }
    p {
      @extend .title-2;color: lighten($cus-black, 50%);
      font-weight: 400;margin: 0px; line-height: 1.2em;
      text-align: center !important;
    }
  }
  .cushed, .progressimage {
    flex: 0 100% !important;margin: 30px;
  }
  .card-footer {
    padding: 20px !important;text-align: center !important;
    a {cursor: pointer;color: $ji-org-color;margin-bottom: 30px;}
  }
  .cus-card-header { border-bottom: none !important;}
}

.jiva-managemenu {
  flex: 0 38%;align-self: center; padding: 20px;
  @media all and (max-width: 1024px) {flex: 0 42%; }
  img {max-width: 260px; }
  ul {
    padding: 0px;margin: 0px;
    li {
      display: inline-block;padding: 0px;
      @extend .small-text;text-transform: uppercase;
      a {cursor: pointer; color: $ji-org-color;}
      &::after { content: '|';color: $ji-org-color;padding: 0px 20px;}
      &:last-of-type {
        &::after {content: '';padding: 0px }
      }
    }
  }
}

/**************** jivaprodcard ******************/
.jivaprodcard {
  @extend .jivabasiccard;width: 500px !important;
  border-top: 3px solid $ji-org-color !important;min-height: 500px;
  .cus-card-header {
    padding: 12px 0px;border-bottom: 1px solid #ccc;
    .cushed {
      padding: 0px !important;
      h3.font-size-26 {  color: lighten($cus-black, 20%) !important;text-transform: capitalize; }
    }
  }
  .cust-des {
    padding: 20px 0px !important;
    .pro-des {
      margin: 20px auto;
      @include flexbox();justify-content: space-around;flex-flow: row wrap;
      .cust-stat {flex: 0 48%; align-self: center;margin: 0px;
        &:nth-of-type(2) {
          flex: 0 40%;
          .cust-stat-val { color: $ji-org-color !important; }
        }
        .cust-stat-lab {
          color: lighten($cus-black, 40%) !important;
          text-align: left !important;text-transform: capitalize;
        }
        .cust-stat-val {
          color: lighten($cus-black, 30%) !important;
          text-align: left !important; font-size: 46px !important;word-spacing: -8px;
          .fa {
            font-size: 30px !important; display: inline-block; vertical-align: top;margin-top: 6px;
          }
        }
      }
    }
    ul.lst {
      padding: 12px 24px;
      li {
        list-style: disc !important;line-height: 24px;
        color: lighten($cus-black, 55%);display: block;
        font-size: 14px;font-weight: 600;
        position: relative;
        &::before{
          position: absolute;content: '';
          top:8px; left:-16px;width:6px;height: 6px;
          background: lighten($cus-black, 50%);
          @include custom-border-radius(50%);
        }
      }
    }
  }
  .card-footer {border: none;padding: 20px; }
}
.jivamanageprodcard{
  @extend .jivaprodcard;
  .card-cont{
    .cust-des{
      .tic-mem {
        padding: 0px 0px 12px 0px;
        p.heading {
          color: lighten($cus-black, 30%);  margin:0px;
          text-align: left; text-transform: capitalize;
        }
      }
      .pro-des{
        margin:10px auto !important;
        border-bottom:1px solid #ccc;
        padding:0px 0px 10px 0px !important;
      }
      .other-des{
        margin: 20px auto;  flex-flow: row wrap;
        @include flexbox(); justify-content: space-around;
        .cust-stat {
          flex: 0 48%;align-self: center;margin: 0px;
          &:nth-of-type(2) {
            flex: 0 40%;
            .cust-stat-val {   color: $ji-org-color !important; }
          }
          .cust-stat-lab1 {
            color: lighten($cus-black, 40%) !important;
            text-align: left !important;text-transform: capitalize; }
          .cust-stat-val1, .cust-stat-val2 {
            color: $ji-org-color !important;line-height:1.5em;
            text-align: left !important;word-spacing: 1px;
            font-size: 26px !important;
          }
          .cust-stat-val2 {
            color: lighten($cus-black, 30%) !important;
            font-size: 18px !important;
            text-transform: none !important;
          }
        }
      }
    }
  }
}

/**************** jivaprodcard ******************/
/******************************** Common Css Starts here ***************************************/

html, body, #root {
  margin: 0px; padding: 0px;
  // font-family: 'Segoe UI', sans-serif !important;
  font-family: 'Graphik' !important;
  width: 100%;
  min-height: 100%;
  font-size: 14px;
  font-weight: normal;position: relative;
  overflow-x: hidden;
  box-sizing: border-box;
  height:auto;

}



/******************************** Common Css Ends Here ***************************************/

// .cust-breadcrumb{
//   padding-bottom:12px;
//   @media all and (max-width: 767px){
//     display: none;
//   }
//   li{
//     padding:0px 6px 0px 0px ;
//     margin:0px;
//     display: inline-block;
//     position: relative;
//     &:after{
//       content: '\/';
//       color:lighten($ji-black, 70%);
//       font-size: 30px;
//       right: -12px;
//       top: 0px;
//       padding: 1px 4px;
//     }
//     a{
//       padding:0px 8px 0px 0px;
//       color:lighten($ji-black, 15%);
//       cursor: pointer;
//       text-decoration: underline;
//       font-size: 15px;
//       @media all and (max-width: 767px){
//         padding:0px 4px;
//       }
//     }
//   }
//   li.active{
//     a{
//       cursor: default;text-decoration: none;color:lighten($ji-black, 48%);
//     }
//     &:after{content: '' !important; }
//   }
// }
.jiva-checkboxbtn-dropdown-contain{
  width:100%;padding:0px; position: relative;margin-bottom:12px;
  @include custom-border-radius(4px);
  .btncheckbox{
    width:100%;  height: 65px;
    position: relative;
    padding:20px 40px;  margin: 0px;cursor: pointer;
    border:1px solid lighten($cus-black, 45%);
    @include custom-border-radius(4px);
  }
  .sub-title{
    position: absolute;top:36px;left:68px;
    color: lighten($ji-org-color, 6%);
    display: none;font-size: 13px;
  }
  .checked ~ span.sub-title{  display: block; }
  label{  font-size: 15px !important;  }
  label::before, label::after{
    opacity: 0;
    width:23px !important;
    height: 23px !important;
  }
  span.bef-check, span.aft-check, .fa-edit{
    position: absolute;top: 20px;left: 14px;
    .icon{font-size: 22px; color:lighten($cus-black, 40%); }
  }
  .aft-check{  opacity: 0 !important; }
  .checked { border:1px solid $ji-org-color; }
  .checked ~ span.bef-check {   opacity: 0 !important; }
  input:checked~label:after{opacity: 0 !important;}
  .checked ~ span.aft-check{
    opacity: 1 !important;
    .icon{ color: $ji-org-color !important;}
  }
  .remov-icon{
    opacity: 0;
    top: 20px;
    right: 22px;
    position: absolute;
    color: $ji-org-color !important;
    .icon{
      font-size: 22px;cursor:pointer;
    }
  }
  .fa-edit{
    top: 24px;
    right: 22px;
    left: 80%;
    font-size: 30px;
   color: $ji-org-color !important;
  }

  .checked ~ span.remov-icon{opacity: 1 !important;}
  .jiva-dropdownbtn-body{
    display: none;
    padding:10px 40px !important;
    position: relative;
    @media all and (max-width: 600px){
      padding:0px 10px !important;
    }
    .jiva-dropdownbtn-form{
      margin:12px 30px;
      @media all and (max-width: 600px){
        margin:12px auto;
      }
    }
    .radiocont{
      @include flexbox();
      justify-content: space-around;
      flex-flow: row wrap;
      p{flex:0 100%;}
      .radiobox{
        flex: 0 45%;
        label:before, label:after{
          width:17px !important;
          height:17px !important;
        }
      }
    }

  }
  .checked ~ .jiva-dropdownbtn-body{
    display: block !important;
    border:1px solid $ji-org-color;
    @include custom-border-radius(4px);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    background: $cus-white;
    margin-top:-3px;
    .radiobox{
      label:before{opacity: 1 !important;}
    }
    .ageform{
      @include flexbox();
      justify-content: flex-start;
      flex-flow: row wrap;
      .agefield{
        flex: 0 34%;margin:0px 5px;
        @media all and (max-width: 600px){
          flex: 0 100% !important;
        }
        h4.labe{margin:4px 0px;  }
        .menu{max-height: 190px; }
      }
    }
  }
}
/************** Date Picker ****************/
.ui-datepicker, .ui-widget.ui-widget-content{
  padding:0px;
  @extend .cust-box-shadow;
  .ui-datepicker-header, .ui-widget-header{
    background: transparent !important;
    padding:10px;height:60px;border:none;
    .ui-datepicker-prev, .ui-datepicker-next{opacity: 0; }
    .ui-datepicker-title{
      margin:0px 4px;padding: 8px 0px;
      .ui-datepicker-month, .ui-datepicker-year{
        background: transparent;outline:none;border:none;width:50%;
      }
    }
  }
  .ui-datepicker-calendar{
    width:94%;margin:12px auto;
    tbody > tr > td {
      background: transparent !important;
      border:none !important;
      a.ui-state-default{
        background: transparent !important;
        border:none !important;font-size: 13px;
        height: 27px;width:27px;
        text-align: center;
      }
      a.ui-state-highlight{
        background: $ji-org-color !important;
        @include custom-border-radius(50%);
        color: $cus-white;font-size: 13px;
      }
    }
    td.ui-state-disabled{
      span.ui-state-default{
        border:none !important;text-align: center;
        height: 27px;width: 27px;font-size: 13px;
        @include custom-border-radius(50%);
        background: rgba(0,0,0,0.1);
      }
    }
  }
}
.jivaHospitalcard {
  margin:0px auto 12px !important;
  background: $ji-white ;
  @include custom-border-radius(4px !important);
  @include cus-boxshadow(0px, 1px, 4px, 0px lighten($ji-black, 60%) !important);
  padding:12px;
  position: relative;
  .tag{
    position: absolute;
    top: 0;
    right: 10px;
    background-color: $ji-org-color;
    width: 35px;
    height: 45px;
    span{
      top: 5px;
      right: 33px;
      position: absolute;
      font-size: 12px;
      width: 80px;
    }
    .fa{
      color: #fff;
      position: absolute;
      font-size: 30px;
      left: 9px;
      top:4px;
    }
    &::after{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 15px solid #fff;
      top: 66%;
      bottom: 0px;
      right: 0;
      left: 0;
      @media all and (max-width: 540px){
        top:67%;
      }
    }
  }

  a{
    color: $ji-black;
  }
  .hos-header{
    padding:20px 0px 6px 0px !important;
    @include flexbox();
    justify-content: space-between;
    flex-flow: row wrap;
    .hos-img, .hos-name{
      align-self: flex-start;
      img{
        width:80px;
        height:80px;
        overflow: hidden;
        @include custom-border-radius(3px);
        display: block;
        margin: auto;
      }
      h4{
        font-size:13px;
        color:$ji-black;
        max-width: 88%;
        // font-family: Montserrat, sans-serif;
      }
      p{
        font-size: 13px;
        color:lighten($ji-black, 40%);
      }
      .nowrap {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 300px;
        color: lighten($ji-black, 16%);
        @media all and (min-width: 320px) and (max-width: 766px){
          width:250px;
        }
      }
    }
    .hos-img{
      flex: 0 20%;
      @media all and (max-width: 540px){
        flex: 0 15%;
        img{
          width:50px;
          height:50px;
        }
      }
    }
    .hos-name{
      flex: 0 78%;
      @media all and (max-width: 540px){
        flex: 0 80% !important;
        width:80% !important;

      }
    }
  }
  .hos-footer{
    @include flexbox();
    justify-content: center;
    flex-flow: row wrap;
    .action{
      flex: 0 33%;
      align-self: center;
      padding: 6px;
      text-align: left;
      font-size: 14px;
      cursor: pointer;
      @media all and (max-width: 480px){
        flex: 0 30%;
        font-size: 11px;
        padding: 0px;
        &:nth-of-type(2){
          flex: 0 40%;
        }
      }
      .fa{
        margin-right: 8px;
        color:lighten($ji-black, 40%);
      }
    }
  }

  .card-header{
    padding: 8px 0px;
    h4{
      .per-hed{
        margin-right: 6px;
        @include custom-border-radius(24px !important);
        background:$ji-org-color ;
        color:$ji-white;padding:10px;
      }
      span{color: lighten($ji-black, 24%);}
    }
  }
  .tic-mem{
    padding:0px 36px;
    @media all and (max-width: 540px){ padding:0px 16px;}
  }
  width: 100% !important;
  height:auto;
  .tic-card-header {
    padding: 8px 20px;
    text-align: center;
    .statistic {
      .custlab {
        margin-top: 12px;
      }
      .custlab, .custval {
        font-size: 15px;
        text-transform: capitalize;
        color: lighten($cus-black, 36%);
      }
      .custval {
        font-size: 45px;word-spacing: -10px;
        font-weight: 400;margin-bottom: 8px;
        .fa {
          font-size: 30px;display: inline-block;vertical-align: top;margin-top: 8px;
        }
      }
    }
  }
}
/****************** Jiva custom radio btn ***************************/

.plusicon{
  cursor: pointer;
  @include flexbox();
  align-items: center;
  justify-content: flex-start;
  padding: 8px 0px;
  float: right;
  img, span{
    font-size: 14x;
    padding: 0px 4px;
    font-weight: 600;
    color:lighten($bk-clr, 40%);
  }
img{max-width: 30px;}
}
 .up-rd-con{
  width:100%;
  overflow: hidden;
  padding:0px;
  @include flexbox();
  justify-content: start;
  flex-flow: row wrap;
  margin:8px auto;

  .radio-bx{
    flex:0 auto;
    margin: 0px;
    min-width: 18%;
    label{
      position: relative;
      margin:0px;
      padding:0px;
      cursor: pointer;
      input[type="radio"]{
        opacity: 0;
        margin:0px;
        position: absolute;
        pointer-events: none;
      }
      .dummyradio{
        width:18px;
        height:18px;
        border: 1px solid #ccc;
        @include custom-border-radius(50%);
        background: transparent;
        position:relative;
        display: inline-block;
        vertical-align: middle;
        .pointer{
          opacity: 0;
          position: absolute;
          top:0;left:0;right:0;bottom: 0;
          margin:auto;background: $bl-cr;
          width:10px;height:10px;
          @include custom-border-radius(50%);
        }
      }
    }
    input[type="radio"]:checked + .dummyradio{
      border:1px solid $bl-cr;
      .pointer{opacity: 1;}
    }
    span.text{
      color:lighten($cus-black, 35%);
      font-size: 15px;
      padding-left: 10px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
 .up-rd-bt-con{
   width:100%;
   overflow: hidden;
   padding:0px;
   @include flexbox();
   justify-content: start;
   flex-flow: row wrap;
   margin:8px auto;
   .up-rd-bt{
     flex: 1 auto;
     width: auto;
   //  margin: 0px ;
     position: relative;
     min-height: 55px;
     height:auto;
    // margin-right: 12px;


label{
  cursor: pointer;
  background: $wt-clr;
  padding: 0px;

  width: 100%;
  height:auto;
  position: relative;
  @include flexbox();
  align-items: center;
  justify-content: space-between;

  input[type="radio"]{
    cursor: pointer;
    width: 100%;height:100%;position: absolute;top:0;right:0px;left:0px;bottom:0px;
    opacity: 0;pointer-events: none;
  }
  .br{
    border: 1px solid lighten($bk-clr, 82%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    @include custom-border-radius(4px);
  }
  .bx{


    flex: 0 auto;
    padding: 12px;
    @include flexbox();
    align-items: center;
    justify-content: flex-start;
    .img-cont, .cont{
      flex: 1 auto;
    img{
      max-width: 50px;
    }

      .def-hide{display: none;min-height: 40px;}
    }
    .cont{padding-left: 10px;}
  }
  input[type="radio"]:checked + .br{border:2px solid lighten($bl-cr, 12%);}
  input[type="radio"]:checked ~ .bx .img-cont .def-hide {display: flex !important;

  }
}
   }
 }

.jiva-img-radio-con{
  @include flexbox();
  justify-content: center;
  flex-flow:row wrap;
  margin:12px auto;
  width:100%;
  .jiva-img-radio-btn{
    position: relative;
    text-align: center;
    margin: 0px 10px;
    width: 65px;height: 65px;
    label {
      border: 1px solid lighten($ji-black, 55%);
      @include custom-border-radius(6px);position: absolute;
      text-align: center;top:0;left: 0;
      height:65px;width: 65px;
      img{
        position: absolute;
        top:0;
        left:0px;
        right: 0;
        bottom: 0;
        margin:auto;
        width:65px;
        padding: 6px;
        pointer-events: none;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
      input[type="radio"]{
        margin:auto;opacity: 0;cursor: pointer;
        width:65px; height: 65px;
      }
      input[type="radio"]:checked + img{
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);
      }
      p.text-center{
        color:lighten($ji-black, 30%);
        position: absolute;
        bottom: -20px;
        font-size: 12px;
        left: 0;
        right: 0;
        font-weight: 200 !important;
        text-transform: uppercase;
        line-height: 20px !important;
      }
      input[type="radio"]:checked ~ p.text-center{
        color:$ji-org-color !important;
      }
    }
  }
}
.jiva-flatradio-con{
  @include flexbox();
  justify-content: space-between;
  flex-flow:row wrap;
  margin:10px 0px;width:82%;
  @media all and (max-width:480px){
    width:100%;
  }
  h4{
    flex:0 100%;
    color: $ji-org-color;
    font-weight: $ji-font-200;
    margin-bottom:5px;
    font-size: 14px;
  }
  .jiva-flatradio-btn{
    position: relative;
    text-align: center;
    width: 120px;
    height: 45px;
    label {
      text-align: center;
      height:50px;cursor: pointer;
      width: 100%;position: absolute;
      top:0;left: 0;
      .dummylabel{
        border: 1px solid lighten($ji-black, 55%);
        @include custom-border-radius(3px);
        text-align: center;
        height:50px;top:0;
        width: 100%;left: 0;
        position: absolute;
      }
      img{
        position: absolute;
        top:0;left:0px;bottom: 0;
        margin:auto;padding: 6px;
        width:50px;pointer-events: none;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
      input[type="radio"]{
        margin:auto;
        width:100%;
        height:100%;
        opacity: 0;
        cursor: pointer;
      }
      input[type="radio"]:checked ~ img{
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);
      }
      p.text-center{
        color:lighten($ji-black, 30%);
        position: absolute;
        font-size: 12px;
        top:16px;left: 26px;right: 0;
        font-weight:$ji-font-600;
        text-transform: uppercase;
        line-height: 20px !important;
      }
      input[type="radio"]:checked ~ p.text-center{
        color:$ji-org-color !important;
      }
    }
    input[type="radio"]:checked + .dummylabel{
      border:1px solid $ji-org-color;
    }
  }
}
.jiva-blk-flatradio-con{
  @extend .jiva-flatradio-con;
  width:100%;
  height: auto;
  @include flexbox();
  justify-content: flex-start;

 // background: $ji-white;
  .jiva-blk-flatradio-btn{
    width:47%;
    height:40px;
    position: relative;
    margin-right: 4px;

    label {
      text-align: center;
      height:100%;cursor: pointer;
      width: 100%;top:0;left: 0;
      position: absolute;
      .dummylabel{
        border: 1px solid lighten($bk-clr, 85%) ;
        @include custom-border-radius(3px);
        text-align: center;
        height:40px;width: 100%;
        position: absolute;
        top:0;left: 0;
        @include cus-boxshadow(0px,3px, 12px,0px rgba(0,0,0,0.1));
        &::before{
          content:'';
          position: absolute;
          width:18px;
          height:18px;
          left: 10px;
          top: 9px;
@include custom-border-radius(50%);
border: 1px solid lighten($bk-clr, 90%);
        }
        &::after{
          content:'';
          position: absolute;
          width:10px;
          height:10px;
          left: 14px;
          top: 13px;
          @include custom-border-radius(50%);
          background: transparent;

        }
      }
      input[type="radio"]{
        margin:auto;
        width:100%;height:100%;
        opacity: 0;
        cursor: pointer;
      }
      p.btn-txt{
        color:lighten($ji-black, 30%);
        position: absolute;
        font-size: 15px;
        text-transform: capitalize !important;
        top:9px;
        left: 0px;
        right: 0px;
        font-weight:600;
        text-transform: uppercase;
        line-height: 20px !important;
        width: 90%;
        margin: auto;
        height: auto;
padding-left: 4px;
        align-items: center;
        span{
          b, img{
            display: inline-block;
            vertical-align:middle;
            font-weight: 600;
          }
          img{
            width:65px;
            margin-left: 8px;
           @media all and (max-width: 420px) {
             display: none;

           }
          }
        }
        .fa-check {
          display: none;font-size: 30px;
        }
      }
     /* input[type="radio"]:checked ~ p.btn-txt {
        color:$ji-org-color !important;
      }*/
     /* input[type="radio"]:checked ~ p.btn-txt .fa{
        display:inline-block!important; color:$ji-org-color !important;
      }*/
    }
    input[type="radio"]:checked + .dummylabel{
      border:1px solid $upsure-blu;
    }
    input[type="radio"]:checked + .dummylabel::before{
      border:1px solid $upsure-blu;
    }
    input[type="radio"]:checked + .dummylabel::after{
      background: $upsure-blu;
    }
  }
}
/**************************** Jiva custom Loader styles starts here ********************************/
.jiva-slidingloader, .jiva-loader{
  position: relative;
  width:100%;
  height:100%;
  height: 350px;
  overflow: hidden;
  padding:20px;
  z-index: 99;
  .slid-round, .loaderdiv{
    width: 16px;height: 16px;
    position:absolute;margin:auto;
    top:0px;bottom: 0px;
    @include custom-border-radius(50%);
    &:nth-child(1){
      background:$ji-green-color;
      -webkit-animation: slidingmove 2.5s infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
      animation: slidingmove 2.5s infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
    }
    &:nth-child(2){
      background:$ji-yel-color;
      -webkit-animation: slidingmove 2.5s 150ms infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
      animation: slidingmove 2.5s 150ms infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
    }
    &:nth-child(3){
      background:$ji-org-color;
      -webkit-animation: slidingmove 2.5s 300ms infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
      animation: slidingmove 2.5s 300ms infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
    }
    &:nth-child(4){
      background:$ji-red-color;
      -webkit-animation: slidingmove 2.5s 450ms infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
      animation: slidingmove 2.5s 450ms infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
    }
    &:nth-child(5) {
      background-color: $ji-darkred-color;
      -webkit-animation: slidingmove 2.5s 600ms infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
      animation: slidingmove 2.5s 600ms infinite cubic-bezier(0.1, 0.64, 0.81, 0.23);
    }
  }
}
@-webkit-keyframes slidingmove {
  0% {left: 10%;  }
  100% {left: 90%;}
}
@keyframes slidingmove {
  0% {left: 10%;}
  100% {left: 90%;}
}
/**************************** Jiva custom Loader styles ends here ********************************/
/**************************Accordion styles starts here  ****************************************/
.jiva-cust-accordion{
  padding:0px;
  overflow: hidden;margin-bottom: 20px;
  background: transparent !important;
  //@include custom-border-radius(3px 3px 0px 0px !important) ;
  @include custom-border-radius(0px !important) ;
  @include cus-boxshadow(0px,0px, 0px, #ccc !important);
  .jiva-cust-accordion-heading, .active{
    color:$ji-white!important;
    background:$ji-org-color!important;
    position: relative;
    @include custom-border-radius(0px !important) ;
    .custicon{
      position: absolute;top:16px;right:16px;
    }
  }
  .jiva-cust-accordion-cont{
    background: transparent !important;
    padding: 0px !important;
    ul{
      padding:0px;
          li{
        line-height: 54px;width:100%;
     //   background:$ji-white;
        a, span{
          padding-left: 20px;cursor: pointer;
          color:#505050;
          font-weight: 600;
        }
      }
      li.act{
        background:lighten($ji-black, 90%);
        border-left: 6px solid $ji-org-color;
      }
    }
  }
}

.multiple-accordion{
  width:100%;
  @include cus-boxshadow(0px, 1px, 1px, 2px lighten($ji-black, 70%));
  @include custom-border-radius(6px);
  overflow: hidden;
  .accor-box{
    position: relative;
    border-bottom: 1px solid lighten($ji-org-color, 20%);
    width: 100%;color:$ji-white;overflow: hidden;
    &:last-child{
      border-bottom: 0px solid lighten($ji-org-color, 20%);
    }
    input {
      position: absolute; opacity: 0;  top:0; left:0;
      width:100%;height:50px; cursor: pointer;
    }
    label {
      position: relative;
      display: block;pointer-events: none;
      padding: 0 0 0 1em;
      background: $ji-org-color;
      font-weight: bold;
      line-height: 50px;
      &::after {
        content: "+";
        position: absolute; right: 0;  top: 0;
        display: block;
        width: 3em;height: 3em;
        line-height: 3;
        text-align: center;
        -webkit-transition: all .35s;
        -o-transition: all .35s;
        transition: all .35s;
      }
    }
    .accor-content {
      height:0px;
      min-height: 0px;
      height: auto;
      overflow: hidden;
      background: $ji-white;
      -webkit-transition: min-height .35s;
      -o-transition:min-height .35s;
      transition: min-height .35s;
      -moz-transition: min-height .35s;
      p {
        margin:0px auto;
        font-size: $ji-small-text;
        color: lighten($ji-black, 15%);
        line-height: 18px !important;
        font-weight: 200 !important;
      }
    }
    input:checked ~ .accor-content {min-height: 20px;padding:12px; }
    input[type="checkbox"]:checked + label::after {  transform: rotate(315deg);  }
  }
}

.jivaaccordion{
  @extend .multiple-accordion;
  @include cus-boxshadow(0px, 0px, 0px, 0px lighten($ji-black, 70%));
  @include custom-border-radius(0px);
  .accor-box{
    border-bottom: 1px solid darken($ji-org-color, 18%);
    background: transparent;

  label {
    position: relative;
    display: block;pointer-events: none;
    padding-left:24px !important;
    background: $ji-org-color;
    font-weight: bold;
    line-height: 50px;
    &::before {
      content: "\f0da";
      position: absolute;
      left: 20px;
      top: 0;
      display: block;
      font-family: FontAwesome;
     // width: 3em;
      //height: 3em;
     // line-height: 3;
      text-align: center;
      -webkit-transition: all .05s;
      -o-transition: all .05s;
      transition: all .05s;
    }
    &::after{
      content: "" !important;
    }
    span{padding-left: 20px;}
  }
  .accor-content{
    background: transparent;
    height:0;
    ul{
      li{
        line-height: 54px;
        width:100%;
        span{
          text-transform: capitalize;
          font-size: 15px;
          padding-left: 26px;
          cursor: pointer;
          color: #505050;
          font-weight: 600;
        }


      }
      li.act{
        background: #e6e6e6;
        border-left: 6px solid $ji-org-color;
       span{ padding-left: 20px;}
      }

    }
  }
    input[type="radio"]:checked ~ .accor-content {min-height: 20px;padding:0px;height:auto; }
    input[type="radio"]:checked + label::before {  transform: rotate(90deg);  }
  }
}

/**************************Accordion styles ends here  ****************************************/
/****************************** Modal styles starts here ***************/
.md-xs{
  max-width: 420px !important;
 // max-height: 480px;
 // overflow-y: scroll;
  @media all and (max-width: 767px){
    max-width: 100% !important;
  }
}
.md-sm{
  max-width: 600px !important;
 /// max-height: 640px;
//  overflow-y: scroll;
  @media all and (max-width: 767px){
    max-width: 100% !important;
  }
}

.md-lg{
  max-width: 1024px !important;
 // max-height: 767px;
 // overflow-y: scroll;
  @media all and (max-width: 767px){
    max-width: 100% !important;
  }
}
.ji-cust-modal{
  margin:auto;
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
z-index: 9999;
  @include flexbox();
  align-items: center;
 // display: table-cell;
  vertical-align: middle;
  width:auto;
  //min-height: 200px;
  height: auto;
  overflow-y: auto;
  //height:fit-content;
  -webkit-animation: modal-anim .3s ease-in-out forwards;
 .jiva-mod-content{
     @include custom-border-radius(3px);
   // padding:20px;
    background: $ji-white;
    z-index: 100;
   position: relative;
    margin: auto;
    @include cus-boxshadow(0, 5px, 15px, rgba(0,0,0,.5));
   .cls-btn{
     position: absolute;
     top: 10px;
     right: 16px;
     color:lighten($ji-black, 46%);
    cursor: pointer;
     z-index: 99;
     .fa{
       font-size: 14px !important;
       color:lighten($ji-black, 80%);
     }

     // border: 1px solid lighten($ji-black, 36%);
     // @include custom-border-radius(50%);
     //padding:4px 6px;
     //cursor: pointer;
     // font-size: 14px;
   }
    .modal-header{
      position: relative;
      padding:26px 20px;
    //  background: lighten($ji-black, 92%);
border-bottom: 1px solid lighten($ji-black, 94%);
      display: block !important;
      h2{
        color: $ji-org-color;
        text-transform: uppercase;
        font-size:16px;
      }
      img{
        max-width: 140px;
      }
    }
   .modal-body{
     padding:0px ;
     height: auto;

   }
   .modal-footer{
     padding:14px;
     background: lighten($ji-black, 94%);
   }
  }
&::before{
  content: '';
  width:100%;
  height:100%;
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
  background: $ji-black;
  opacity: .7;
  z-index: -1;
}
}
.sm{
  max-width: 480px;
  min-height: 240px;
}
.md{
  max-width: 600px;
  min-height: 240px;
}
@-webkit-keyframes modal-anim{
  0% {
    top:-50px;
    opacity: 0;
  }
  10% {
    top:-40px;
    opacity: 1;
  }
  100% {
    top:25px;
    opacity: 1;
  }
}
@keyframes modal-anim{
  0% {
    top:-50px;
    opacity: 0;
  }
  10% {
    top:-40px;
    opacity: 1;
  }
  100% {
    top:25px;
    opacity: 1;
  }
}

/****************************** Modal styles ends here ****************/
.jiva-card{
  width:100%;
  max-width:100%;
  padding: 20px 16px 10px !important;
  height: auto;
  .card-header{
    .heading, .halfimg {
      flex:0 40% !important;
      h2.title-4{text-align: left !important; }
      @media all and (max-width: 480px){
        padding:0px !important;
      }
    }
    .logo-heading{
      flex:0 54% !important;
      padding:0px 0px 0px 14px !important;
      @media all and (max-width: 480px){
        flex:0 52% !important;
      }
      .logo-img{max-width: 70px;padding: 4px 0;}
      .logo-title{
        font-size: 12px;text-transform: capitalize;
      }
      h2.title-4{
        margin:0px !important;
      }
    }
    .jivalogo{
      padding:20px 0px 0px 10px  !important;
      align-self: flex-start !important;
    }
    .logo-half-img1, .logo-half-img2, .logo-half-img3{
      flex:0 40% !important;
      background: url('../../assets/images/happyfamily.jpg') no-repeat ;
      background-position:50% 0px;background-size: cover; width:100%;
      //height:100%;
      height: 120px;
    }
    .logo-half-img2{
      background: url('../../assets/images/healthplus.jpg');
      background-position: left;
    }
    .logo-half-img3{
      background: url('../../assets/images/health.jpg');
      background-position: left;
    }
    .heading{
      @media all and (max-width: 480px){
        padding:10px !important;
      }
    }
  }
  .card-body{
    margin:0px auto 20px;
  }
  .card-footer{
    @include flexbox();
    justify-content: center;
  }
  .fot-abs{
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 10px;
    top: calc(100% - 60px);
  }
}

.jiva-card, .productcard, .fullimgcard, .ticketcard{
  min-height: 280px;
  padding: 20px 20px 10px;margin:10px auto;
  overflow: hidden;background: $ji-white;
  position: relative;@include custom-border-radius(4px);
  box-sizing: border-box;
  @include cus-boxshadow(1px, 1px, 6px, 0px lighten($ji-black, 75%));
  @media all and (max-width: 480px){  width:100%;}
  .card-header{
    background: transparent;
    @include flexbox();
    flex-flow: row wrap;justify-content: center;min-height:70px;
    overflow: hidden;border-bottom: 1px solid lighten($ji-black, 85%);
    .heading, .halfimg, .logoimg{
      flex:0 50%;align-self: center;
      padding: 4px 0px;
      @media all and (max-width: 480px){
        flex:0 100%;padding:5px 10px ;
      }
      h3.title-2{
        color: lighten($ji-black, 30%); margin:6px auto;font-size: 16px;
      }
     p.title-1{
        font-size: 12px;margin:12px auto 0px;
        font-weight: 200 !important;line-height: 100% !important;
        color: lighten($ji-black, 65%);
      }
      h2.title-4{
        font-weight: 200; line-height: 110% !important;
        .fa{
          font-size: $ji-small-text; margin-top: 9px;vertical-align: top;
          @media all and (max-width: 480px){
            margin-top: 0px;
          }
        }
      }
    }
    .logoimg{
      padding: 4px 0px ;
      img{  max-width: 160px;  }
    }
    .fullimg{flex: 0 100%;text-align: center;
    img{
      max-width: 100%;
    }
    }
    .halfimg{
      padding: 0px;
      img{ width:100%;}
    }
    .pri-logo{
      max-width: 170px;
      padding:10px 0px 0px;
      display: block;
      height: auto;
    }

  }
  .order-sum-header{
    display: block !important;
    min-height: 60px !important;
  }
  .card-body{
    padding: 10px 0px;
    min-height: 30px;
    .po-dt{
      padding: 10px 20px 10px;
      text-align: center;
      p{
        font-size:15px;

        color:lighten($ji-black, 30%);
        &:nth-of-type(2){
          color:$ji-org-color;
        }
      }
    }
    .ply-row{
      padding:0px 14px 6px ;
      span{
        font-size:16px;
        color:lighten($ji-black, 40%);
        font-weight: 600;
        text-transform: uppercase;
        &:nth-of-type(2){
          color:$ji-org-color;
        }
      }
    }
    .pre-plan{
     color: lighten($ji-black, 50%);
      padding-bottom: 18px;
    }
    .pri-cover{
      padding:0px 20px;
      font-size: 26px;
      @include flexbox();
      justify-content: center;
      flex-flow: row wrap;
      span{
        flex:1 50%;
        align-self: center;
        color: $ji-org-color;
        text-align: right;

        .fa{
          vertical-align: top;
          margin:8px 3px 0px 0px;
          font-size: 30px;

        }
        &:nth-of-type(2){
          color:lighten($ji-black, 32%);
          text-align: left;
          font-weight: 400;
          font-size: 30px;
        }
      }

    }
    .plan{
      font-size: 30px;
      color:lighten($ji-black, 20%);
      padding: 0px 0 4px;
    }
    .prod-box{
      @include flexbox();
      justify-content: space-around;
      flex-flow: row wrap;padding: 12px 0px 14px;
      .innerbox{
        flex:0 50%; align-self: center;
        p.labelname, p.labelvalue{ color:lighten($ji-black, 30%);}
        p.labelvalue{
          font-size: 36px;
          @media all and (max-width: 480px){font-size: 26px; }
          .fa{
            font-size: $ji-small-text;margin-top: 14px;
            vertical-align: top;float: left;
            @media all and (max-width: 480px){
              margin-top: 10px; float: left;
            }
          }
          span{ text-align: left;  }
        }
        &:nth-of-type(2){
          p.labelvalue{ color:$ji-org-color; }
        }
      }
    }
    .refblk{
      @include flexbox();
      justify-content: space-between;flex-flow: row wrap;
      .refnum{
        flex: 0 45%;margin:8px 0px;
        align-self: center;
        p {
          color: lighten($cus-black, 50%);
          margin: 0px;line-height: 130% !important;
          &:nth-of-type(2) {
            color: lighten($cus-black, 20%);
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        h2 {position: relative; color: #05a479;font-weight: 600;font-size: 32px;
          &:before {
            content: '';position: absolute;width: 8px;height: 8px;
            @include custom-border-radius(50%);
            background: #05a479;left: -16px;top: 15px;
          }
        }
        &:nth-of-type(3){
          flex: 0 100%;
          p.title-1{   text-align: center; text-transform: capitalize; }
        }
      }
    }

      ul.prod-list, ul.list {
      padding: 6px 0;margin: 0px;
      @media all and (max-width: 480px){ padding: 12px 0; }
      li {
        padding-left: 16px; line-height: 28px;display: block;position: relative;
        text-align: left;
        font-size:$ji-small-text;color: lighten($ji-black, 40%);
        .fa {
          position: absolute;
          top:7px;
          left:0;vertical-align: top;display: inline-block;
          color: $ji-org-color;margin-right: 5px;
        }
        span{
         // padding-left: 16px;
          vertical-align: top;display: inline-block;line-height: 24px;
        }
      }
      li.dotlist{
         position: relative;
        &::before{
          content: '';
          position: absolute;
          top:10px;left:0;bottom: 0;
          width:7px;height: 7px;
          background:$ji-org-color;
          @include custom-border-radius(50%);
        }
      }
    }
    ul.list{
      li
    {
      font-size: $ji-small-text !important;
    }
    }
  }
  .card-footer{
  background: transparent;
    border: none;
     }
  .broc-div{
    @include flexbox();
    justify-content: space-between;
    flex-flow: wrap;
    margin: 10px auto;
    a{
      flex: 1 48%;
      align-self: center;
      margin-right:6px;
      background: lighten($ji-org-color, 37%);
      text-align: center;
      font-size: 12px;
      color:lighten($ji-black, 34%);
      padding:16px 10px;
      .fa{
        font-size: 30px;
      }
      p{
        width: 100%;}
      &:nth-of-type(2){
        margin: 0px;
      }
    }
  }
  }


.br-crd{
  width: 100%;
  border: 1px solid lighten($bk-clr, 70%);
  padding: 20px 10px;
  @include custom-border-radius(4px);
  @include flexbox();
  background: $wt-clr;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  box-sizing: border-box;
   .tick-img, .con{
flex: 0 auto;

    img{max-width:50px;width: 30px;margin: 10px;}

  }
  .v-img ,.v-con{
    display: inline-block;
    vertical-align: middle;
    padding: 0px 4px;


  }
  ul {
    li{
      @include flexbox();
      align-items: self-start;
      justify-content: flex-start;
padding-bottom:5px;
      font-size: 13px;

      span.des{padding-left: 6px;}

    }
  }

}

.pol-crd{
  @extend .br-crd;
  @include flexbox();
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  .bx{
    width: 70%;
    &:last-child{
      width: 30%;
      @include mx(576){
        width: 100%;
      }
    }
    @include mx(576){
      width: 100%;
    }
  }

}
.s-img, .s-icon{max-width: 25px;width: 100%;display: block;margin: auto ;}
.m-icon{
  @extend .s-icon;
  max-width: 35px;
}
.l-icon{  @extend .s-icon;max-width: 50px;}
.xl-icon{  @extend .s-icon;max-width: 80px;}
.sm-img{display: block;margin: auto ;max-width: 100%;width: 60px;}
.md-img{display: block;margin: auto ;max-width:180px;width: auto;}
.lg-img{@extend .md-img;max-width:320px;width: 100%;}
.sec-img{max-width: 440px;display: block;width: auto;
  margin: 0px auto;}

.br-crd-act{
  border: 1px solid lighten($bl-cr, 20%);
}

/*page loader css*/
.pg-ld-cont{
  position: fixed;
  top: 0;
  left:0;
  right:0;
  bottom:0px;

 // min-height: 300px;
  @include flexbox();
  align-items: center;
  width: 100%;
  height:100vh;
  justify-content: center;

  background: rgba(0,0,0, .3);
  .pg-ld {
    border: 6px solid darken($bl-cr,16%);
    border-radius: 50%;
    border-top: 6px solid $wt-clr;
    width:48px;
    border-bottom: 6px solid $wt-clr;
    border-left: 6px solid $wt-clr;
    height: 48px;
    -webkit-animation: spin .9s linear infinite; /* Safari */
    animation: spin .9s linear infinite;
    //  margin: 0px;
    margin: auto;
    box-sizing: border-box;
  }
}

.loader{
  position:absolute;
  top: 0;
  right: 0;
  left:0;
  bottom:0;
  margin:auto;
  width:100%;
  display:flex;
  height:100%;
  background: rgba(0,0,0, .7);
  .ld{
    border: 6px solid darken($bl-cr,16%);
    border-radius: 50%;
    border-top: 6px solid $wt-clr;
    width:48px;
    border-bottom: 6px solid $wt-clr;
    border-left: 6px solid $wt-clr;
    height: 48px;
    -webkit-animation: spin .9s linear infinite; /* Safari */
    animation: spin .9s linear infinite;
    //  margin: 0px;
    margin: auto;
    box-sizing: border-box;
  }
}


.pos-ab{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}

.addresscard{
  @extend .productcard;min-height: 230px !important;
  max-width: 100% !important;
  .card-header{
    .heading{
      flex: 0 100%;
      text-align: left;
      padding:15px 0px 30px;
      h3.title-2{text-align: left;  padding:0px;}
    }
  }
  .card-body{
    padding:20px 0px !important;
    .card-table{
      width:100%;
      .table{
        width:100%;
        thead > tr > th{color:$ji-org-color;text-transform: capitalize;}
        thead > tr > th, tbody > tr > td{
          text-align: left;padding:4px;
        }
      }
    }
    .address-con, .mem-con{
      @include flexbox();flex-flow: row wrap;
      h4.title-2{
        color:$ji-org-color; font-size: 15px;
        text-transform: capitalize;
        &:nth-of-type(1){ flex: 0 55%;}
        &:nth-of-type(2){flex: 0 25%;}
        &:nth-of-type(3){ flex: 0 20%;}
      }
      p.small-text{
        flex:0 35%;display: inherit;line-height: inherit;
        color:lighten($ji-black, 30%);
        &:nth-child(even){flex:0 60%;}
        span{ padding-right: 6px;}
      }
      .p-content{
        width:100%;padding:5px 0px;
        @include flexbox();
        flex-flow: row wrap;
        span{
          &:first-child{ flex: 0 55%;}
          &:nth-child(2){  flex: 0 25%;}
          &:last-child{ flex: 0 20%;}
        }
      }
    }
  }
}
.productcard{
  max-width: 500px;width:100%;min-height: 340px;
  border-top: 3px solid $ji-org-color;
  .card-header{height:auto;}
  .card-footer{
    @include flexbox();
    justify-content:center;
    flex-flow: row wrap;
    .box{
      flex:0 48%;align-self: center;
      &:nth-of-type(2){
        text-align: right;
      }
      @media all and (max-width: 480px){
        flex:0 100%;
      }
    }
  }
}
.compycard{
  @extend .productcard;
  min-height: 70px !important;
  position: relative;
  margin:10px auto;
  padding:10px 20px;
  width:100% !important;
  .card-header{
    border-bottom: none;
    .logoimg, .heading{
      @media all and (max-width: 480px){
        flex:1;
        img{
          max-width: 100px;
        }
      }
    }
    .heading{
      text-align: right;
      padding:0px;
      h3.title2{
        @media all and (max-width: 480px){
          font-size: 13px;
        }
      }
    }
  }
}
.fullimgcard{
  width:500px;
  .card-header{
    height:310px;
    .fullimg{
      position: relative;
      .fa-ellipsis-v{
        position: absolute;top:20px;right:20px;
        cursor: pointer;color:$ji-white; font-size: x-large;
      }
    }
  }
  .card-body{
    position: relative;
    .iconbox{
      position: absolute;
      top:-26px;right:0px;
      width:50px;height:50px;
      background: $ji-org-color; cursor: pointer;
      @include custom-border-radius(50%);
      @include cus-boxshadow(1px, 2px, 4px, rgba(0,0,0,0.4) !important );
      .fa{
        position: absolute;left: 16px;
        top: 16px;text-align: center;
        color: $ji-white; font-size: 30px;
      }
    }
    h2.title-4{width:100% !important; line-height: 100% !important;}
    h4.title-2{color: $ji-org-color; }
    p.title-1{line-height: 100% !important;margin-top: 6px;font-weight: 200 !important;}
  }
}

.ticketcard{
  padding: 20px ;border-top: none;
  @extend .productcard;
  .card-header{
    border-bottom: none;
    .fullheading{
      margin:10px auto;flex: 0 100%;
      p.title-2{
        text-align: center;font-size: 16px !important;
        color:lighten($ji-black, 36%) !important;
      }
      h2.title-4{text-align: center;margin:5px auto;width:100% !important;}
    }
    .bghead {
      width:100%;
      background-color: $cus-white;
      min-height: 100px;
      background-image: -webkit-linear-gradient(-95deg, lighten($ji-black, 55%) 64%, $ji-white 65%);
      padding: 0px 12px;
      h3 {
        color: $ji-white;font-size: $ji-title-18; padding: 13px;
      }
    }
  }
  .card-body{
    padding: 10px 0px;
    .jiva-cust-divider{ margin:10px auto; }

  }
}

/********************** Modal csss **********************/
.ups-modal{
  //  display: block; /* Hidden by default */
  @include flexbox();
  align-items: center;
  justify-content: center;
  position: fixed; /* Stay in place */
  z-index: 99; /* Sit on top */
  // padding-top: 0px; /* Location of the box */
  left: 0;
  top: 0px;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
  @include mx(768){
    padding: 10px;
  }
  .mod-cont{
    background-color: $wt-clr;
    margin: auto;
    padding: 20px;
    // border: 1px solid #888;
    width: 100%;
    position: relative;
    @include custom-border-radius(12px);
    .cl-icon{
      position: absolute;
      top: 20px;
      right: 20px;
      width: 20px;
      height: auto;
      cursor: pointer;
      img{
        max-width: 36px;
      }
    }
  }
  .modal-header{
    position: relative;
    padding: 0px !important;
    padding-bottom: 16px !important;
    display: block;
    .close {
      color: lighten($bk-clr, 87%);
      float: right;
      padding: 12px;
      position: absolute;
      top: 0px;
      right:9px;

      // font-size: 28px;
      // font-weight: bold;
      img{
        width: 16px;
        height: 16px;
      }
    }

    .close:hover,
    .close:focus {
      color: lighten($bk-clr, 30%);
      text-decoration: none;
      cursor: pointer;
    }
  }
  .modal-body{
    padding:0px ;
    height: auto;

  }
  .xs{
    max-width: 480px;
    overflow: hidden;
    height: auto;
    margin: auto;
    box-sizing: border-box;

  }
  .sm{
    @extend .xs;
    max-width: 600px;
  }
  .md{
    @extend .xs;
    max-width: 768px;
  }
  .lg{
    @extend .xs;
    max-width: 90%;
  }
}
/******************** Jiva progress steps *****************************/
.jiva-progresssteps{
  padding:20px ;margin:auto;
  overflow: hidden;
  counter-reset: step;
  width:100%;max-width: 800px;
  li {
    list-style-type: none;
    width: 25%;float: left;
    font-size: 12px;text-align: center;
    position: relative;
    text-transform: uppercase;
    color: lighten($ji-black, 36%);
    &::before{
      width: 30px;height: 30px;
      content: counter(step);
      counter-increment: step;
      line-height: 26px;display: block;
      border: 2px solid lighten($ji-black, 36%);
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: white;
    }
    &::after{
      width: 100%;
      height: 2px;content: '';
      position: absolute;
      background-color:lighten($ji-black, 36%);
      top: 15px;left: -50%;
      z-index: -1;
    }
  }
}
.jiva-progresssteps li:first-child:after {content: none;}
.jiva-progresssteps li.active {  color: $ji-org-color;}
.jiva-progresssteps li.active:before {border-color: $ji-org-color;}
.jiva-progresssteps li.active + li:after {background-color: $ji-org-color;}
.jiva-tabprogresssteps{
  padding:0px;margin:auto;
  max-width: 800px;line-height:45px;
  width: 100%;clear: both;
  text-align: center;
  .tabprogress{
    position: relative;height:50px;
    label{
      position: relative;background:$ji-white ;width:20%;
      display: inline-block;line-height: 50px;cursor: pointer;
      border-right:none;
      @include custom-border-radius(3px);
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      input[type="checkbox"]{ display: inline-block;vertical-align: middle;opacity: 0;}
      input[type="checkbox"]:checked + span{
        background:$ji-org-color;color:$ji-white;border:1px solid $ji-org-color;
        &:after{background: $ji-org-color;}
      }
      span{
        font-size: 14px;padding-left: 12px;
        position: absolute;
        top:0;left:0;padding: 0px;
        width:100%;height: 100%;
        color:$ji-black;
        border:1px solid #ccc; border-right: none;
        &:after{
          content: '';position: absolute;right: -1px;
          z-index: 2;top: 50%;
          background-color:$ji-white;
          width: 1.14285714em; height: 1.14285714em;
          border-style: solid;
          border-color: rgba(34,36,38,.22);
          border-width: 0 1px 1px 0;
          -webkit-transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
          transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
          -webkit-transform: translateY(-50%) translateX(50%) rotate(-45deg);
          transform: translateY(-50%) translateX(50%) rotate(-45deg);
        }
      }
      &:last-child{
        border-right: 1px solid #ccc;
        @include custom-border-radius(3px);
        span{
          &::after{   display: none;}
        }
      }
    }
  }
}
/******************** Jiva progress steps *****************************/
/********************************Jiva tabs css ***********************************/
.jiva-cust-tab{
  width:100%; padding:10px;
  ul{
    li{
      padding:0px;margin:0px 6px;
      position: relative;
      line-height:50px;
      vertical-align: middle;display: inline-block;
      input[type="radio"]{
        position: absolute;
        top:0;left:0;opacity: 0;
        width:100%; height:100%;
      }
      label{
        width:100%;
        padding:12px 20px;
        border:1px solid #ccc;
        @include custom-border-radius(3px);
        pointer-events: none;
        font-size: 14px;
      }
      input[type="radio"]:checked + label{ border:1px solid $ji-org-color;  color:$ji-org-color;
      }
    }
  }
  #cont1, #cont2, #cont3 { position: relative; width:100%;padding: 20px 0 0;}
  #cont2, #cont3{  display: none;}
  #tab1:checked #cont1, #tab2:checked ~ #cont2, #tab3:checked ~ #cont3 {display: block !important;}
}
/************************** jivaheader styles starts here *****************************/

.inr-pag-header{
  // @extend .upsureheader;
  background: $ji-white;
  animation:slide-down 0.7s;
  padding: 0px 3.2% !important;
  @include cus-boxshadow(2px, 0px, 10px, 2px lighten($ji-black, 90%));
  .rightsidemenu{
    .menu-icon{
      display: none;
      @media all and (max-width: 768px) {
        display: inline-block;
        margin-right: 24px;
        font-size: 30px;
        color: $ji-black;
        border-left: 0px ;
      }
    }
    ul {
      li{

a{color:$bl-cr;}
      }
      li.active{
       /* a{
          color:$ji-white;
          padding: 8px 20px;
          background: #4895dc;
          @include custom-border-radius(30px);
        }*/
      }
    }
    .innersidemenu{
      left:0 !important;

        li{
          display:flex !important;
        }
   }
  }
}
@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-110%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.topfixedheader{
  position: relative; width:100%;
  background: lighten($ji-black, 98%);
  padding: 3px ;z-index:2;
  p{
    font-size: 12px;padding:4px 162px 6px 0px;color:#7d7d7d;
    @media all and (min-width: 769px) and (max-width: 1024px){padding:4px 98px 6px 0px; }
    @media all and (min-width: 641px) and (max-width: 768px){padding:4px 36px 6px 0px; }
    @media all and (max-width: 640px){padding:4px 0px; }
    span{
      padding:0px 10px;color:#171717;cursor: pointer;
      font-weight: 600;
      border-right: 1px solid lighten($ji-black, 40%);
      &:nth-of-type(2){
        border:none;
       padding:0 0 0 10px;
      }
    }
    span.active{ color:$ji-org-color !important;}
  }
}
.fixedheader{
  position: fixed;
  top:0;
  width:100%;
  animation:slide-down 0.7s;
  padding:10px 0px;
  box-sizing: border-box;
}
@keyframes slide-down {
  0% {
    opacity: 0.5;
    transform: translateY(-110%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/************************** jivaheader styles ends here *****************************/
/*******************************Basic info steps styles starts here ******************/
.basicinfo-steps-mobile{
  padding: 0px;width:100%;margin:0px;
  @include flexbox();
  justify-content: center;flex-flow: row wrap;
  a{
    flex:1 auto;padding:12px;text-align: center;align-self: center;color:$ji-white;
    background: lighten($ji-org-color, 28%);
  }
  a.active{background: $ji-org-color;}
  @media all and (min-width: 768px){display: none !important;}
}
/*******************************Basic info steps styles ends here ******************/
.jiva-fileupload-con{
  width:100%;
  padding:0px;
  margin:0px;
  height:50px;
  position: relative;
  input[type="text"]{ width:73% !important;}
  input[type="file"]{
    position: absolute;right:0px;max-width:115px;
    padding:8px 0px !important;cursor: pointer;
  }
  .jiva-pri-bor-btn{
    position: absolute;padding: 13px 30px !important;right:0px;pointer-events: none;
  }
}
/*********************Jiva normal custom table *******************/
.table-con{
  width:100%;max-width:1140px;
  overflow-x: auto;
  background: $ji-white;
  max-height: 540px;
  overflow-y: auto;
  position: relative;
  @include custom-border-radius(4px);
  @include cus-boxshadow(1px, 0px, 1px, rgba(0,0,0,0.3) !important );
  table{border-collapse: collapse;}
  table > thead > tr{border-bottom: 1px solid lighten($ji-black, 90%);}
  table > thead > tr > th {
    background: lighten($ji-org-color, 30%);
    padding:15px 10px;min-width: 180px;
  }
  table > tbody > tr{ border-bottom: 1px solid lighten($ji-black, 90%); }
  table > tbody > tr > td{
    padding:10px;vertical-align: middle;font-size: $ji-small-text;
  }
  table > tfoot{ background: lighten( $ji-black, 94%); }
  table > tfoot > tr > td{padding:5px;height:50px; }
}
.nr-table{
  @extend .table-con;
  max-width: 100%;
max-height: 100%;
  //overflow: hidden;
  &::-webkit-scrollbar {height:4px !important;width:2px; }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 10px;
  }
  table{
    width: 100%;
  }
  table > thead > tr > th {
    background: lighten($ji-org-color, 30%);
    padding:15px 10px;min-width: auto;
    text-align: left;
    vertical-align: middle;
  }
  table > tbody > tr > td{
   min-width: 120px;
    word-break: break-all;
  }
}
/*************Extra css need to be delete once check it************/
.dropdown {
  position: relative;display: inline-block;
}
.dropdown-content {
  display: none;position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;padding: 12px 16px;z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.dropdown:hover .dropdown-content {display: block;}


.jiva-flex-box, .flx-con{
  @include flexbox();
}

.jiva-flex-auto, .fx-auto{
  flex: 1 auto;
}

.jiva-flex-0, .fx-0{
  flex: 0 auto;
}

.address-brd{
  border: solid 1px lighten($cus-black, 84%);
  @include custom-border-radius(5px);

}
.org-brd{
  border: 2px solid $ji-org-color;
}

.br-sup-pag{
  // min-height: 500px;
  margin: auto;
  background:$ji-white;
  @include flexbox();
  // align-items: center;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
  min-height: 100vh;
  .cont{
    //width: 100%;
    flex: 0 100%;
    .sad-f{
      margin: 20px auto;
      display: block;
      width: 65px;
    }
    h1{
      color: $ji-org-color;
      // font-family: Montserrat;
    }
    p{
      //color:$ji-white;
      text-align: center;
      width: 45%;
      margin:6px auto;
      color:lighten($ji-black, 40%);
      @media all and(min-width: 560px) and (max-width: 768px){
        width: 70%;
      }
      @media all and (max-width: 559px){
        width: 90%;
      }
    }
  }
  .br-sup-img-con{
    padding: 40px 0px;
    width: 100%;
    text-align: center;
    flex: 0 100%;
    .box{
      display: inline-block;
      vertical-align: middle;
      height: auto;
      text-align: center;
      padding: 10px;
      width: 190px;
      border-right: 1px solid lighten($ji-black, 94%);
      cursor: pointer;
      &:nth-child(5){
        border: none;
      }
      @media all and (min-width: 560px) and (max-width: 768px){
        width: 33%;
        padding: 20px 10px;
      }
      @media all and (max-width: 559px){
        width: 100%;
        padding: 20px 10px;
      }
      @media all and (max-width: 1023px){
        border: none;
      }
      .spr-img{
        width: 90px;
        height: 90px;
        background: url('../../assets/images/browser.png') no-repeat;
        margin:12px auto;
        display: block;
      }
      .chr-img{
        background-position: -441px -5px;
      }
      .moz-img{
        background-position: -301px -5px;
      }
      .o-img{
        background-position: -165px -5px;
      }
      .ed-img{
        background-position: -572px -5px;
      }
      .saf-img{
        background-position: -22px -5px;
      }
      p{
        //color:$ji-white;
        text-align: center;
        width: 100%;
        margin:3px auto;
        color:lighten($ji-black, 40%);
      }
    }
  }
}

/* medical box css */
.md-con{
  padding: 20px 24px 8px;
  position: relative;
  border: 1px solid lighten($bk-clr, 85%);
  height:auto;
  min-height: 70px;
  background: $wt-clr;
  @include custom-border-radius(3px);
  margin-top: 6px;
  p.qs{font-size:14px;}
  .sb-qs-bk {
    @include flexbox();
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin:10px 0px;
    background: lighten($bk-clr,96%);
    padding:10px 20px ;
    .sb-qs, .jiva-cust-radio-btn-block{
      flex: 0 auto;
      color:lighten($bk-clr, 45%);
    }
    .jiva-cust-radio-btn-block{max-width: 180px;margin:10px 20px;
    .jiva-cust-radio-btn{max-width: 40%;width: 100%;}}
  }
}

.postredirection{
.postCard{
  .p-header{
    @include flexbox();
    justify-content: center;
  }
  .Circleimage{
    padding: 6px;
    img{
      @include custom-border-radius(50%);

    }
  }
  h2{
    font-size: $ji-title-18;
    color:lighten($ji-green-color, 30%);
  }
  p{
    opacity: 0.6;
  }
  .fa{
    opacity: 0.6;
  }
}

h2.atinbox{
  font-size: $ji-title-18;
  color:lighten($ji-green-color, 30%);
}
  ul.atinbox-list{
    list-style-type: disc;
    opacity: 0.6;
    display: inline-block;
    padding-left: 18px !important;
    max-width: 380px;
    width: 100%;
    li{
      padding: 5px 0px;
      display: list-item;
      font-size: 15px;
    }
  }
}

@-webkit-keyframes slidingmove {
  0% {left: 10%; }
  100% {left: 90%; }
}
@keyframes slidingmove {
  0% {
    left: 10%;
  }
  100% {
    left: 90%;
  }
}


.crd-con{
  @include flexbox();
  align-items: center;
  flex-flow: row wrap;
  justify-content: center;
  margin:0px -10px;

@include mx(767){
  margin: 10px;
}
}

/*upsure business card */
.business-card {
  flex: 0 auto;
  @include custom-border-radius(10px);
  min-height: 280px;
  position: relative;
  //background-color: $ji-white;
  width: 230px;
  cursor: pointer;
  padding: 12px;
  margin: 10px;
  @include cus-boxshadow(0px, 2px, 4px,0px lighten($bk-clr, 90%) );
  background: $ji-white;
  background-repeat: no-repeat;
  z-index: 0;
  &::after {
    content:'';
    position: absolute;
    z-index: -1;
    background:url('../../assets/images/premium-card-bg.png') no-repeat ;
    background-size: cover;
    background-position: top left;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    left: 0;
    filter: grayscale(1);
    filter:opacity(.5);
  }
  &:nth-of-type(2n + 1) {

    &::after {
      background:url('../../assets/images/premium-card-bg_01.png') no-repeat ;
      background-size: cover;
      background-position: top right;
      filter:opacity(.7);
    }
  }
  .tp-img{
   // margin-top: 20px;
    max-width: 100px;
display: block;
    height: 85px;
    object-fit: fill;
    margin: 20px auto 0px;
  }

  @media all and (min-width: 768px) and (max-width: 992px) {
    width: 22%;
  }
  @media all  and (max-width: 480px) {
    width:100%;
    max-width: 230px;
  }
  ul {
    li {
      padding: 4px 0px;
      display: block;
    }
  }
  ul.points{
    min-height: 160px;

    li{
      display: block;
      text-align: center;
//padding-left: 12px;
      font-size: 11px;
      font-weight: 500;
      position: relative;
      //border: 1px solid lighten($bk-clr, 80%);
      padding: 4px;
      color:lighten($bk-clr, 10%);
      @include custom-border-radius(2px);
      margin-bottom: 3px !important;
       }
  }
  span.planAmt {
    color: #D0BA4D;
  }

  /*.ms-ov{
    display: none;
  }*/
 /* .ms-out{
    display: flex;
  }*/
  &:hover{
  //  background: #dd244b;
  //  background: -moz-linear-gradient(top,  #dd244b 0%, #f6424d 100%);
  //  background: -webkit-linear-gradient(top,  #dd244b 0%,#f6424d 100%);
   // background: linear-gradient(to bottom,  #dd244b 0%,#f6424d 100%);
   // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dd244b', endColorstr='#f6424d',GradientType=0 );
    //box-shadow: 0px 0px 3px 2px lighten($ji-black, 62%) !important;
  /*  ul {
      li {

        color: $ji-white;
        &::after{

          background:$wt-clr;

        }
      }
    }*/
   /* p{
      color:$ji-white;
    }*/
 /*   .ms-ov{
      display: flex;
     }
    .ms-out{
      display: none;
    }*/
  }

}

.rd-act-crd{
  background: #dd244b;
  background: -moz-linear-gradient(top,  #dd244b 0%, #f6424d 100%);
  background: -webkit-linear-gradient(top,  #dd244b 0%,#f6424d 100%);
  background: linear-gradient(to bottom,  #dd244b 0%,#f6424d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dd244b', endColorstr='#f6424d',GradientType=0 );
p, ul >li{color:$wt-clr;}
}
.yelgrn-act-crd{
  background: #C9C227 !important;
  background: -moz-linear-gradient(top,  #C9C227 0%, #DDDF76 100%) !important;
  background: -webkit-linear-gradient(top,  #C9C227 0%,#DDDF76 100%) !important;
  background: linear-gradient(to bottom,  #C9C227 0%,#DDDF76 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#C9C227', endColorstr='#DDDF76',GradientType=0 );
  p{color:$wt-clr !important;
  span:nth-of-type(1){color:$wt-clr!important;}
  }
}
.purple-act-crd{
  background: #BD00E7 !important;
  background: -moz-linear-gradient(top,  #BD00E7 0%, #CF7CE5 100%) !important;
  background: -webkit-linear-gradient(top,  #BD00E7 0%,#CF7CE5 100%) !important;
  background: linear-gradient(to bottom,  #BD00E7 0%,#CF7CE5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#BD00E7', endColorstr='#CF7CE5',GradientType=0 );
  p{color:$wt-clr !important;
    span:nth-of-type(1){color:$wt-clr!important;}
  }
}

.org-act-crd{
  background: #FF7400 !important;
  background: -moz-linear-gradient(top,  #FF7400 0%, #FF9F63 100%) !important;
  background: -webkit-linear-gradient(top,  #FF7400 0%,#FF9F63 100%) !important;
  background: linear-gradient(to bottom,  #FF7400 0%,#FF9F63 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FF7400', endColorstr='#FF9F63',GradientType=0 );
  p{color:$wt-clr !important;
    span:nth-of-type(1){color:$wt-clr!important;}
  }
}

