@import "../css/themes.scss";
@import "../css/mixins.scss";

.japan-footer-main {
  // background: #010B0D;
  float: left;
  width: 100%;
  min-height: 680px;
  .ftbg {
    background-image: url("https://cdn.upsure.io/newui2023/footer/footerbg_03.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    display: block;
    width: 100%;
    min-height: 804px;
  }
  .container {
    position: relative;
    .ft-sec {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-flow: column;
      width: 100%;
      padding-top: 140px;
      
      .ft-flex{
        @include flexbox();
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        .japan-upsure{
          background-color: #CC4125;
          border-radius: 10px;
          border: solid 2px $white-color;
          height: 321px;
          width: 265px;
          position: relative;
          @include upsure-md {
            margin-bottom: 3rem;
          }
          @include upsure-xs{
            margin-bottom: 3rem;
          }
          p{
            font-size: 18px;
            color: $white-clr;
            padding: 2rem 0rem 0rem 2rem;
          }
          h2{
            font-size: 18px;
            color: $white-clr;
            padding: 0.2rem 0rem 1rem 2rem;
          }
          .takshi-image{
            position: absolute;
    bottom: -3px;
    right: 0px;
    img{
      width: 250px;
    }
          }
        }
        .jp-left{
          text-align: center;
          h3 {
            flex: 0 auto;
            width: 100%;
            font-weight: bold;
            color: $white-color;
            text-align: center;
            @include upsure-xs {
              font-size: 28px;
             // padding-top: 55px;
             padding-bottom: 3rem;
            }
            @include upsure-md {
              font-size: 36px;
             // padding-top: 140px;
             padding-bottom: 3rem;
            }
            @include upsure-lg {
              font-size: 36px;
             // padding-top: 140px;
             padding-bottom: 3rem;
            }
          }
          p {
            flex: 0 auto;
            color: $white-color;
            text-align: center;
            @include upsure-xs {
              width: 100%;
              font-size: 18px;
              font-weight: 400;
              padding-top: 6px;
            }
            @include upsure-md {
              width: 585px;
              padding-top: 10px;
              font-size: 16px;
              font-weight: 500;
            }
            @include upsure-lg {
              width: 585px;
              padding-top: 10px;
              font-size: 16px;
              font-weight: 500;
            }
          }
          a.ft-btn {
            flex: 0 auto;
            width: 200px;
            background: #46bfca;
            font-size: 16px;
            font-weight: 400;
            color: $white-color;
            text-align: center;
            line-height: 24px;
            text-decoration: none;
            padding: 14px 50px;
            margin: 0px;
            outline: none;
            border: none;
            border-radius: 6px;
            cursor: pointer;
            @include upsure-xs {
              margin-top: 20px;
            }
            @include upsure-md {
              margin-top: 80px;
            }
            @include upsure-lg {
              margin-top: 80px;
            }
          }
        }
      }
     
  
    }
    .flk-log-sec {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-flow: row wrap;
      border-top: #8a8a8a solid 1px;
      border-bottom: #8a8a8a solid 1px;
      @include upsure-xs {
        margin-top: 45px;
        padding: 40px 0px 30px;
      }
      @include upsure-md {
        padding: 40px 0px 65px;
        margin-top: 180px;
      }
      @include upsure-lg {
        padding: 40px 0px 65px;
        margin-top: 180px;
        justify-content: space-between;
        width: 100%;
      }

      .ft-item01 {
        flex: 0 auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        @include upsure-xs {
          width: 100%;
          margin-left: 0px;
          margin-bottom: 30px;
        }
        @include upsure-md {
          width: 160px;
          margin-left: 0px;
        }
        @include upsure-lg {
          width: 15%;
          margin-left: 0px;
        }
        .ft-logo {
          flex: 0 auto;
          @include upsure-xs {
            width: 100%;
            text-align: center;
          }
          @include upsure-md {
            width: 121px;
            height: 62px;
          }
          @include upsure-lg {
            width: 121px;
            height: 62px;
          }
          img {
            flex: 0 auto;
            width: 121px;
            height: 62px;
          }
        }
        .ft-logo-cnt {
          flex: 0 auto;
          @include upsure-xs {
            width: 100%;
            text-align: center;
          }
          @include upsure-md {
            width: 121px;
            text-align: left;
          }
          @include upsure-lg {
            width: 121px;
            text-align: left;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            color: #ff8a00;
            padding: 20px 0px 0px;
            margin: 0px;
            @include upsure-xs {
              font-size: 16px;
            }
          }
        }
      }
      .ft-item02 {
        flex: 0 auto;
        color: $white-color;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        @include upsure-xs {
          width: 50%;
        }
        @include upsure-md {
          width: 184px;
        }
        @include upsure-lg {
          width: 11%;
          margin-left: 10px;
        }
        .hm-lnks {
          flex: 0 auto;
          width: 100%;
          @include upsure-xs {
            padding-top: 38px;
          }
          @include upsure-md {
            padding-top: 58px;
          }
          @include upsure-lg {
            padding-top: 66px;
          }
          a {
            flex: 0 auto;
            width: 100%;
            font-size: 13px;
            font-weight: normal;
            color: $white-color;
            text-align: left;
            width: 100%;
            display: block;
            margin-bottom: 4px;
          }
        }
      }
      .ft-item03 {
        flex: 0 auto;
        color: $white-color;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        @include upsure-xs {
          width: 50%;
        }
        @include upsure-md {
          width: 220px;
        }
        @include upsure-lg {
          width: 18%;
          margin-top: 28px;
        }
        h6.busslin-head {
          flex: 0 auto;
          font-size: 15px;
          font-weight: 500;
          color: $white-color;
        }
        .bsi-lnks {
          flex: 0 auto;
          width: 100%;
          @include upsure-xs {
            padding-top: 10px;
          }
          @include upsure-md {
            padding-top: 32px;
          }
          @include upsure-lg {
            padding-top: 11px;
          }
          a {
            flex: 0 auto;
            width: 100%;
            font-size: 13px;
            font-weight: normal;
            color: $white-color;
            text-align: left;
            width: 100%;
            display: block;
            margin-bottom: 4px;
          }
        }
      }
      .ft-item04 {
        flex: 0 auto;
        color: $white-color;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        @include upsure-xs {
          width: 50%;
        }
        @include upsure-md {
          width: 230px;
        }
        @include upsure-lg {
          width: 17%;
          margin-top: 28px;
        }
        h6.usecase-head {
          flex: 0 auto;
          font-size: 15px;
          font-weight: 500;
          color: $white-color;
        }
        .usecase-lnks {
          flex: 0 auto;
          width: 100%;
          @include upsure-xs {
            padding-top: 12px;
          }
          @include upsure-md {
            padding-top: 32px;
          }
          @include upsure-lg {
            padding-top: 11px;
          }
          a {
            flex: 0 auto;
            width: 100%;
            font-size: 13px;
            font-weight: normal;
            color: $white-color;
            text-align: left;
            width: 100%;
            display: block;
            margin-bottom: 4px;
          }
        }
      }
      .ft-item05 {
        flex: 0 auto;
        color: $white-color;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        @include upsure-xs {
          width: 50%;
        }
        @include upsure-md {
          width: 167px;
        }
        @include upsure-lg {
          width: 15%;
          margin-top: 28px;
        }
        h6.reso-head {
          flex: 0 auto;
          font-size: 15px;
          font-weight: 500;
          color: $white-color;
        }
        .reso-lnks {
          flex: 0 auto;
          @include upsure-xs {
            padding-top: 12px;
          }
          @include upsure-md {
            padding-top: 32px;
          }
          @include upsure-lg {
            padding-top: 11px;
          }
          a {
            flex: 0 auto;
            font-size: 13px;
            font-weight: normal;
            color: $white-color;
            text-align: left;
            width: 100%;
            display: block;
            margin-bottom: 4px;
          }
        }
      }
      .ft-item06 {
        flex: 0 auto;
        color: $white-color;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        @include upsure-xs {
          width: 50%;
        }
        @include upsure-md {
          width: 170px;
        }
        @include upsure-lg {
          width: 19%;
          margin-top: 28px;
        }
        h6.abt-head {
          flex: 0 auto;
          font-size: 15px;
          font-weight: 500;
          color: $white-color;
        }
        .abt-lnks {
          flex: 0 auto;
          @include upsure-xs {
            padding-top: 12px;
          }
          @include upsure-md {
            padding-top: 32px;
          }
          @include upsure-lg {
            padding-top: 11px;
          }
          a {
            flex: 0 auto;
            font-size: 13px;
            font-weight: normal;
            color: $white-color;
            text-align: left;
            width: 100%;
            display: block;
            margin-bottom: 4px;
          }
        }
      }
      .ft-item07 {
        flex: 0 auto;
        color: $white-color;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-flow: column;
        @include upsure-xs {
          width: 50%;
        }
        @include upsure-md {
          width: 147px;
        }
        @include upsure-lg {
          width: 20%;
          margin-top: 28px;
        }
        h6.soc-head {
          flex: 0 auto;
          font-size: 15px;
          font-weight: 500;
          color: $white-color;
        }
        .soc-lnks {
          flex: 0 auto;
          @include upsure-xs {
            padding-top: 12px;
          }
          @include upsure-md {
            padding-top: 32px;
          }
          @include upsure-lg {
            padding-top: 11px;
          }
          a.fb {
            flex: 0 auto;
            width: 100%;
            margin-bottom: 4px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: row wrap;
            span.fb-inc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/fb_icon.svg")
                no-repeat center center;
            }
            span.fb-lnk {
              flex: 0 auto;
              font-size: 13px;
              font-weight: normal;
              color: $white-color;
              text-align: left;
              display: block;
              padding-left: 20px;
            }
          }
          a.twitter {
            flex: 0 auto;
            width: 100%;
            margin-bottom: 6px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: row wrap;
            span.twt-inc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/twitter_icon.svg")
                no-repeat center center;
            }
            span.twt-lnk {
              flex: 0 auto;
              font-size: 14px;
              font-weight: normal;
              color: $white-color;
              text-align: left;
              display: block;
              padding-left: 20px;
            }
          }
          a.youtube {
            flex: 0 auto;
            width: 100%;
            margin-bottom: 6px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: row wrap;
            span.yut-inc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/youtub_icon.svg")
                no-repeat center center;
            }
            span.yut-lnk {
              flex: 0 auto;
              font-size: 14px;
              font-weight: normal;
              color: $white-color;
              text-align: left;
              display: block;
              padding-left: 20px;
            }
          }
          a.linkedin {
            flex: 0 auto;
            width: 100%;
            margin-bottom: 6px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: row wrap;
            span.lin-inc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/linkedin_icon.svg")
                no-repeat center center;
            }
            span.lin-lnk {
              flex: 0 auto;
              font-size: 14px;
              font-weight: normal;
              color: $white-color;
              text-align: left;
              display: block;
              padding-left: 20px;
            }
          }
          a.instagram {
            flex: 0 auto;
            width: 100%;
            margin-bottom: 6px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: row wrap;
            span.inst-inc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/instagram_icon.svg")
                no-repeat center center;
            }
            span.inst-lnk {
              flex: 0 auto;
              font-size: 14px;
              font-weight: normal;
              color: $white-color;
              text-align: left;
              display: block;
              padding-left: 20px;
            }
          }
        }
      }
      .ftlinks {
        flex: 0 auto;
        width: 100%;
        text-align: center;
        a {
          font-size: 14px;
          font-weight: 500;
          color: $white-color;
          text-align: center;
          text-decoration: none;
          padding-right: 40px;
          &:last-child {
            padding-right: 0px;
          }
        }
      }
      .ftlogo {
        flex: 0 auto;
        width: 100%;
        text-align: center;
        padding-top: 40px;
        img {
          width: 121px;
          text-align: center;
        }
      }
    }
    .cp-wr {
      display: block;
      width: 100%;
      border-top: #515151 solid 1px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-top: 0px;
      .container {
        .soc-cpy {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          @include upsure-xs {
            flex-flow: column;
          }
          @include upsure-md {
            flex-flow: row wrap;
          }
          @include upsure-lg {
            flex-flow: row wrap;
          }
          .socinc {
            flex: 0 auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row wrap;
            a.fbinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/fb_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
            a.twtinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/twitter_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
            a.youinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/youtub_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
            a.lnkinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/linkedin_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
            a.instinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/instagram_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
          }
          .cpy {
            flex: 0 auto;
            @include upsure-xs {
              text-align: center;
            }
            @include upsure-md {
              text-align: right;
            }
            @include upsure-lg {
              text-align: right;
            }
            p {
              width: 100%;
              font-size: 13px;
              font-weight: 400;
              color: $white-color;
              @include upsure-xs {
                text-align: center;
                padding-top: 10px;
              }
              @include upsure-md {
                text-align: right;
                padding: 0px;
                margin: 0px;
              }
              @include upsure-lg {
                text-align: right;
                padding: 0px;
                margin: 0px;
              }
            }
          }
        }
      }
    }
    .cnt-cp-wr {
      display: block;
      width: 100%;
      border-top: #515151 solid 0px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-top: 300px;
      .container {
        .soc-cpy {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-flow: row wrap;
          width: 100%;
          .socinc {
            flex: 0 auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row wrap;
            a.fbinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/fb_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
            a.twtinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/twitter_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
            a.youinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/youtub_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
            a.lnkinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/linkedin_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
            a.instinc {
              flex: 0 auto;
              width: 16px;
              height: 16px;
              background: url("https://cdn.upsure.io/newui2023/footer/instagram_icon.svg")
                no-repeat center center;
              margin-right: 12px;
            }
          }
          .cpy {
            flex: 0 auto;
            text-align: right;
            p {
              width: 100%;
              font-size: 13px;
              font-weight: 400;
              color: $white-color;
              text-align: right;
              padding: 0px;
              margin: 0px;
            }
          }
        }
      }
    }
    .web-footer {
      @include upsure-xs {
        display: none;
      }
      @include upsure-sm {
        display: none;
      }
      @include upsure-md {
        display: block;
      }
      @include upsure-lg {
        display: block;
      }
      @include upsure-xl {
        display: flex;
      }
    }
    .mb-footer {
      @include upsure-xs {
        display: block;
      }
      @include upsure-sm {
        display: block;
      }
      @include upsure-md {
        display: none;
      }
      @include upsure-lg {
        display: none;
      }
      @include upsure-xl {
        display: none;
      }

      .footer-flex {
        // width: 100%;
        @include flexbox();
        flex-flow: row wrap;
        align-items: flex-start;
        justify-content: center;
        margin: 0rem 1rem 1.5rem 1rem;
        @include upsure-xs {
          width: 100%;
          justify-content: flex-start;
        }
        @include upsure-sm {
          width: 100%;
        }

        .ft-item02 {
          width: 50%;
          .hm-lnks {
            padding-top: 0px;
          }
        }
        .ft-item03 {
          width: 50%;
          .busslin-head {
            padding-top: 0px;
            border-bottom: solid 1px $white-color;
          }

          .bsi-lnks {
            padding-top: 0px;
          }
        }
        .ft-item04 {
          .usecase-head {
            padding-top: 0px;
            border-bottom: solid 1px $white-color;
          }
          .usecase-lnks {
            padding-top: 0px;
          }
        }
        .ft-item05 {
          .reso-head {
            border-bottom: solid 1px $white-color;
          }
          .reso-lnks {
            padding-top: 0px;
          }
        }
        .ft-item06 {
          width: 50%;
          .abt-lnks {
            padding-top: 0px;
          }
          .abt-head {
            border-bottom: solid 1px $white-color;
          }
        }
        .ft-item07 {
          .soc-lnks {
            padding-top: 0px;
          }
          .soc-head {
            border-bottom: solid 1px $white-color;
          }
        }
      }
    }
  }
}

// .contact-footer-main {
//   background: #010b0d;
//   float: left;
//   width: 100%;
//   .container {
//     position: relative;
//     .ft-sec {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-flow: column;
//       width: 100%;
//       h3 {
//         flex: 0 auto;
//         width: 100%;
//         font-size: 36px;
//         font-weight: bold;
//         color: $white-color;
//         text-align: center;
//         padding-top: 120px;
//       }
//       p {
//         flex: 0 auto;
//         width: 585px;
//         font-size: 30px;
//         font-weight: 500;
//         color: $white-color;
//         text-align: center;
//         padding-top: 30px;
//         @media screen and (max-width: 767px) {
//           width: 100%;
//         }
//         @media only screen and (min-width: 768px) and (max-width: 1024px) {
//           width: 100%;
//         }
//       }
//       a.ft-btn {
//         flex: 0 auto;
//         background: $primary-color;
//         font-size: 14px;
//         font-weight: 600;
//         color: $white-color;
//         text-decoration: none;
//         padding: 19px 50px;
//         margin: 0px;
//         margin-top: 30px;
//         outline: none;
//         border: none;
//         border-radius: 6px;
//         cursor: pointer;
//       }
//     }
//     .flk-log-sec {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       flex-flow: row wrap;
//       margin-top: 100px;
//       .ftlinks {
//         flex: 0 auto;
//         width: 100%;
//         text-align: center;
//         a {
//           font-size: 15px;
//           font-weight: 500;
//           color: $white-color;
//           text-align: center;
//           text-decoration: none;
//           padding-right: 40px;
//           &:last-child {
//             padding-right: 0px;
//           }
//         }
//       }
//       .ftlogo {
//         flex: 0 auto;
//         width: 100%;
//         text-align: center;
//         padding-top: 40px;
//         img {
//           width: 121px;
//           text-align: center;
//         }
//       }
//       .social-media {
//         flex: 0 auto;
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         flex-flow: row;
//         margin: 20px 0px;
//         a.linkedin {
//           flex: 0 auto;
//           width: 25px;
//           height: 25px;
//           background: url(../../public/assets/images/footer/linkedin_icon.svg)
//             no-repeat center center;
//           cursor: pointer;
//           text-indent: -9999px;
//         }
//         a.twitter {
//           flex: 0 auto;
//           width: 25px;
//           height: 25px;
//           margin-left: 10px;
//           background: url(../../public/assets/images/footer/twitter_icon.svg)
//             no-repeat center 5px;
//           cursor: pointer;
//           text-indent: -9999px;
//         }
//         a.youtube {
//           flex: 0 auto;
//           width: 27px;
//           height: 27px;
//           margin-left: 10px;
//           background: url(../../public/assets/images/footer/youtube_icon.svg)
//             no-repeat center 6px;
//           cursor: pointer;
//           text-indent: -9999px;
//         }
//         a.fb {
//           flex: 0 auto;
//           width: 25px;
//           height: 25px;
//           margin-left: 10px;
//           background: url(../../public/assets/images/footer/fb_icon.svg)
//             no-repeat center 5px;
//           cursor: pointer;
//           text-indent: -9999px;
//         }
//       }
//     }
//     .cp-wr {
//       display: block;
//       width: 100%;
//       border-top: #515151 solid 1px;
//       padding-top: 30px;
//       padding-bottom: 30px;
//       margin-top: 30px;
//       p {
//         width: 100%;
//         font-size: 11px;
//         font-weight: 400;
//         color: #a1a1a1;
//         text-align: center;
//         padding: 0px;
//         margin: 0px;
//       }
//     }
//   }
// }
