@import "themes.scss";
@import 'mixins.scss';
.careerbanner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/career/careers_banner.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: left;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
            }
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: left;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
.career-section-main {
    float: left;
    width: 100%;
    margin-top: 545px;
    margin-bottom: 100px;
  }
.career-banner {
    background: #1B818D;
    display: block;
    width: 100%;
    min-height: 162px;
    text-align: center;
    .container {
        position: relative;
        .arrowbg {
            background: url("https://cdn.upsure.io/newui2023/products/prodcuts_banner_bg.svg");
            background-repeat: no-repeat;
            background-position: center 60px;
            background-size: 642px;
        }
        h1 {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 700;
            color: $white-color;
            text-align: center;
            padding: 95px 0px 60px;
            margin: 0px;
        }
    }
}
.careerbanner-imgbg {
    background: #1B818D;
    display: block;
    width: 100%;
    min-height: 86px;
    .careeer-container {
        position: relative;
        width: 885px;
        margin: 0px auto;
        .banner-img {
            position: absolute;
            left: 0px;
            right:0px;
            top: -40px;
            img.career-img {
                width: 885px;
                height: 417px;
                text-align: center;
            }
        }
    }
}
.career-body-sec {
    display: block;
    width: 100%;
    padding-bottom: 150px;
    .careerbdy-con {
        width: 780px;
        margin: 0px auto;
        p {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: $text-color;
            line-height: 24px;
            text-align: justify;
            padding: 0px;
            margin: 10px 0px;
        }
        p.bld-txt {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 500;
            color: $dark-color;
            padding: 0px;
            margin: 10px 0px;
        }
        ul {
            float: left;
            width: 100%;
            margin-left: 0px;
            padding-left: 18px;
            margin-bottom: 20px;
            li {
                float: left;
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                color: $text-color;
                margin-bottom: 10px;
            }
        }
    }
}