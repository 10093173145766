@import "themes.scss";
@import "mixins.scss";

.ourtraget {
    display: block;
    background: $white-color;
    width: 100%;
    min-height: 150px;
    @include upsure-xl{
        padding-top: 0rem !important; 
    }
    // @include upsure-lg{
    //     padding-top: 5rem;
    // }
    h1 {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: 600;
        color: $dark-color;
        text-align: center;
        padding: 145px 0px 0px;
        margin: 0px;
        @media screen and (max-width: 767px) {
            padding: 120px 0px 0px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            padding: 50px 0px 0px;
        }
    }
    p {
        display: block;
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        color: $text-color;
        text-align: center;
        padding: 10px 0px 0px;
        margin: 0px;
    }
}
.cnt-polygon-container {
    background-image: url("https://cdn.upsure.io/newui2023/contact/contact_bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 684px;
    width: 100%;
    @media screen and (max-width: 767px) {
        height: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        height: 100%;
    }
    .container {
        .contact-sec {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 50px;
            padding-top: 30px;
            @media screen and (max-width: 767px) {
                margin-top: 0px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                margin-top: 0px;
                flex-flow: row;
                padding-top: 10px;
                padding-bottom: 20px;
            }
            .contact-bx {
                flex: 0 auto;
                width: 40%;
                min-height: 440px;
                position: relative;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    min-height: 350px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                    min-height:220px;
                }
                .cnt-bx {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    width: 100%;
                    min-height: 440px;
                    background: $white-color;
                    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                    border-radius: 36px;
                    @media screen and (max-width: 767px) {
                        min-height: 350px;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        min-height:220px;
                    }
                    .add-det {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-flow: column;
                        h3 {
                            flex: 0 auto;
                            width: 100%;
                            font-size: 30px;
                            font-weight: 600;
                            color: $dark-color;
                            text-align: left;
                            padding: 105px 0px 0px 53px;
                            @media screen and (max-width: 767px) {
                                padding: 30px;
                            }
                            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                                padding: 30px;
                            }
                        }
                        p {
                            flex: 0 auto;
                            font-size: 15px;
                            font-weight: 400;
                            color: $dark-color;
                            text-align: left;
                            line-height: 190.5%;
                            padding: 50px 0px 0px 53px;
                            @media screen and (max-width: 767px) {
                                padding: 30px;
                                padding-top: 0px;
                            }
                            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                                padding: 30px;
                                padding-top: 0px;
                            }
                        }
                    }
                }
            }
            .contact-address {
                flex: 0 auto;
                width: 40%;
                margin-left: 50px;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    padding-top: 30px;
                    margin-left: 20px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                    padding-top: 30px;
                    margin-left: 20px;
                }
                h2 {
                    font-size: 30px;
                    font-weight: 600;
                    color: $white-color;
                    padding: 0px 0px 30px 0px;
                    margin: 0px 0px 0px 0px;
                }
                p.eamil-sec {
                    font-size: 15px;
                    font-weight: 400;
                    color: $white-color;
                    padding: 0px 0px 40px 0px;
                    margin: 0px 0px 0px 0px;
                    a {
                        color: $white-color;
                        text-decoration: none;
                    }
                }
                p {
                    font-size: 15px;
                    font-weight: 400;
                    color: $white-color;
                    padding: 0px 0px 0px 0px;
                    margin: 0px 0px 20px 0px;
                }
            }
        }
    }
  }