@import "themes.scss";
@import 'mixins.scss';
.whyupsure-banner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/whyupsure/why_upsure.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: left;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
            }
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: left;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
  .whyupsure-bdy {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 545px 0px 0px;
  }
.container {
    h2.upsure-title {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: 600;
        color: $dark-color;
        text-align: center;
        padding: 0px 0px 0px;
        margin: 0px;
        @media screen and (max-width: 767px) {
            font-size: 25px;
            padding: 50px 0px 0px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            padding: 50px 0px 0px;
        }
    }
    p.titledec {
        display: block;
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        color: #667477;
        text-align: center;
        padding: 30px 0px 0px;
        margin: 0px;
    }
    .wemake-bx {
        display: flex;
        justify-content: center;
        align-self: center;
        flex-flow: column;
        background: #EFF8F9;
        width: 1000px;
        border-radius: 16px;
        margin: 50px auto;
        @media screen and (max-width: 767px) {
            width: 95%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            width: 95%;
        }
        h3 {
            flex: 0 auto;
            font-size: 30px;
            font-weight: 600;
            color: #1B818D;
            text-align: center;
            padding: 30px 0px 0px;
            @media screen and (max-width: 767px) {
                padding: 30px 35px 20px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                padding: 30px 35px 20px;
            }
        }
        p {
            flex: 0 auto;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: center;
            padding: 0px 75px 37px;
            @media screen and (max-width: 767px) {
                padding: 20px 25px 25px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                padding: 20px 25px 25px;
            }
        }
    }
    .yupsu-lft-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin: 30px 0px 50px;
        @media screen and (max-width: 767px) {
            flex-flow: column;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            flex-flow: column;
        }
        .yupsu-lft {
            flex: 0 auto;
            width: 50%;
            @media screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
                text-align: center;
            }
            img {
                max-width: 100%;
    height: auto;
    padding: 10%;
            }
        }
        .yupsu-rgt {
            flex: 0 auto;
            width: 50%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
            h4 {
                font-size: 30px;
                font-weight: 600;
                color: #012932;
                text-align: left;
                padding: 0px;
                margin: 0px;
            }
            p {
                font-size: 15px;
                font-weight: 400;
                color: #667477;
                text-align: justify;
                padding: 10px 0px;
                margin: 0px;
            }
        }
    }
    .upsur-rgt-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin: 50px 0px 50px;
        @media screen and (max-width: 767px) {
            flex-flow: column;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            flex-flow: column;
        }
        .upsur-lft {
            flex: 0 auto;
            width: 50%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
            h4 {
                font-size: 30px;
                font-weight: 600;
                color: $dark-color;
                text-align: left;
                padding: 0px;
                margin: 0px;
            }
            p {
                font-size: 15px;
                font-weight: 400;
                color: $text-color;
                text-align: justify;
                padding: 10px 0px;
                margin: 0px;
            }
        }
        .upsur-rgt {
            flex: 0 auto;
            width: 50%;
            text-align: right;
            @media screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
                text-align: center;
            }
            img {
                max-width: 100%;
                height: auto;
                padding: 10%;
            }
        }
    }
    .impact-your-business {
        display: block;
        float: left;
        width: 100%;
        margin: 0px;
        padding: 50px 0px 0px 0px;
        h2.impact-title {
            display: block;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: $dark-color;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        p.impact-dec {
            display: block;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: $text-color;
            text-align: center;
            margin: 0px;
            padding: 10px 0px 0px;
        } 
    }
    .impact-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin: 0px;
        padding-bottom: 50px;
        padding-top: 80px;
        @media screen and (max-width: 767px) {
            flex-flow: column;
            margin: 50px 0px;
            padding-bottom: 20px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            flex-flow: column;
            margin: 50px 0px;
            padding-bottom: 20px;
        }
        .impact-item {
            flex: 0 auto;
            width: 26%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 30px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
            img {
                flex: 0 auto;
                width: 72px;
                text-align: center;
                margin: 0px;
                padding: 0px;
            }
            h5 {
                flex: 0 auto;
                width: 100%;
                font-size: 15px;
                font-weight: 600;
                color: $dark-color;
                text-align: center;
                margin: 0px;
                padding: 60px 0px 0px;
                @media screen and (max-width: 767px) {
                    padding: 20px 0px 0px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    padding: 20px 0px 0px;
                }
            }
            p {
                flex: 0 auto;
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                color: $text-color;
                text-align: center;
                margin: 0px;
                padding: 10px 0px;
            }
        }
    }
}

.tab-view {
    .container {
        .yupsu-lft-tab-flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row wrap;
        width: 100%;
        margin: 30px 0px 50px;
        .yupsu-lft-tab {
            flex: 0 auto;
            width: 20%;
            text-align: left;
            img {
                width: 110px;
            }
        }
        .yupsu-rgt-tab {
            flex: 0 auto;
            width: 80%;
            h4 {
                font-size: 30px;
                font-weight: 500;
                color: $dark-color;
                text-align: left;
                padding: 0px;
                margin: 0px;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                color: $text-color;
                text-align: justify;
                padding: 10px 0px 0px 0px;
                margin: 0px;
            }
        }
        }
    }
}

.mobile-view {
    .container {
        .yupsu-mob-flex {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: column;
        width: 100%;
        margin: 30px 0px 50px;
        .yupsu-lft-mob {
            flex: 0 auto;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;
            img {
                width: 110px;
            }
        }
        .yupsu-rgt-mob {
            flex: 0 auto;
            width: 100%;
            h4 {
                font-size: 30px;
                font-weight: 500;
                color: $dark-color;
                text-align: center;
                padding: 0px;
                margin: 0px;
            }
            p {
                font-size: 14px;
                font-weight: 400;
                color: $text-color;
                text-align: justify;
                padding: 10px 0px 0px 0px;
                margin: 0px;
            }
        }
        }

    }
}