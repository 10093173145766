@import "themes.scss";
@import "mixins.scss";

.reports-banner-bg {
    background-image: url('https://cdn.upsure.io/newui2023/reports/reports_banner.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: left;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
            }
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: left;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
  .reports-banner-dwn {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 482px 0px 0px 0px;

.reports-bx-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 1320px;
    margin: 100px auto;
    @media screen and (max-width: 767px) {
        flex-flow: column;
        width: 100%;
        margin: 20px auto 30px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        flex-flow: row wrap;
        width: 100%;
        margin: 20px auto 30px;
    }
    .reports-item-bx {
        width: 45%;
        min-height:625px;
        position: relative;
        border-radius: 10px;
    
        margin-bottom: 0px;
        @media screen and (max-width: 768px) {
            width: 100%;
            min-height: 100%;
        }
        @media only screen and (min-width: 769px) and (max-width: 1024px) {
            width: 48%;
        }
        .arrowbg {
            background-image: url("https://cdn.upsure.io/newui2023/team_arrow_bg.svg");
            background-repeat: no-repeat;
            background-position: left bottom;
            background-size: contain;
            width: 251px;
            height: 146px;
            position: absolute;
            left: 0px;
            bottom: 0px;
        }
        .reports-dec-flex {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: column;
            margin-top: 20px;
            @include upsure-xs{
                height: 690px;
            }
            .reports-dec-title {
                flex: 0 auto;
                width: 100%;
                font-size: 30px;
                font-weight: 600;
                color: $white-color;
                text-align: center;
                height: 60px;
                padding: 18px 40px 20px 48px;
                margin: 0px;
                @include upsure-md {
                    text-align: center;
                    padding: 30px 15px 10px;
                    height: auto;
                    line-height: inherit;
                }
                @include upsure-xs{
                    font-size: 20px !important;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    height: auto;
                    line-height: normal;
                }
                @media only screen and (min-width: 1025px) and (max-width: 1920px) {
                    font-size: 23px;
                }
            }
            .reports-mem-bio {
                flex: 0 auto;
                font-size: 15px;
                font-weight: 400;
                color: $white-color;
                text-align: justify;
                padding: 10px 48px 20px 48px;
                margin: 0px;
                @media screen and (max-width: 767px) {
                    padding: 20px 30px 20px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    padding: 20px 30px 20px;
                }
            }
            .btn-sec {
                flex: 0 auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row wrap;
                padding: 18px 60px;
                font-size: 16px;
                font-weight: 700;
                color: $white-color;
                text-decoration: none;
                border-radius: 6px;
                outline: none;
                border: none;
                cursor: pointer;
                z-index: 100;
                margin: 25px 25% 20px;
                position: absolute;
                bottom: 20px;
                @include upsure-md{
                    margin: 10px 0px 10px 40px;
                }
                @include upsure-xs{
                    margin-left: 15%;
                    padding: 20px 30px 20px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    margin: 25px auto;
                }
                span.dwn-icon {
                    flex: 0 auto;
                    width: 20px;
                    height: 26px;
                    margin-right: 15px;
                    img {
                        width: 25px;
                        height: 28px;
                    }
                }
                span.dwn-txt {
                    flex: 0 auto;
                    font-size: 15px;
                    font-weight: 500;
                    color: $white-color;
                    text-decoration: none;
                    line-height: 24px;
                    letter-spacing: -1.1%;
                }
            }
        }
    }
    .darkbg {
        background: $dark-color;box-shadow: 0px 4px 15px #629CA3;
        @include upsure-xs{
            height: 700px;
    margin-bottom: 20px;
        }
.reports-dec-title{
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 20px;
    }
}

    }
    .lightbg {
        background: #2CA1AF;box-shadow: 0px 4px 15px #629CA3;
    }
    .btn-darkbg {
        background: $dark-color;
    }
    .btn-lightbg {
        background: #2CA1AF;
    }
}
  }