@import "themes.scss";
@import "mixins.scss";

.ourpartners-banner-bg {
    background-image: url('https://cdn.upsure.io/newui2023/ourpartners/our_partners.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: left;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
            }
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: left;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
.ourpartners-banner-dwn {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 482px 0px 0px 0px;

.container {
    h2.ourprt-title {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: 600;
        color: $dark-color;
        text-align: center;
        padding: 100px 0px 0px 0px;
        margin: 0px;
        @media screen and (max-width: 767px) {
            padding: 30px 0px 0px 0px;
        }
    }
    p.ourprt-titledec {
        display: block;
        width: 960px;
        font-size: 15px;
        font-weight: 400;
        color: $text-color;
        text-align: center;
        padding: 10px 0px 0px 0px;
        margin: 0px auto;
        @media screen and (max-width: 767px) {
            width: 90%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            width: 90%;
        }
    }
}
  }


.partners-bx-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 960px;
    margin: 100px auto;
    @media screen and (max-width: 767px) {
        flex-flow: column;
        width: 100%;
        margin: 20px auto 30px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        flex-flow: column;
        width: 100%;
        margin: 20px auto 30px;
    }
    .ptr-item-bx {
        width: 450px;
        // min-height: 450px;
        min-height: 380px;
        position: relative;
        border-radius: 10px;
        margin-bottom: 50px;
        @media screen and (max-width: 767px) {
            width: 100%;
            min-height: 100%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            width: 100%;
        }
        .arrowbg {
            background-image: url("../../../public/assets/images/ourpartners/ptrbx_arrow_bg.svg");
            background-repeat: no-repeat;
            background-position: left bottom;
            background-size: contain;
            width: 251px;
            height: 146px;
            position: absolute;
            left: 0px;
            bottom: -11px;
        }
        .team-dec-flex {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
        }
        .ourptr-dec-flex {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: column;
            margin-top: 20px;
            min-height: 415px;
            @include upsure-xs {
                align-items: center;
            }
        
            @include upsure-sm {
                align-items: center;
            }
            .ptr-logo-img {
                flex: 0 auto;
                height: 100px;
                padding: 30px 48px 0px 48px;
                @media screen and (max-width: 767px) {
                    padding: 30px 0px 0px 10px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    padding: 30px 48px 0px 48px;
                }
            }
            .pwwdo-title {
                flex: 0 auto;
                font-size: 30px;
                font-weight: 600;
                color: $white-color;
                text-align: left;
                height: 60px;
                line-height: 60px;
                padding: 18px 40px 0px 48px;
                margin: 0px;
                @media screen and (max-width: 767px) {
                    text-align: center;
                    padding: 30px 15px 10px;
                    height: auto;
                    line-height: inherit;
                    font-size: 23px;
                }
                @media only screen and (min-width: 1025px) and (max-width: 1920px) {
font-size: 21px;
                }
            }
            .team-mem-bio {
                flex: 0 auto;
                font-size: 15px;
                font-weight: 400;
                color: $white-color;
                text-align: justify;
                line-height: 24px;
                padding: 20px 48px 40px 48px;
                margin: 0px;
                @media screen and (max-width: 767px) {
                    padding: 20px 30px 40px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    padding: 20px 30px 40px;
                }
            }
        }
        .team-mem-pic {
            position: absolute;
            bottom: 0px;
            right: 0px;
            img {
                width: 267px;
            }
            img.kiran-img {
                width: 258px;
            }
            img.hemanth-img {
                width: 202px;
            }
            img.jaline-img {
                width: 179px;
            }
        }
    }
    .darkbg {
        background: $dark-color;box-shadow: 0px 4px 15px #629CA3;
        cursor: pointer;
    }
    .lightbg {
        background: #2CA1AF;box-shadow: 0px 4px 15px #629CA3;
    }
}