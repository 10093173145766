$breakpoints: (
	"upsure-xs": 0,
	"upsure-sm": 480px,
	"upsure-md": 720px,
	"upsure-lg": 960px,
	"upsure-xl": 1200px,
);

@mixin upsure-xs {
	@media (min-width: map-get($breakpoints, "upsure-xs")) {
		@content;
	}
}

@mixin upsure-sm {
	@media (min-width: map-get($breakpoints, "upsure-sm")) {
		@content;
	}
}

@mixin upsure-md {
	@media (min-width: map-get($breakpoints, "upsure-md")) {
		@content;
	}
}

@mixin upsure-lg {
	@media (min-width: map-get($breakpoints, "upsure-lg")) {
		@content;
	}
}

@mixin upsure-xl {
	@media (min-width: map-get($breakpoints, "upsure-xl")) {
		@content;
	}
}

@mixin breakpoint($bp: 0) {
	@media (min-width: $bp) {
		@content;
	}
}