$primary-color : #1B818D;
// $text-color :#667477;
$text-color : #404040;
$white-color : #ffffff;
$dark-color : #012932;
$link-color :#238E99;
$darksnapphire: #002C77;
$white-clr            :#ffffff;

@mixin flexbox() {
    display: -webkit-box ;display: -moz-box ;display: -ms-flexbox ;
    display: -webkit-flex ;display:flex ;
  }