@import '../css/themes.scss';
@import '../css/homelearnmore.scss';
@import '../css/mixins.scss';

.pw-gen-sec, .leadingcompaines-sec {
    background: #EFF8F9;
    display: block;
    width: 100%;
    min-height: 100px;
    padding: 100px 0px;
    h2 {
        display: block;
        width: 100%;
        font-size: 40px;
        font-weight: 700;
        color: $dark-color;
        text-align: center;
        margin: 0px;
        padding: 0px;
    }
    p {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: 500;
        color: #667477;
        text-align: center;
        margin: 0px;
        padding: 20px 0px 0px 0px;
    }
    .container {
        .bx-sec {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 40px;
            @media screen and (max-width: 768px) {
                flex-flow: column;
            }
            .bx-sec-item {
                flex: 0 auto;
                width: 30%;
                background: $white-color;
                position: relative;
                min-height: 206px;
                padding: 30px 20px 30px 40px;
                margin-top: 60px;
                border-radius: 6px;
                @media screen and (max-width: 768px) {
                    width: 100%;
                }
                .blu-bx {
                    position: absolute;
                    top: -40px;
                    left: 40px;
                    background: #46BFCA;
                    width: 80px;
                    height: 80px;
                    border-radius: 10px;
                }
                h3 {
                    font-size: 26px;
                    font-weight: 700;
                    color: $dark-color;
                    padding: 30px 0px 0px;
                    margin: 0px;
                }
                p {
                    font-size: 30px;
                    font-weight: 500;
                    color: $text-color;
                    padding: 10px 0px 0px;
                    margin: 0px;
                    text-align: left;
                }
            }
        }
    }
}
.japan-platform {
    background: #EFF8F9;
    display: block;
    width: 100%;
    min-height: 100px;
    padding: 100px 0px;
    @include upsure-xs {
        padding: 50px 0px;
    }
    .container {
    .japan-platform-js{
        max-width: 994px;
        min-width: 994px;
        margin: 0px auto;
        @media only screen and (min-width: 320px) and (max-width: 1024px) {
            max-width: 100%;
            min-width: 100%;  
        }
        // h3.pageheading-js {
        //     float: left;
        //     width: 100%;
        //     font-family: 'Graphik' !important;
        //     font-size: 30px;
        //     font-weight: 600;
        //     color: $dark-color;
        //     text-align: center;
        //     padding: 0px;
        //     margin-top: 100px;
        // }
        .pageheading-js {
            float: left;
            width: 100%;
            font-family: 'Graphik' !important;
            font-size: 30px;
            font-weight: 600;
            color: $dark-color;
            text-align: center;
            line-height: initial;
            @include upsure-xs {
                margin-top: 50px;
                padding: 0px;
            }
            @include upsure-md {
                margin-top: 100px;
                padding: 0px;
            }
            @include upsure-lg {
                margin-top: 100px;
                padding: 0px 140px;
            }
        }
        p.subtext{
            // display: block;
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: center;
            margin: 0px;
            padding: 10px 0px 0px 0px;
        }
        .box-flex{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: row wrap;
            width: 100%;
            padding-top: 50px;
            @include upsure-xs {
                justify-content: center;
                align-items: center;
                padding-top: 0px;
            }
            @include upsure-md {
                justify-content: space-between;
                align-items: flex-start;
                padding-top: 50px;
            }
            @include upsure-lg {
                justify-content: space-between;
                align-items: flex-start;
                padding-top: 50px;
            }
            .box-item{
                flex: 0 auto;
                width: 300px;
                background: $white-color;               
                min-height: 200px;
                padding: 20px;
                margin-top: 40px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                -webkit-box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.1);
                box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.1);
                @include upsure-xs {
                    width:100%;
                }
                @include upsure-md {
                    width: 47%;
                }
                @include upsure-lg {
                    width: 300px;
                }
                .items{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-flow: column;
                    p {
                        font-size: 16px;
                        text-align: center;
                        padding: 20px 0px 0px 0px;
                        width: 100%;
                        
                    }
                    .omni-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/japan/1.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .agtser-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/japan/3.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .accel-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/japan/5.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .singleapi-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/japan/8.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .realtime-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/japan/2.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .automa-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/japan/4.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                }
                &:hover {
                    background: #46BFCA;
                    p {
                        color:#fff;
                    }
                    .omni-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/unified/multichannel_icon_wht.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .agtser-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/unified/trusted_icon_wht.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .accel-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/unified/highperformance_icon_wht.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .singleapi-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/single_api_icon_white.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .realtime-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/unified/deploy_icon_wht.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                    .automa-inc {
                        background-image: url('https://cdn.upsure.io/newui2023/homepage/unified/exphorzvet_icon_wht.svg');
                        background-repeat: no-repeat;
                        background-position: center center;
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }
    }
}
}

.weare-sec {
    background: $white-color;
    display: block;
    width: 100%;
    min-height: 100px;
    padding: 100px 0px;
    h2.weare-title {
        display: block;
        width: 100%;
        font-size: 40px;
        font-weight: 700;
        color: $dark-color;
        text-align: center;
        margin: 0px;
        padding: 0px;
    }
    p.slogtxt {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: 500;
        color: $text-color;
        text-align: center;
        margin: 0px;
        padding: 20px 0px 0px 0px;
    }
    .container {
        .weare-sec-one {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 100px;
            @media screen and (max-width: 768px) {
                flex-flow: column;
            }
            .weare-item {
                flex: 0 auto;
                width: 45%;
                @media screen and (max-width: 768px) {
                    width: 100%;
                    text-align: center;
                }
                h4.whoweare-title {
                    display: block;
                    width: 100%;
                    font-size: 40px;
                    font-weight: 700;
                    color: $dark-color;
                    text-align: left;
                    margin: 0px 0px 0px 0px;
                    padding: 0px;
                }
                p.whoweare-txt {
                    display: block;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-color;
                    text-align: left;
                    margin: 30px 0px 0px 0px;
                    padding: 0px;
                }
                .leanmore-btn {
                    display: inline-block;
                    background: $dark-color;
                    font-size: 16px;
                    font-weight: 600;
                    color: $white-color;
                    line-height: 24px;
                    letter-spacing: -1.1%;
                    text-align: center;
                    text-decoration: none;
                    border: none;
                    outline: none;
                    border-radius: 6px;
                    padding: 19px 50px;
                    margin: 30px 0px 0px 0px;
                }
            }
        }
    }
}
.leadingcompaines-sec {
    .container {
        .leadingcompaines-img-sec {
            display: block;
            width: 100%;
            margin: 80px 0px 0px;
            text-align: center;
            img {
                text-align: center;
            }
        }
    }
}
.whatpeplesay-sec {
    background: $white-color;
    display: block;
    width: 100%;
    min-height: 100px;
    padding: 100px 0px;
    h2.wtpplsay-title {
        display: block;
        width: 100%;
        font-size: 40px;
        font-weight: 700;
        color: $dark-color;
        text-align: center;
        margin: 0px 0px 50px 0px;
        padding: 0px;
    }
.testimonial-carousel
{
    height: 400px;
        .item {
            width: 556px;
height: 350px;

padding: 1rem;
        opacity: 0.4;
        transition: .4s ease all;
        margin: 0 20px;
        transform: scale(0.7);
                .quetations{
                    position: absolute;
                    top: 30px;
                    right: 20px;                   
                    width: 60px;
            }
            .dotted{
                position: absolute;
                left: 0px;
                bottom: 10px;
            }
            .testimonial-bg{
                background-color: #EFF8F9;
                border-radius: 20px;
                @media only screen and (min-width: 320px) and (max-width: 768px) {
                   // height: 300px;
                 }
                .testimonial-content{
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 20px;
                    margin-top: 40px;
                    .test-img-bg{
                        
                        width: 25%;
                        height: 200px;
                        border-radius: 20px;
                        margin-right: 10px;
                        @media only screen and (min-width: 320px) and (max-width: 767px) {
                            display: none;
                        }
                        img{
                            height: 150px;
                            width: 130px;
                        }
                    }
                    .carousel-txt{
                        width: 70%;
                        font-size: 11px;
                        @media only screen and (min-width: 320px) and (max-width: 768px) {
                            width: 100%;
                        }
                        .employee-details{
                            margin-top: 20px;
                            .emp-name{
                                font-size: 12px;
                                font-weight: 600;
                            }
                            .emp-designation{
                                font-size: 11px;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }

        
    }
.active .item, .slick-center {
        opacity: 1;
        transform: scale(1.2);
        width: 500px;

        @media only screen and (min-width: 769px) and (max-width: 1024px) {
width: 350px !important;
        }



        @media only screen and (min-width: 320px) and (max-width: 768px) {
width: auto;
        }

        @media only screen and (min-width: 1100px) and (max-width: 1900px) {
width: 500px !important;
        }



      }
}
//   .owl .item {
    //     background: #4DC7A0;
    //   }
      
    //   .slick .item {
    //     background: #3498db;
    //   }
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
      
      
      
//       .owl-controls {
//         position: absolute;
//         margin-top: 300px;
//       }

//     .owl{
//         .testimonial-carousel{

//       .owl-item{
//         position: relative;
//         .quetations{
//             position: absolute;
//             top: 0px;
//             right: 20px;
//             }
//         .item {
//             width: 556px;
// height: 350px;


//         .testimonial-content{
//             display: flex;
//             width: 100%;
//             margin-top: 20px;
//             align-items: start;
//             justify-content: center;
//             .test-img-bg{
//                 background-color: #238E99;
//                 width: 40%;
//                 height: 200px;
//                 border-radius: 20px;
//                 margin-right: 10px;
//             }
//             .carousel-txt{
//                 width: 50%;
//                 font-size: 14px;
//                 .employee-details{
//                     .emp-name{
//                         font-size: 30px;
//                         font-weight: 600;
//                     }
//                     .emp-designation{
//                         font-size: 14px;
//                         font-weight: normal;
//                     }
//                 }
//             }
//         }
//     }
//       }
        
//     }
//     }

  


    // .item {
    //     height: 10rem;
    //     padding: 1rem;
    //     opacity: 0.4;
    //     transition: .4s ease all;
    //     margin: 0 10px;
    //     transform: scale(1.2);
    //   }
      
    //   .owl .item {
    //     background: #EFF8F9;
    //     width: 530px;
    //     height: 300px;
    //     border-radius: 20px;
    //   }
      
    //   .slick .item {
    //     background: #3498db;
    //   }
      
    //   .active .item, .slick-center {
    //     opacity: 1;
    //     transform: scale(1);
    //   }
      
    //   .owl-controls {
    //     position: absolute;
    //     margin-top: 300px;
    //   }




}
.learnmore-upsue-sec {
    background: $white-color;
    display: block;
    width: 100%;
    min-height: 100px;
    padding: 100px 0px;
    h2.lnmore-title {
        display: block;
        width: 100%;
        font-size: 40px;
        font-weight: 700;
        color: $dark-color;
        text-align: center;
        margin: 0px;
        padding: 0px;
    }
    .container {
        .lnmore-blg-sec {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: row wrap;
            width: 100%;
            margin: 80px 0px 0px 0px;
            .lnmmore-item-lft {
                flex: 0 auto;
                width: 483px;
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 90%;
                    margin: 0px auto;
                }
                .lnmorelft-sec {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-flow: column;
                    background: $white-color;
                    box-shadow: 0px 4px 15px #CBDCDE;
                    border-radius: 26px;
                    h5.lnmore-abt-title {
                        font-size: 13px;
                        font-weight: 700;
                        color: $dark-color;
                        letter-spacing: 29%;
                        padding: 30px 0px 0px 32px;
                        margin: 0px;
                    }
                    h6.lmore-hwcan {
                        font-size: 22px;
                        font-weight: 600;
                        color: $dark-color;
                        letter-spacing: 0%;
                        padding: 30px 30px 0px 32px;
                        margin: 0px;
                    }
                    p.lmore-dec {
                        font-size: 16px;
                        font-weight: 500;
                        color: $text-color;
                        letter-spacing: 0%;
                        padding: 30px 30px 0px 32px;
                        margin: 0px;
                    }
                    a.lnmore-watchvideo {
                        flex: 0 auto;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        width: 100%;
                        padding-top: 100px;
                        padding-bottom: 30px;
                        padding-left: 32px;
                        cursor: pointer;
                        .watchvideolink {
                            flex: 0 auto;
                            font-size: 16px;
                            font-weight: 400;
                            color: $link-color;
                        }
                        .watch-linkarrow {
                            flex: 0 auto;
                            width: 15px;
                            height: 12px;
                            margin-left: 10px;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .lnmmore-item-rgt {
                flex: 0 auto;
                width: 773px;
                @media only screen and (min-width: 769px) and (max-width: 1024px) {
                    width: 90%;
                    margin: 10px auto;
                }
                @media screen and (max-width: 768px) {
                    width: 90%;
                    margin: 15px auto;
                }
                .lnmoreitemrgt-one {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-flow: row wrap;
                    background: $white-color;
                    box-shadow: 0px 4px 15px #CBDCDE;
                    border-radius: 26px;
                    margin-bottom: 30px;
                    @media screen and (max-width: 768px) {
                        justify-content: center;
                    }
                    .inmoreitem-img01 {
                        flex: 0 auto;
                        width: 301px;
                        height: 374px;
                    }
                    .inmoreitem-txt01 {
                        flex: 0 auto;    
                        width: 472px;   
                        padding: 43px 50px 0px 40px;
                        h5.inmorergtitm-blog {
                            font-size: 13px;
                            font-weight: 700;
                            color: $dark-color;
                            letter-spacing: 29%;
                            padding: 0px;
                            margin: 0px;
                        }
                        h6.inmorergtitm-blogtitle {
                            font-size: 22px;
                            font-weight: 600;
                            color: $dark-color;
                            letter-spacing: 29%;
                            padding: 20px 0px 0px;
                            margin: 0px;
                        }
                        p.inmorergtitm-blogdec {
                            font-size: 16px;
                            font-weight: 500;
                            color: $dark-color;
                            letter-spacing: 29%;
                            padding: 35px 0px 0px;
                            margin: 0px;
                        } 
                        a.blogdec-readmore {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 27px 0px 0px;
                            margin: 0px;
                            .read-article {
                                flex: 0 auto;
                                font-size: 16px;
                                font-weight: 400;
                                color: $link-color;
                            }
                            .linkarrow {
                                flex: 0 auto;
                                width: 15px;
                                height: 12px;
                                margin-left: 10px;
                                img {
                                    display: block;
                                    width: 100%;
                                }
                            }                           
                        }                       
                    }
                }
                .lnmoreitemrgt-two {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-flow: row wrap;
                    background: $white-color;
                    box-shadow: 0px 4px 15px #CBDCDE;
                    border-radius: 26px;
                    margin-bottom: 30px;
                    .inmoreitem-img {
                        flex: 0 auto;
                        width: 307px;
                        height: 374px;
                        img {
                            width: 100%;
                        }
                    }
                    .inmoreitem-txt {
                        flex: 0 auto;    
                        width: 466px;   
                        padding: 40px 35px 0px 40px;
                        h5.inmorergtitm-blog {
                            font-size: 13px;
                            font-weight: 700;
                            color: $dark-color;
                            letter-spacing: 29%;
                            padding: 0px;
                            margin: 0px;
                        }
                        h6.inmorergtitm-blogtitle {
                            font-size: 22px;
                            font-weight: 600;
                            color: $dark-color;
                            letter-spacing: 29%;
                            padding: 10px 0px 0px;
                            margin: 0px;
                        }
                        p.inmorergtitm-blogdec {
                            font-size: 16px;
                            font-weight: 500;
                            color: $dark-color;
                            letter-spacing: 29%;
                            padding: 25px 0px 0px;
                            margin: 0px;
                        } 
                        a.blogdec-readmore {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 25px 0px 0px;
                            margin: 0px;
                            .read-article {
                                flex: 0 auto;
                                font-size: 16px;
                                font-weight: 400;
                                color: $link-color;
                            }
                            .linkarrow {
                                flex: 0 auto;
                                width: 15px;
                                height: 12px;
                                margin-left: 10px;
                                img {
                                    display: block;
                                    width: 100%;
                                }
                            }                           
                        }                       
                    }
                }
            }






        }
    }
}
.japan-hm-reg-sec {
    float: left;
    width: 100%;
    min-height: 665px;
    background-image: url('https://cdn.upsure.io/newui2023/homepage/hm_regards_bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    .container {
        .hmresult-sec {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: row wrap;
            @include upsure-xs {
                flex-flow: column;
                margin-top: 50px;
                margin-bottom: 50px;
            }
            @include upsure-md {
                flex-flow: row wrap;
                margin-top: 125px;
            }
            @include upsure-lg {
                flex-flow: row wrap;
                margin-top: 0px;
                height: 665px;
                align-items: center;
                justify-content: space-between;
            }
            .hmresult-item {
                flex: 0 auto;
                margin-bottom: 100px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-flow: column;
                @include upsure-xs {
                    width: 100%;
                    margin-bottom: 50px;
                }
                @include upsure-xs {
                    flex-flow: column;
                    margin-top: 50px;
                }
                @include upsure-md {
                    width: 26%;
                }
                @include upsure-lg {
                    width: 26%;
                }
                .hmresult-item-num {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 55px;
                    font-weight: 700;
                    color: #fff;
                    line-height: 60px;
                    text-align: center;
                }
                .hmresult-item-dec {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #fff;
                    text-align: center;
                    line-height: 26px;
                    @include upsure-xs {
                        padding-top: 10px;
                    }
                    @include upsure-md {
                        padding-top: 40px;
                    }
                    @include upsure-lg {
                        padding-top: 40px;
                    }
                }
            }
        }
    }
}


.japanese-teams-bdy {
    float: left;
    width: 100%;
    padding: 0px;
    @include upsure-xs {
        margin: 0px 0px 0px;
    }
    @include upsure-sm {
        margin: 0px 0px 0px;
    }
    @include upsure-md {
        margin: 0px 0px 0px;
    }
    @include upsure-lg {
        margin: 0px 0px 0px;
    }
    @include upsure-xl {
        margin: 0px 0px 0px;
    }
    .team-flex {
        display: grid;
        grid-gap: 1rem;
        width: 100%;
        margin: 150px 0px 150px !important;
        @include upsure-xs {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            width: 100%;
            margin: \0px 0px 50px !important;
        }
        @include upsure-sm {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            width: 100%;
            margin: 100px 0px 100px;
        }
        @include upsure-md {
            grid-template-columns: repeat(2, 1fr);
            margin: 100px auto 100px auto !important;
            width: 100%;
        }
        @include upsure-lg {
            grid-template-columns: repeat(2, 1fr);
            margin: 100px auto 100px auto !important;
            width: 70%;
        }
        
        @include upsure-xl {
            grid-template-columns: repeat(2, 1fr);
            margin: 100px auto 100px auto !important;
            width: 70%;
        }
        .item-bx {
            // flex: 0 auto;
            // width: 30%;
            width: 420px;
            // min-height: 440px;
            position: relative;
            border-radius: 10px;
            @include upsure-xs {
                width: 100%;
            }
            .team-dec-flex {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                min-height: 440px;
                @include upsure-xs {
                    min-height: auto;
                }
                h2.team-title {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: $dark-color;
                    text-align: center;
                }
                p {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: $text-color;
                    line-height: 25px;
                    padding: 20px 20px 0px;
                }
            }
            .arrowbg {
                background-image: url("../../../public/assets/images/team_arrow_bg.svg");
                background-repeat: no-repeat;
                background-position: -55px 250px;
                background-size: contain;
                width: 100%;
                height: 100%;
                .tema-dec-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-flow: column;
                    .team-mem-nm {
                        flex: 0 auto;
                        font-size: 20px;
                        font-weight: 600;
                        color: $white-color;
                        padding: 35px 0px 0px 28px;
                        margin: 0px;
                    }
                    .team-mem-bio {
                        flex: 0 auto;
                        font-size: 15px;
                        font-weight: 400;
                        color: $white-color;
                        line-height: 24px;
                        padding: 23px 35px 0px 28px;
                        margin: 0px;
                    }
                }
            }
            .team-mem-pic {
                position: absolute;
                bottom: 0px;
                right: 0px;
                img {
                    width: 267px;
                }
                img.kiran-img {
                    width: 258px;
                }
                img.hemanth-img {
                    width: 202px;
                }
                img.jaline-img {
                    width: 179px;
                }
            }
            a.team-mem-linkedin {
                position: absolute;
                bottom: 35px;
                left: 35px;
                background-image: url("../../../public/assets/images/team/team_mem_linkedin_icon.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 50px;
                height: 50px;
                cursor: pointer;
            }
        }
        .minheight-first {
            min-height: 440px;
            @include upsure-xs {
                min-height: 360px;
            }
        }
        .minheight {
            @include upsure-xs {
                min-height: 475px;
            }
            @include upsure-sm {
                min-height: 495px;
            }
            @include upsure-md {
                min-height: 492px;
            }
            @include upsure-lg {
                min-height: 460px;
            }
            @include upsure-xl {
                min-height: 450px;
            }
        }
        .darkbg {
            background: $dark-color;
        }
        .lightbg {
            background: #2CA1AF;
        }
    }
  }
.unified-main {
    float: left;
    width: 100%;
    @include upsure-xs {
        padding: 50px 0px 0px;
    }
    @include upsure-md {
        padding: 100px 0px 0px;
    }
    @include upsure-lg {
        padding: 100px 0px 0px;
    }
    .container {
        h3.unified-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 500;
            color: #000;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        p.unif-dec {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 700;
            color: #667477;
            text-align: center;
            margin-top: 50px;
            padding: 0px 20% 10px;
        }
        .mkinsu {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            margin: 0px auto;
            background: #0096A4;
            border-radius: 16px;
            @include upsure-xs {
                width: 100%;
                padding: 30px;
            }
            @include upsure-md {
                width: 100%;
                padding: 30px;
            }
            @include upsure-lg {
                width: auto;
                padding: 40px 73px;
            }
            h4.mkinsu-title {
                flex: 0 auto;
                font-weight: 500;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;
                color: #fff;
                @include upsure-xs {
                    width: 100%;
                    font-size: 19px;
                    text-align: center;
                }
                @include upsure-md {
                    font-size: 16px;
                    padding-bottom: 20px;
                }
                @include upsure-lg {
                    font-size: 16px;
                    padding-bottom: 20px;
                }
            }
            p.mkinsu-dec {
                flex: 0 auto;
                font-weight: 400;
                color: #fff;
                text-align: center;
                @include upsure-xs {
                    width: 100%;
                    text-align: center;
                }
                @include upsure-md {
                    font-size: 15px;
                    line-height: 22px;
                }
                @include upsure-lg {
                    font-size: 15px;
                    line-height: 22px;
                }
            }
        }
        .japan-unified {
            display: flex;
            justify-content: center;
            align-items: center;
            // flex-wrap: wrap;
            flex-flow: row wrap;
            margin: 0 auto;
            width: 100%;
            @include upsure-xs {
                padding: 30px 0px 0px;
            }
            @include upsure-md {
                padding: 100px 0px 0px;
            }
            @include upsure-lg {
                padding: 100px 0px 0px;
            }
          }
          h3.unified-title {
              float: left;
              width: 100%;
              font-size: 30px;
              font-weight: 500;
              color: #000;
              text-align: center;
              margin: 0px 0px 50px;
              padding: 0px;
          }
          .card {
            color: inherit;
            // cursor: pointer;
            cursor: inherit;
            width: calc(25% - 2rem);
            min-width: calc(25% - 2rem);
            height: 250px;
            min-height: 250px;
            perspective: 1000px;
            margin: 1rem;
            position: relative;
            border: none;
          }
          @media screen and (max-width: 800px) {
            .card {
              width: calc(50% - 2rem);
            }
          }
          @media screen and (max-width: 500px) {
            .card {
              width: 100%;
            }
          }
          .front, .back {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-flow: column;
            text-align:center;
            position: absolute;
            height: 100%;
            width: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            transition: ease-in-out 600ms;
            padding: 2rem;            
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
          }
          .front div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            width: 100%;
            min-height: 100%;
            img {
                margin-bottom: 20px;
            }
            p {
                flex: 0 auto;
                width: 100%;
                font-size: 16px;
                font-weight: 400;
                color: #000;
                text-align: center;
                line-height: 26px;
                padding: 0px;
                margin: 0px;
            }            
          }
          .back div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            width: 100%;
            min-height: 100%;
            img {
                margin-bottom: 20px;
            }
            p {
                flex: 0 auto;
                width: 100%;
                font-size: 19px;
                font-weight: 400;
                color: #fff;
                text-align: center;
                line-height: 26px;
                padding: 0px;
                margin: 0px;
            }            
          }

          
          .front {
          /*   background-size: cover; */
            overflow: hidden;
             box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
          }
          .card:hover .front {
            transform: rotateY(180deg);
          }
          .card:nth-child(even):hover .front {
            transform: rotateY(-180deg);
          }          
          .back {
            background: #46BFCA;
            transform: rotateY(-180deg);
          }
          .card:hover .back {
            transform: rotateY(0deg);
          }
          .card:nth-child(even) .back {
            transform: rotateY(180deg);
          }
          .card:nth-child(even):hover .back {
            transform: rotateY(0deg);
          }
          
          .button {
            transform: translateZ(40px);
            cursor: pointer;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            font-weight: bold;
            color: #fff;
            padding: 0.5em 1em;
            border-radius: 100px;
            font: inherit;
            border: none;
            position: relative;
            transform-style: preserve-3d;
            transition: 300ms ease;
          }
          .button:before {
            transition: 300ms ease;
            position: absolute;
            display: block;
            content: "";
            transform: translateZ(-40px);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            height: calc(100% - 20px);
            width: calc(100% - 20px);
            border-radius: 100px;
            left: 10px;
            top: 16px;
          }
          .button:hover {
            transform: translateZ(55px);
          }
          .button:hover:before {
            transform: translateZ(-55px);
          }
          .button:active {
            transform: translateZ(20px);
          }
          .button:active:before {
            transform: translateZ(-20px);
            top: 12px;
          }
    }
}

.trusted-global-partners {
    float: left;
    width: 100%;
    @include upsure-xs {
        padding: 50px 0px;
    }
    @include upsure-md {
        padding: 100px 0px;
    }
    @include upsure-lg {
        padding: 100px 0px;
    }
    .container {
        h3.trusted-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 500;
            color: #000;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        p.trusted-dec {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            line-height: 26px;
            text-align: center;
            margin-top: 50px;
        }
    }
    .hm-global-partners {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        padding-top: 50px;
        @include upsure-xs {
            flex-flow: column;
        }
        @include upsure-md {
            flex-flow: row wrap;
        }
        @include upsure-lg {
            flex-flow: row wrap;
        }
        .glbpart-item {
            flex: 0 auto;
            background: #fff;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            min-height: 171px;
            margin: 1rem;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            cursor: pointer;
            @include upsure-xs {
                width: 90%;
                margin-bottom: 30px;
            }
            @include upsure-md {
                width: 37%;
            }
            @include upsure-lg {
                width: 200px;
            }
            img.pwc {
                width: 87px;
                height: 65px;
            }
            img.hcltech {
                width: 226px;
                height: 61px;
            }
            img.hcltech {
                width: 171px;
                height: 46px;
            }
            img.idiom {
                width: 116px;
                height: 46px;
            }
            img.ibm {
                width: 116px;
                height: 46px;
            }
        }
    }
}



