@import "themes.scss";
@import 'mixins.scss';

.ItcJapan-banner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/team/whoweare_banner_01.svg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    @media screen and (max-width: 767px) {
        background-position: center bottom;
        background-size: contain;
        background-color: black;
        min-height: 250px;
    }
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: left;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
                
            }
          
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: left;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
  .ItcJapan-bdy {
    float: left;
    width: 100%;
    padding: 0px;
    @media screen and (max-width: 767px) {
        margin: 150px 0px 0px;
    }
    @include upsure-sm {
        margin: 440px 0px 0px;
    }
    @include upsure-md {
        margin: 460px 0px 0px;
    }
    @include upsure-lg {
        margin: 460px 0px 0px;
    }
    @include upsure-xl {
        margin: 464px 0px 0px;
    }
    .ItcJapan-content {
        float: left;
        width: 100%;
        margin: 100px 0px 100px !important;
        .itc-title{
            font-size: 30px;
        }
    }
  }