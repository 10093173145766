@import 'themes.scss';
@import 'mixins';

.prodcutsbanner {
    // display: block;
    // width: 100%;
    // min-height: 482px;
    // background: $primary-color;
    background-image: url('https://cdn.upsure.io/newui2023/products/product_banner_img_01.svg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    @include upsure-xs {
        // min-height:auto;
        padding-bottom: 2rem;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        min-height: auto;
        background-size: cover;
    }
    .container {
        position: relative;
        // .probg {
        //     background-image: url("https://cdn.upsure.io/newui2023/products/prodcuts_banner_bg.svg");
        //     background-repeat: no-repeat;
        //     background-position: center 10px;
        //     background-size: cover;
        //     width: 100%;
        //     min-height: 482px;
        //     @media screen and (max-width: 767px) {
        //         min-height: 100%;
        //     }
        //     @media only screen and (min-width: 768px) and (max-width: 1024px) {
        //         min-height: 100%;
        //     }
        //     h1.pro-head {
        //         display: block;
        //         width: 100%;
        //         font-size: 46px;
        //         font-weight: 700;
        //         color: $white-color;
        //         text-align: center;
        //         padding-top: 70px;
        //         span {
        //             display: block;
        //             width: 100%;
        //             font-size: 27px;
        //             font-weight: 500;
        //             color: $white-color;
        //             text-align: center;
        //             padding-top: 0px;
        //         }
        //     }
        //     p.pro-decs {
        //         display: block;
        //         width: 58%;
        //         margin: 0px auto;
        //         font-size: 30px;
        //         font-weight: 500;
        //         color: $white-color;
        //         text-align: center;
        //         padding: 0px 100px 30px;
        //         @media screen and (max-width: 767px) {
        //             width: 100%;
        //             padding: 20px;
        //         }
        //         @media only screen and (min-width: 768px) and (max-width: 1024px) {
        //             width: 100%;
        //             padding: 20px;
        //         }
        //     }
        //     .pro-btns {
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         flex-flow: row;
        //         width: 100%;
        //         @media screen and (max-width: 767px) {
        //             flex-flow: column;
        //         }
        //         @media only screen and (min-width: 768px) and (max-width: 1024px) {
        //             flex-flow: row wrap;
        //         }
        //         .startnow-btn {
        //             flex: 0 auto;
        //             background: #46BFCA;
        //             font-size: 16px;
        //             font-weight: 400;
        //             color: $white-color;
        //             line-height: 24px;
        //             letter-spacing: -1.1%;
        //             text-decoration: none;
        //             border-radius: 6px;
        //             outline: none;
        //             border: none;
        //             padding: 20px 50px;
        //             margin: 10px 20px;
        //         }
        //         .learnmore-btn {
        //             flex: 0 auto;
        //             background: $white-color;
        //             font-size: 16px;
        //             font-weight: 400;
        //             color: #1B818D;
        //             line-height: 24px;
        //             letter-spacing: -1.1%;
        //             text-decoration: none;
        //             border-radius: 6px;
        //             outline: none;
        //             border: none;
        //             padding: 20px 50px;
        //             margin: 10px 20px;
        //         }
        //     }
        // }

        h1.pro-head {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: center;
            padding-top: 150px;
            @include upsure-xs{
                padding-top: 10rem;
            }
            span {
                display: block;
                width: 100%;
                font-size: 27px;
                font-weight: 500;
                color: $white-color;
                text-align: center;
                padding-top: 0px;
            }
            @include upsure-xs{
font-size: 2.2rem;
            }
            @include upsure-sm{
                font-size: 2.2rem;  
            }
            @include upsure-lg {
                font-size: 60px;
                font-weight: 900;
            }
        }
        p.pro-decs {
            display: block;
            width: 100%;
            margin: 0px auto;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: center;
            padding: 0px 0px 60px;
            @media screen and (max-width: 767px) {
                width: 100%;
                padding: 20px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
                padding: 20px;
            }
        }
        .pro-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: row;
            width: 100%;
            @media screen and (max-width: 767px) {
                flex-flow: column;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                flex-flow: row wrap;
            }
            .startnow-btn {
                flex: 0 auto;
                background: #46BFCA;
                font-size: 16px;
                font-weight: 400;
                color: $white-color;
                line-height: 24px;
                letter-spacing: -1.1%;
                text-decoration: none;
                border-radius: 6px;
                outline: none;
                border: none;
                padding: 14px 40px;
                margin: 10px 20px;
            }
            .learnmore-btn {
                flex: 0 auto;
                background: $white-color;
                font-size: 16px;
                font-weight: 400;
                color: #1B818D;
                line-height: 24px;
                letter-spacing: -1.1%;
                text-decoration: none;
                border-radius: 6px;
                outline: none;
                border: none;
                padding: 20px 50px;
                margin: 10px 20px;
            }
        }
    }
}

.prod-icons-sec {
    display: block;
    background: $white-color;
    width: 100%;
    min-height: 170px;    
    @media screen and (max-width: 767px) {
        min-height: 100%;    
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        min-height: 100%;    
    }

.testdiv{
    position: fixed;
    top: 75px;
    background-color: #fff;
    width: 100%;
    height: 80px;
    scroll-behavior: smooth;
    border-bottom: solid 1px #ddd;
    z-index: 999;
    ul{
        @include flexbox();
        align-items: center;
        justify-content: center;
        height: 80px;
        li{
            padding: 0px 20px;
            a{
                cursor: pointer;
                color: #667477;
                &:hover{
                    color: #000;
                }
            }
        }
    }
}

    .container {
        position: relative;
        .prod-pos {
            position: absolute;
            left: 0px;
            right: 0px;
            // top: -50px;
            top: 440px;
            height: 100px;
            @include upsure-lg{
                // top: 280px;
            }
            @media screen and (max-width: 767px) {
                top: 20px;
                height: auto;
                position: inherit;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                top: -50px;
                height: auto;
            }
            .proinc-flex {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: row wrap;
                .icon-color-change{
                    color: #46BFCA !important;
                }
                .bancaActive{
                    .proditm{
                        .express-banca-icon{
                            //background: url("../../assets/images/products/express_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    }  
                }
                .addupActive{
                    .proditm{
                        .add-up-icon{
                            //background: url("../../assets/images/products/addup_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    }  
                }
                .benefitActive{
                    .proditm{
                        .benefits-box-icon{
                            //background: url("../../assets/images/products/benefits_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    } 
                }
                .productActive{
                    .proditm{
                        .product-power-icon{
                            //background: url("../../assets/images/products/product_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    } 
                }

                .expertActive{
                    .proditm{
                        .expert-edge-icon{
                            //background: url("../../assets/images/products/express_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    } 
                }

                .masterActive{
                    .proditm{
                        .master-mind-icon{
                            //background: url("../../assets/images/products/master_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    } 
                }

                .policyActive{
                    .proditm{
                        .policy-panel-icon{
                            //background: url("../../assets/images/products/policy_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    } 
                }


                .claimActive{
                    .proditm{
                        .claim-check-icon{
                            //background: url("../../assets/images/products/claim_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    } 
                }

                .safeActive{
                    .proditm{
                        .sme-safeguard-icon{
                            //background: url("../../assets/images/products/safeguard_wt.svg") no-repeat center center !important;
                            width: 100% !important;
                            height: 100% !important; 
                            //background-color: #46BFCA !important;
                            border-radius: 10px;
                            background-size: 50% !important;
                        }
                    }  
                    .prodnm{
                        color: $primary-color !important;
                    } 
                }



                .prodinc-items {
                    flex: 0 auto;
                    // margin-bottom: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: column;
                    margin: 0px 15px;
                   padding: 0px 8px;
                   @include upsure-lg{
                    margin: 0px 3px;
                   }
                    .proditm {
                        flex: 0 auto;
                        width: 60px;
                        height: 60px;
                        background: $white-color;
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                        border-radius: 10px;
                        @include flexbox();
                        align-items: center;
                        justify-content: center;
                        .express-banca-icon {
                            background: url("../../assets/images/products/express_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                        .express-banca-icon-active {
                            background: url("https://cdn.upsure.io/newui2023/products/express_banca_wt_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                        .add-up-icon {
                            background: url("https://cdn.upsure.io/newui2023/products/add_up_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                       
                        .benefits-box-icon {
                            background: url("https://cdn.upsure.io/newui2023/products/benefits_box_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                        .product-power-icon {
                            background: url("https://cdn.upsure.io/newui2023/products/product_power_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                        .expert-edge-icon {
                            background: url("https://cdn.upsure.io/newui2023/products/expert_edge_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                        .master-mind-icon {
                            background: url("https://cdn.upsure.io/newui2023/products/master_mind_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                        .policy-panel-icon {
                            background: url("https://cdn.upsure.io/newui2023/products/policy_panel_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                        .claim-check-icon {
                            background: url("https://cdn.upsure.io/newui2023/products/claim_check_icon.png") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                        .sme-safeguard-icon {
                            background: url("https://cdn.upsure.io/newui2023/products/sme_safeguard_icon.svg") no-repeat center center;
                            width: 100%;
                            height: 100%;
                            background-size: 50%;
                        }
                    }
                    .prodnm {
                        flex: 0 auto;
                        width: 100%;
                        font-size: 13px;
                        font-weight: 500;
                        color: #667477;
                        text-align: center;
                        padding-top: 12px;
                    }
                    .active {
                        background: #46BFCA !important;
                    }
                    .active-nm {
                        font-weight: bold;
                        color: $primary-color !important;
                    }
                }
            }
        
        
       
       
       
        }
    }
}
.prod-sec-one {
    background: $white-color;
    // display: block;
    float: left;
    width: 100%;
    padding: 3rem 0rem;
    clear: both;
    @media screen and (max-width: 767px) {
        padding: 50px 0px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        padding: 100px 0px;
    }
    
}

.banca-cls{
    padding-top: 30rem;
    @include upsure-lg{
        padding-top: 28rem;
    }
}



.prod-sec-two {
    background: #EFF8F9;
    display: block;
    width: 100%;
    padding: 3rem 0rem;
    clear: both;
}




.prod-two-cust-cls{
    padding: 400px 0px 100px 0px !important;
}
.container {
    h2.probdy-title {
        display: block;
        width: 100%;
        font-size: 30px;
        font-weight: 600;
        color: $dark-color;
        text-align: center;
        margin: 0px 0px 0px;
        padding: 0px;
    }
    p.probdy-dec {
        display: block;
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        color: $text-color;
        text-align: justify;
        line-height: 26px;
        margin: 0px;
        padding: 50px 0px 0px;
    }
    .prosecbdy-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin-top: 100px;
        @media screen and (max-width: 767px) {
            margin-top: 30px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            margin-top: 100px;
        }
        .prosecbdy-lft {
            flex: 0 auto;
            width: 50%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 45%;
            }
        }
        .prosecbdy-rgt {
            flex: 0 auto;
            width: 45%;
        }
        .prosebdydec-flex {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: column;
            width: 100%;
            .prosec-list {
                flex: 0 auto;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: row wrap;
                span.proimg {
                    flex: 0 auto;
                    width: 3%;
                    margin-bottom: 10px;
                    @media screen and (max-width: 767px) {
                        width: 6%;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        width: 4%;
                    }
                    img {
                        
                    }
                }
                span.prolt {
                    flex: 0 auto;
                    width: 96%;
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-color;
                    line-height: 24px;
                    margin-bottom: 10px;
                    @media screen and (max-width: 767px) {
                        width: 94%;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        width: 96%;
                    }
                }
            }
            .prosec-de {
                flex: 0 auto;
                width: 100%;
                font-size: 15px;
                font-weight: 500;
                color: $text-color;
                line-height: 24px;
                margin: 0px;
                padding: 40px 0px 0px;
                @media screen and (max-width: 767px) {
                    padding: 10px 0px 0px;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    padding: 40px 0px 0px;
                }
            }
        
            .prodlinkrgt-sec {
                flex: 0 auto;
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row;
                text-decoration: none;
                .vdlink {
                    flex: 0 auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row;
                    text-decoration: none;
                    margin-right: 20px;
                    padding: 6px 15px;
                    border: solid 1px #46BFCA;
                    border-radius: 6px;
                    @include upsure-lg{
                        margin-right: 5px;
                        font-size: 10px;
                    }
                    @include upsure-xl{
                        margin-right: 5px;
                        font-size: 10px;
                    }
                    span.vdinc {
                        flex: 0 auto;
                        img{
                            width: 20px; 
                         }
                    }
                    span{
                       
                    }
                    span.vd-text {
                        flex: 0 auto;
                        margin-left: 10px;
                        font-size: 13px;
                        font-weight: 500;
                        color: #46BFCA;
                        text-align: left;
                        line-height: 210%;
                        text-decoration: none;
                    }
                }
                .dwn-blet {
                    flex: 0 auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-decoration: none;
                    border: solid 1px #46BFCA;
                    padding: 9px 15px;
                    border-radius: 6px;
                    span.dwninc {
                        flex: 0 auto;
                        img{
                            width: 18px; 
                         }
                    }
                    span.dwnlet-text {
                        flex: 0 auto;
                        margin-left: 10px;
                        font-size: 13px;
                        font-weight: 500;
                        color: #46BFCA;
                        text-align: left;
                        text-decoration: none;
                    }
                }
                .prod-bdy-btn {
                    flex: 0 auto;
                    background: #46BFCA;
                    font-size: 13px;
                    font-weight: 500;
                    color: $white-color;
                    text-decoration: none;
                    border-radius: 6px;
                    border: none;
                    outline: none;
                    padding: 11px 15px;
                    margin: 0px 20px 0px 0px;
                    @include upsure-lg{
                        margin-right: 5px;
                        font-size: 10px;
                    }
                    @include upsure-xl{
                        margin-right: 5px;
                        font-size: 13px;
                    }
                }



            }
        }
        .prodlink-sec {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row;
            width: 95%;
            margin-top: 50px;
            @include upsure-lg{
                width: 100%;
            }
            @include upsure-xl{
                width: 100%;
            }
            a.links-item-videolink {
                flex: 0 auto;
                margin-left: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row;
                cursor: pointer;
                text-decoration: none;
                margin-right: 0px;
                padding: 6px 15px;
                border: solid 1px #46BFCA;
                border-radius: 6px;
                @include upsure-lg{
                    margin-left: 5px;
                    font-size: 10px;
                }
                @include upsure-xl{
                    margin-left: 5px;
                    font-size: 10px;
                }
                span.videoicon {
                    flex: 0 auto;
                    img{
                       width: 20px; 
                    }
                }
                span.videoicon-link {
                    flex: 0 auto;
                    font-size: 13px;
                    font-weight: 500;
                    color: #46BFCA;
                    text-align: left;
                    //line-height: 210%;
                    text-decoration: none;
                    padding-left: 10px;
                    padding-top: 3px;
                }
            }
            a.links-item-dwn {
                flex: 0 auto;
                margin-left: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row;
                cursor: pointer;
                text-decoration: none;
                padding: 6px 15px;
                border: solid 1px #46BFCA;
                border-radius: 6px;
                @include upsure-xl{
                    margin-left: 5px;
                    
                }
                @include upsure-lg{
                    margin-left: 5px;
                    
                }
                span.dwnicon {
                    flex: 0 auto;
                    img{
                        width: 18px; 
                     }
                }
                span.dwn-link {
                    flex: 0 auto;
                    font-size: 13px;
                    font-weight: 500;
                    color: #46BFCA;
                    text-align: left;
                    text-decoration: none;
                    padding-left: 10px;
                    padding-top: 3px;
                }
            }

            .prod-bdy-btn {
                flex: 0 auto;
                background: #46BFCA;
                font-size: 13px;
                font-weight: 500;
                color: $white-color;
                line-height: 16px;
                letter-spacing: -1.1%;
                text-decoration: none;
                border-radius: 6px;
                border: none;
                outline: none;
                padding: 11px 15px;
                margin: 0px 0px 0px 0px;
                @include upsure-lg{
                    
                    font-size: 10px;
                }
                @include upsure-xl{
                    
                    font-size: 13px;
                }
            }


        }
        .prodlink-sec-benefit {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row;
            width: 100%;
            margin-top: 50px;
            a.links-item-videolink {
                flex: 0 auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row;
                cursor: pointer;
                text-decoration: none;
                margin-right: 20px;
                padding: 6px 15px;
                border: solid 1px #46BFCA;
                border-radius: 6px;
                @include upsure-lg{
                    margin-right: 5px;
                }
                @include upsure-xl{
                    margin-right: 5px;
                }
                span.videoicon {
                    flex: 0 auto;
                    img{
                        width: 20px; 
                     }
                }
                span.videoicon-link {
                    flex: 0 auto;
                    font-size: 13px;
                    font-weight: 500;
                    color: #46BFCA;
                    text-align: left;
                    text-decoration: none;
                    padding-left: 10px;
                    padding-top: 3px;
                }
            }
            a.links-item-dwn {
                flex: 0 auto;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row;
                cursor: pointer;
                text-decoration: none;
                padding: 6px 15px;
border: solid 1px #46BFCA;
border-radius: 6px;
                span.dwnicon {
                    flex: 0 auto;
                    img{
                        width: 18px; 
                     }
                }
                span.dwn-link {
                    flex: 0 auto;
                    font-size: 13px;
                    font-weight: 500;
                    color: #46BFCA;
                    text-align: left;
                    text-decoration: none;
                    padding-left: 10px;
                    padding-top: 3px;
                }
            }

            .prod-bdy-btn {
                flex: 0 auto;
                background: #46BFCA;
                font-size: 13px;
                font-weight: 500;
                color: $white-color;
                line-height: 16px;
                letter-spacing: -1.1%;
                text-decoration: none;
                border-radius: 6px;
                border: none;
                outline: none;
                padding: 11px 15px;
                margin: 0px 20px 0px 0px;
                @include upsure-lg{
                    margin-right: 5px;
                    font-size: 10px;
                }
                @include upsure-xl{
                    margin-right: 5px;
                    font-size: 13px;
                }
            }


        }
    }
}


.tab-view {
    .prodcutsbanner-tab {
        display: block;
        width: 100%;
        min-height:100%;
        background: $primary-color;
        .container {
            position: relative;
            .probg-tab {
                background-image: url(https://cdn.upsure.io/newui2023/products/prodcuts_banner_bg.svg);
                background-repeat: no-repeat;
                background-position: center 10px;
                background-size: cover;
                width: 100%;
                min-height: 100%;
                h1.pro-head-tab {
                    display: block;
                    width: 100%;
                    font-size: 46px;
                    font-weight: 700;
                    color: $white-color;
                    text-align: center;
                    padding-top: 40px;
                    span {
                        display: block;
                        width: 100%;
                        font-size: 27px;
                        font-weight: 700;
                        color: $white-color;
                        text-align: center;
                        padding-top: 0px;
                    }
                }
                p.pro-decs-tab {
                    display: block;
                    width: 58%;
                    margin: 0px auto;
                    font-size: 30px;
                    font-weight: 600;
                    color: $white-color;
                    text-align: center;
                    padding: 20px 100px;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        padding: 20px;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        width: 100%;
                        padding: 20px;
                    }
                }
                .pro-btns-tab {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: row wrap;
                    width: 100%;
                    .startnow-btn-tab {
                        flex: 0 auto;
                        background: #46BFCA;
                        font-size: 16px;
                        font-weight: 700;
                        color: $white-color;
                        line-height: 24px;
                        letter-spacing: -1.1%;
                        text-decoration: none;
                        border-radius: 6px;
                        outline: none;
                        border: none;
                        padding: 20px 50px;
                        margin: 10px 20px;
                    }
                    .learnmore-btn-tab {
                        flex: 0 auto;
                        background: $white-color;
                        font-size: 16px;
                        font-weight: 700;
                        color: #1B818D;
                        line-height: 24px;
                        letter-spacing: -1.1%;
                        text-decoration: none;
                        border-radius: 6px;
                        outline: none;
                        border: none;
                        padding: 20px 50px;
                        margin: 10px 20px;
                    }
                }
            }
        }
    }
    
    .prod-icons-sec-tab {
        display: block;
        background: $white-color;
        width: 100%;
        min-height: 100%; 
        .container {
            position: relative;
            .prod-pos-tab {
                // position: absolute;
                // left: 0px;
                // right: 0px;
                // top: -50px;
                // height: 100px;
                display: block;
                width: 100%;
                padding: 20px 0px;
                .proinc-flex-tab {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row wrap;
                    .prodinc-items-tab {
                        flex: 0 auto;
                        margin: 20px 15px 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-flow: column;
                        .proditm-tab {
                            flex: 0 auto;
                            width: 100px;
                            height: 100px;
                            background: $white-color;
                            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                            border-radius: 10px;
                            .express-banca-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/sme_safeguard_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .express-banca-icon-tab-active {
                                background: url("https://cdn.upsure.io/newui2023/products/express_banca_wt_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .add-up-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/add_up_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .benefits-box-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/benefits_box_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .product-power-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/product_power_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .expert-edge-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/expert_edge_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .master-mind-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/master_mind_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .policy-panel-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/policy_panel_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .claim-check-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/claim_check_icon.png") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .sme-safeguard-icon-tab {
                                background: url("https://cdn.upsure.io/newui2023/products/sme_safeguard_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                        }
                        .prodnm-tab {
                            flex: 0 auto;
                            width: 100%;
                            font-size: 15px;
                            font-weight: 500;
                            color: #667477;
                            text-align: center;
                            padding-top: 22px;
                        }
                        .active {
                            background: #46BFCA !important;
                        }
                        .active-nm {
                            font-weight: bold;
                            color: $primary-color !important;
                        }
                    }
                }
            }
        }
    }
    .prod-sec-one-tab {
        background: $white-color;
        display: block;
        width: 100%;
        padding: 20px 0px;
    }
    .prod-sec-two-tab {
        background: #EFF8F9;
        display: block;
        width: 100%;
        padding: 20px 0px 30px;
    }
    .container {
        h2.probdy-title-tab {
            display: block;
            width: 100%;
            font-size: 40px;
            font-weight: 700;
            color: $dark-color;
            text-align: center;
            margin: 0px;
            padding: 0px 0px 20px;

            @include upsure-lg{
                font-size: 30px;
            }
            @include upsure-md{
                font-size: 30px;
            }
        }
        p.probdy-dec-tab {
            display: block;
            width: 100%;
            font-size: 15px;
            font-weight: 500;
            color: $text-color;
            text-align: justify;
            line-height: 26px;
            margin: 0px;
            padding: 10px 0px 0px;
        }
        .prosecbdy-flex-tab {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 0px;
            .prosecbdy-lft-tab {
                flex: 0 auto;
                width: 100%;
            }
            .prosecbdy-rgt-tab {
                flex: 0 auto;
                width: 100%;
            }
            .prosebdydec-flex-tab {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                width: 100%;
                .prosec-list-tab {
                    flex: 0 auto;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row wrap;
                    margin: 20px 0px 10px;
                    span.proimg-tab {
                        flex: 0 auto;
                        width: 6%;
                        margin-bottom: 10px;
                        img {
                            display: flex;
                        }
                    }
                    span.prolt-tab {
                        flex: 0 auto;
                        width: 94%;
                        font-size: 15px;
                        font-weight: 500;
                        color: $text-color;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                }
                .prosec-de-tab {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-color;
                    line-height: 24px;
                    margin: 0px;
                    padding: 10px 0px 0px;
                }
               
            }
            .prodlink-sec-tab {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: row wrap;
                width: 100%;
                margin-top: 20px;
                a.links-item-videolink-tab {
                    flex: 0 auto;
                    margin-left: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row;
                    cursor: pointer;
                    text-decoration: none;
                    margin-right: 0px;
                    padding: 13px 30px;
                    border: solid 1px #46BFCA;
                    border-radius: 6px;
                    span.videoicon {
                        flex: 0 auto;
                        img{
                           width: 20px; 
                        }
                    }
                    span.videoicon-link {
                        flex: 0 auto;
                        font-size: 13px;
                        font-weight: 500;
                        color: #46BFCA;
                        text-align: left;
                        //line-height: 210%;
                        text-decoration: none;
                        padding-left: 10px;
                        padding-top: 3px;
                    }
                }
                a.dwn-blet-tab {
                    flex: 0 auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-decoration: none;
                    border: solid 1px #46BFCA;
                    padding: 11px 15px;
                    border-radius: 6px;
                    margin-left: 10px;
                    span.dwninc-tab {
                        flex: 0 auto;
                        img{
                            width: 18px; 
                         }
                    }
                    span.dwnlet-text-tab {
                        flex: 0 auto;
                        margin-left: 10px;
                        font-size: 13px;
                        font-weight: 500;
                        color: #46BFCA;
                        text-align: left;
                        text-decoration: none;
                    }
                }


                .prod-bdy-btn-tab {
                    flex: 0 auto;
                    width: auto;
                    background: #46BFCA;
                    font-size: 16px;
                    font-weight: 700;
                    color: $white-color;
                    line-height: 16px;
                    letter-spacing: -1.1%;
                    text-decoration: none;
                    border-radius: 6px;
                    border: none;
                    outline: none;
                    padding: 18px 50px;
                    margin: 0px 0px 0px 0px;
                }

               


                a.links-item-videolink {
                flex: 0 auto;
                margin-left: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-flow: row;
                cursor: pointer;
                text-decoration: none;
                margin-right: 0px;
                padding: 6px 15px;
                border: solid 1px #46BFCA;
                border-radius: 6px;
                span.videoicon {
                    flex: 0 auto;
                    img{
                       width: 20px; 
                    }
                }
                span.videoicon-link {
                    flex: 0 auto;
                    font-size: 13px;
                    font-weight: 500;
                    color: #46BFCA;
                    text-align: left;
                    //line-height: 210%;
                    text-decoration: none;
                    padding-left: 10px;
                    padding-top: 3px;
                }
            }



                a.links-item-videolink-tab {
                    flex: 0 auto;
                    margin-left: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row;
                    cursor: pointer;
                    text-decoration: none;
                    margin-right: 0px;
                    padding: 13px 30px;
                    border: solid 1px #46BFCA;
                    color: #46BFCA;
                    border-radius: 6px;
                    span.videoicon {
                        flex: 0 auto;
                        img{
                           width: 20px; 
                        }
                    }
                    span.videoicon-link {
                        flex: 0 auto;
                        font-size: 13px;
                        font-weight: 500;
                        color: #46BFCA;
                        text-align: left;
                        //line-height: 210%;
                        text-decoration: none;
                        padding-left: 10px;
                        padding-top: 3px;
                    }
                }
            }
        }
    }
}

.mobile-view {
    .prodcutsbanner-mob {
        display: block;
        width: 100%;
        min-height:100%;
        background: $primary-color;
        .container {
            position: relative;
            .probg-mob {
                background-image: url(https://cdn.upsure.io/newui2023/products/prodcuts_banner_bg.svg);
                background-repeat: no-repeat;
                background-position: center 10px;
                background-size: cover;
                width: 100%;
                min-height: 100%;
                h1.pro-head-mob {
                    display: block;
                    width: 100%;
                    font-size: 46px;
                    font-weight: 700;
                    color: $white-color;
                    text-align: center;
                    padding-top: 40px;
                    span {
                        display: block;
                        width: 100%;
                        font-size: 25px;
                        font-weight: 700;
                        color: $white-color;
                        text-align: center;
                        padding-top: 0px;
                    }
                }
                p.pro-decs-mob {
                    display: block;
                    width: 58%;
                    margin: 0px auto;
                    font-size: 30px;
                    font-weight: 600;
                    color: $white-color;
                    text-align: center;
                    padding: 20px 100px;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                        padding: 20px;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) {
                        width: 100%;
                        padding: 20px;
                    }
                }
                .pro-btns-mob {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: column;
                    width: 100%;
                    .startnow-btn-mob {
                        flex: 0 auto;
                        background: #46BFCA;
                        font-size: 16px;
                        font-weight: 700;
                        color: $white-color;
                        line-height: 24px;
                        letter-spacing: -1.1%;
                        text-decoration: none;
                        border-radius: 6px;
                        outline: none;
                        border: none;
                        padding: 20px 50px;
                        margin: 10px 20px;
                    }
                    .learnmore-btn-mob {
                        flex: 0 auto;
                        background: $white-color;
                        font-size: 16px;
                        font-weight: 700;
                        color: #1B818D;
                        line-height: 24px;
                        letter-spacing: -1.1%;
                        text-decoration: none;
                        border-radius: 6px;
                        outline: none;
                        border: none;
                        padding: 20px 50px;
                        margin: 10px 20px;
                    }
                }
            }
        }
    }
    
    .prod-icons-sec-mob {
        display: block;
        background: $white-color;
        width: 100%;
        min-height: 100%; 
        .container {
            position: relative;
            .prod-pos-mob {
                // position: absolute;
                // left: 0px;
                // right: 0px;
                // top: -50px;
                // height: 100px;
                display: block;
                width: 100%;
                padding: 20px 0px;
                .proinc-flex-mob {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    flex-flow: row wrap;
                    .prodinc-items-mob {
                        flex: 0 auto;
                        margin-bottom: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-flow: column;
                        .proditm-mob {
                            flex: 0 auto;
                            width: 100px;
                            height: 100px;
                            background: $white-color;
                            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                            border-radius: 10px;
                            .express-banca-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/sme_safeguard_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .express-banca-icon-mob-active {
                                background: url("https://cdn.upsure.io/newui2023/products/express_banca_wt_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .add-up-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/add_up_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .benefits-box-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/benefits_box_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .product-power-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/product_power_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .expert-edge-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/expert_edge_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .master-mind-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/master_mind_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .policy-panel-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/policy_panel_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .claim-check-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/claim_check_icon.png") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                            .sme-safeguard-icon-mob {
                                background: url("https://cdn.upsure.io/newui2023/products/sme_safeguard_icon.svg") no-repeat center center;
                                width: 100px;
                                height: 100px;
                            }
                        }
                        .prodnm-mob {
                            flex: 0 auto;
                            width: 100%;
                            font-size: 15px;
                            font-weight: 500;
                            color: #667477;
                            text-align: center;
                            padding-top: 22px;
                        }
                        .active {
                            background: #46BFCA !important;
                        }
                        .active-nm {
                            font-weight: bold;
                            color: $primary-color !important;
                        }
                    }
                }
            }
        }
    }
    .prod-sec-one-mob {
        background: $white-color;
        display: block;
        width: 100%;
        padding: 20px 0px;
    }
    .prod-sec-two-mob {
        background: #EFF8F9;
        display: block;
        width: 100%;
        padding: 20px 0px 30px;
    }
    .container {
        h2.probdy-title-mob {
            display: block;
            width: 100%;
            font-size: 40px;
            font-weight: 700;
            color: $dark-color;
            text-align: center;
            margin: 0px;
            padding: 0px 0px 20px;
            @include upsure-xs{
                font-size: 30px;
            }
        }
        p.probdy-dec-mob {
            display: block;
            width: 100%;
            font-size: 15px;
            font-weight: 500;
            color: $text-color;
            text-align: justify;
            line-height: 26px;
            margin: 0px;
            padding: 10px 0px 0px;
        }
        .prosecbdy-flex-mob {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 0px;
            .prosecbdy-lft-mob {
                flex: 0 auto;
                width: 100%;
            }
            .prosecbdy-rgt-mob {
                flex: 0 auto;
                width: 100%;
            }
            .prosebdydec-flex-mob {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                width: 100%;
                .prosec-list-mob {
                    flex: 0 auto;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row wrap;
                    margin: 20px 0px 10px;
                    span.proimg-mob {
                        flex: 0 auto;
                        width: 6%;
                        margin-bottom: 10px;
                        img {
                            display: flex;
                        }
                    }
                    span.prolt-mob {
                        flex: 0 auto;
                        width: 94%;
                        font-size: 15px;
                        font-weight: 500;
                        color: $text-color;
                        line-height: 24px;
                        margin-bottom: 10px;
                    }
                }
                .prosec-de-mob {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 500;
                    color: $text-color;
                    line-height: 24px;
                    margin: 0px;
                    padding: 10px 0px 0px;
                }
              
            }
            .prodlink-sec-mob {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-flow: row wrap;
                width: 100%;
                margin-top: 20px;
                a.links-item-videolink-mob {
                    flex: 0 auto;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row;
                    cursor: pointer;
                    text-decoration: none;
                    padding: 15px 15px;
                border: solid 1px #46BFCA;
                border-radius: 6px;
                    span.videoicon-mob {
                        flex: 0 auto;
                        width: 25px;
                    }
                    span.videoicon-link-mob {
                        flex: 0 auto;
                        font-size: 11px;
                        font-weight: 700;
                        color: #46BFCA;
                        text-align: left;
                        line-height: 210%;
                        text-decoration: none;
                        padding-left: 10px;
                    }
                }
                a.links-item-dwn-mob {
                    flex: 0 auto;
                    font-size: 16px;
                    font-weight: 500;
                    color: #46BFCA;
                    text-align: left;
                    text-decoration: none;
                    cursor: pointer;
                    margin-left: 0px;
                    padding: 15px 15px;
                border: solid 1px #46BFCA;
                border-radius: 6px;
                span.dwnicon {
                    flex: 0 auto;
                    img{
                        width: 18px; 
                     }
                }
                span.dwn-link {
                    flex: 0 auto;
                    font-size: 13px;
                    font-weight: 500;
                    color: #46BFCA;
                    text-align: left;
                    text-decoration: none;
                    padding-left: 10px;
                    padding-top: 3px;
                }
                }
                .prod-bdy-btn-mob {
                    flex: 0 auto;
                    width: 50%;
                    background: #46BFCA;
                    font-size: 13px;
                    font-weight: 700;
                    color: $white-color;
                    text-align: center;
                    line-height: 16px;
                    letter-spacing: -1.1%;
                    text-decoration: none;
                    border-radius: 6px;
                    border: none;
                    outline: none;
                    padding: 18px 0px;
                    margin: 0px 0px 10px 0px;
                }
            }
        }
    }
}

.testmenu{
    position: fixed;
    top: 105px;
    width: auto;
    margin: 0px auto;
    left: 0;
    right: 0;
    background-color: white;
    padding: 15px 0px 15px 0px;
    -webkit-box-shadow: -3px 5px 5px 1px rgba(0,0,0,0.1);
    -moz-box-shadow: -3px 5px 5px 1px rgba(0,0,0,0.1);
    box-shadow: -3px 5px 5px 1px rgba(0,0,0,0.1);
    z-index: 99;
    .proinc-flex {
            width: 90%;
            margin: 0px auto;
    
            a.prodinc-items {
                flex-flow: row !important;
                margin: 0px 5px !important;
                .proditm {
                    background-color: transparent !important;
                    box-shadow: 0px 0px 0px !important;
                    width: 40px !important;
                    height: 40px !important;
                }
                .prodnm{
                    padding-top: 0px !important;
                }
                
            }
        }
   
}
