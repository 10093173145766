.lnmore-box-section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 40px;
  cursor: pointer;
  .ln-box-item{
      display: flex;
      flex-flow: column;
      width: 413px; 
      margin-bottom: 32px;
      @media only screen and (min-width: 320px) and (max-width: 767px) {
          width: 100%;
      }
      .item-01{
          width: 413px; 
          height: 413px;
          @media only screen and (min-width: 320px) and (max-width: 767px) {
              width: 100%;
              height: auto;
          }
      }
      p.item-title{
          font-size: 22px;
          font-weight: 600;
          color: #000;
          margin-top: 32px;
      }
      p.item-date{
          font-size: 30px;
          font-weight: 600;
          color: #667477;
          margin-top: 10px;
      }
  }
}