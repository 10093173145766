// .hmtest-slide {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-flow: row wrap;
//     width: 100%;
//     margin-top: 100px;
//     border: #f00 solid 1px;
//     .slick-slider .slick-list {
//         flex: 0 auto;
//         background: #EFF8F9;
//         width: 756px;
//         height: 438px;
//         position: relative;
//         border-radius: 26px;
//         margin: 0px 20px;
//         // display: flex;
//         // border: #000 solid 1px;
//         .itemcard {
//             // flex: 0 auto;
//             h3 {
//                 font-size: 30px;
//             }
//         }
//     }
// }

// @import "../css/slick.min.scss";

// @import "../css/slick-theme.min.scss";

// .main {
//     width: 100%;
//     display:block;
//     margin:0 auto;
//   }
//   h3 {
//       background: #EFF8F9;
//       margin: 10px ;
//       min-height: 438px;
//       position: relative;
//       border: 50px solid #fff;
//       border-radius: 26px;
//   }



.tmo-container {
    position: relative;
    margin: 0 auto;
    width: 80%;
    margin-top:10px;
  

  h2{
    // font-family:Pacifico;
    font-size:36px;
    margin:20px auto;
    text-align:center;
  }
  img{
    border-radius:8px;
    background:#fff;
    color:#3498db;
    font-size:36px;
    line-height:100px;
    margin:10px;
    // padding:2%;
    //width: 100px;
    //height: 100px;
    position:relative;
    text-align:center;
    .top {
      font-size: 70%;
      height: 70%;
      
      margin: 0 auto;
   
    }
    .bottom {
      font-size: 70%;
      width: 70%;
      margin: 0 auto;
   
    }
  }
  .clip {
  
    // height: 200px;
    overflow: hidden;
    bottom: 0;
  }
  
  .center .slick-slide {
   
    // height: 500px;
  }
  
  .center .slick-center img{
    //-moz-transform:scale(1.08);
    //-ms-transform:scale(1.08);
    //-o-transform:scale(1.08);
    //-webkit-transform:scale(1.08);
    transform:scale(1.0);
    //width: 150px;
    //height: 120px;
    color:#e67e22;
    opacity:1;
    .top {
      font-size: 90%;
      width: 90%;
      background-color: rgba(blue, 0.3);
    }
    .bottom {
      font-size: 90%;
      width: 90%;
      background-color: rgba(green, 0.3);
    }
  }
  .center img{
    opacity:0.8;
    transition:all 300ms ease;
    bottom: 0;
    transform:scale(0.9);
  }
  
}