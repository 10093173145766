@import "themes.scss";
@import "mixins.scss";
@import "common.scss";
.blog-banner-bg {
  background-image: url("https://cdn.upsure.io/newui2023/blog/blog_banner.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  display: block;
  width: 100%;
  min-height: 564px;
  position: absolute;
  top: 0px;
  .container {
    position: relative;
    margin-top: 82px;
    h1.banner-heading {
      display: block;
      width: 100%;
      font-size: 60px;
      font-weight: 900;
      color: $white-color;
      text-align: left;
      padding: 145px 0px 0px;
      margin: 0px;
      @include upsure-xs {
        font-size: 2.2rem;
      }

      @include upsure-sm {
        font-size: 2.2rem;
      }
      @include upsure-md {
        font-size: 4rem;
      }
      @include upsure-lg {
        font-size: 60px;
      }
    }
    p.banner-dec {
      display: block;
      width: 100%;
      font-size: 24px;
      font-weight: 400;
      color: $white-color;
      text-align: left;
      padding: 12px 0px 0px;
      margin: 0px;
    }
  }
}

.article-view {
  background-image: url("https://cdn.upsure.io/newui2023/blog/article_view.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  display: block;
  width: 100%;
  min-height: 564px;
  position: absolute;
  top: 0px;
  @media screen and (max-width: 767px) {
    min-height: 320px;
  }
  .container {
    position: relative;
    margin-top: 82px;
    .article-banner-txt{
      max-width: 1024px;
      margin: 0px auto;
      h1.banner-heading {
        display: block;
        width: 100%;
        font-size: 40px;
        font-weight: 900;
        color: $white-color;
        text-align: left;
        padding: 150px 0px 0px;
        margin: 0px;
        @include upsure-xs {
          font-size: 2.2rem;
          padding: 50px 0px 0px;
        }
        @include upsure-md {
          font-size: 2.2rem;
        }
        @include upsure-lg {
          font-size: 35px;
          padding-top: 18rem;
          text-align: center;
        }
      }
      p.banner-dec {
        display: block;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        color: $white-color;
        text-align: left;
        padding: 12px 0px 0px;
        margin: 0px;
        text-align: center;
      }
    }
   
  }
}

.blog-banner-dwn {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 482px 0px 0px 0px;
}

.blog-box-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  @include upsure-xs {
    margin-top: 50px;
    justify-content: space-between;
  }
  @include upsure-lg {
    justify-content: flex-start;
    margin-top: 100px;
  }
  cursor: pointer;
  .blog-box-item {
    flex: 0 auto;
    width: 413px;
    display: flex;
    flex-flow: column;
    margin-bottom: 50px;
    margin-right: 20px;
    border: solid 1px #ddd;
    justify-content: space-between;
    align-items: center;
    @include custom-border-radius(16px);
    @include upsure-xs {
      width: 100%;
      margin-right: 0px;
      padding: 1rem;
    }
    @include upsure-sm {
      width: 100%;
    }
    @include upsure-lg {
      margin-right: 23px;
    }
    @include upsure-md {
      width: 410px;
      padding: 1rem 1rem 4rem 1rem;
    }
    .blog-item-img {
      width: 400px;
      height: 220px;
      position: relative;
      @include custom-border-radius(16px);
      @include upsure-xs {
        width: 100%;
      }
      @include upsure-sm {
        width: 100%;
        height: auto;
      }
      @include upsure-md {
        width: 380px;
      }
      .blogimg-title {
        position: absolute;
        bottom: 60px;
        left: 30px;
        font-size: 22px;
        font-weight: 500;
        color: #000;
        letter-spacing: 0%;
      }
      .blogimg-title2 {
        position: absolute;
        bottom: 35px;
        left: 30px;
        font-size: 22px;
        font-weight: 500;
        color: #000;
        letter-spacing: 0%;
      }
    }
    h6.item-date {
      flex: 0 auto;
      width: 100%;
      font-size: 11px;
      font-weight: 500;
      color: #707c7f;
      text-transform: uppercase;
      letter-spacing: 24.5%;
      margin: 40px 0px 0px 0px;
      padding: 0px;
      @include upsure-md {
        margin-top: 20px;
      }
    }
    h5.item-title {
      flex: 0 auto;
     // width: 100%;
      font-size: 30px;
      font-weight: 500;
      color: #0011CA;
      margin: 20px 0px 0px 0px;
      padding: 0px;
      text-overflow: ellipsis;
      overflow: hidden; 
      width: 100%; 
      height: 1.2em; 
      white-space: nowrap;
      @include upsure-md {
        font-size: 20px;
      }
      @include upsure-xs{
        font-size: 20px;
      }
    }
    p.item-contxt {
      flex: 0 auto;
      
      font-size: 15px;
      font-weight: 400;
      color: #667477;
      margin-top: 10px;
      display: -webkit-box;
      width: 100%;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.blog-btns {
  position: absolute;
  left: 0px;
  bottom: -242px;
  height: 50px;
  width: 100%;
  .blogtabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    button.blogbtn {
      flex: 0 auto;
      background: #156d77;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 57px;
      border: 0px;
      outline: 0px;
      min-width: 86px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      line-height: 24px;
      padding: 10px 25px;
      margin-right: 25px;
    }
    button.blogbtn-active {
      flex: 0 auto;
      padding: 10px 15px;
      background: #46bfca;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 57px;
      border: 0px;
      outline: 0px;
      min-width: 86px;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      line-height: 24px;
      margin-right: 25px;
    }
  }
}

/* INNER BLOG PAGE CSS STARTS HERE */

.blog-inn-main {
  float: left;
  width: 100%;
  padding-bottom: 100px;
  margin: 482px 0px 0px 0px;
  background-color: #E8E8E8;
  @include upsure-xs {
    margin-top: 200px;
    padding-bottom: 0px;
  }
  @include upsure-sm {
    margin-top: 450px;
    padding-bottom: 0px;
  }
  @include upsure-md {
    margin-top: 450px;
  }
  @include upsure-lg {
    margin-top: 450px;
  }
  @include upsure-xl {
    margin-top: 25rem;
  }
  .blog-inner-container {
    margin: 0px auto;
    padding-top: 30px;
    @media screen and (max-width: 767px) {
        min-width: 98%;
        max-width: 98%;
        margin: 0px;
        padding: 20px 0px 0px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        min-width: 98%;
        max-width: 98%;
        margin: 0px;
        padding: 50px 20px 0px;
      }
      .article-bg{
        background-color: $white-color;
        max-width: 1010px;
        height: auto;
        @include custom-border-radius(10px);
        margin: 0px auto 2rem auto;
        padding: 2.3rem 1rem 1rem 1rem;
        position: relative;
        .txtitalic{
          font-style: italic;
          font-size: 26px;
          color: $ji-red-color;
          text-align: center;
          margin-bottom: 0px;
          margin-top: 35px;
        }
        .whishes{
          font-size: 36px;
          text-align: center;
          font-weight: bold;
          color: #FF4400;
          margin-bottom: 50px;
          font-style: italic;
        }
        .signuptxt{
          text-align: left;
          font-size: 16px;
          color: #8f8f8f;
          padding: 1rem 0rem;
          line-height: 1.8rem;
        }
      }
      .shareSection{
        max-width: 1024px;
        margin: 0px auto;
        padding: 1rem 0rem;
        p.likedarticle{
          padding: 1rem 0rem;
          
         
          @include upsure-xs {
            @include flexbox();
            flex-flow: column;
            align-items: center;
            justify-content: center;
          }
          @include upsure-sm {
            @include flexbox();
            flex-flow: column;
            align-items: center;
            justify-content: center;
          }
          @include upsure-md {
            display: inline-block;
          }
          @include upsure-lg {
            display: inline-block;
          }
          @include upsure-xl {
            display: inline-block;
          }
         
          span{
            font-size: 16px;
            color: $text-color;
            font-weight: 500;
            padding-left: 1rem;
          }
          .socialicons{
            a{
              //overflow: hidden;
              height: 36px;
              display: inline-block;
              img{
                padding: 0rem 0.3rem;
              }
            }
            
           
          }
          b{
            padding-left: 1rem;
          }
        }
      
        
      }
    .article-banner {
      height: 400px;
      // float: left;
      position: relative;
      @include upsure-xs {
        display: none;
      }
      @include upsure-md {
        display: none;
      }
      @include upsure-md {
        display: none;
      }
      @include upsure-lg {
        display: block;
      }
      @include upsure-xl {
        display: block;
      }
      .article-img {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        margin-top: 0px;
        img {
          max-width: 940px;
          height: auto;
          //border: solid 2px $primary-color;
          -webkit-border-radius: 10px;
          border-radius: 10px;
          -moz-border-radius: 10px;
          -khtml-border-radius: 10px;
          min-width: 940px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
    p.blogdate {
      display: block;
      width: 97%;
      font-size: 18px;
      font-weight: 500;
      color: #707c7f;
      text-align: left;
      line-height: 1.2;
      padding: 0px;
      margin: 40px auto 20px auto;
      @media screen and (max-width: 767px) {
        text-align: justify;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        text-align: justify;
      }
    }
    p {
      display: block;
      width: 97%;
      font-size: 16px;
      font-weight: 400;
      color: $text-color;
      text-align: justify;
      line-height: 1.5;
      padding: 0px;
      margin: 0px auto 20px;
      @media screen and (max-width: 767px) {
        text-align: justify;
        margin: 0px 0px 20px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        text-align: justify;
      }
    }
    h2, h3 {
      display: block;
      width: 100%;
      font-size: 30px;
      font-weight: 600;
      color: $dark-color;
      letter-spacing: 0%;
      padding: 0px;
      margin: 0px 0px 20px;
      @media screen and (max-width: 767px) {
        line-height: 40px;
        margin: 20px 0px;
      }
      span {
        float: left;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        color: #707c7f;
        line-height: 1.2;
      }
    }
    h4 {
      display: block;
      width: 97%;
      font-size: 22px;
      font-weight: 600;
      color: $dark-color;
      letter-spacing: 0%;
      padding: 0px;
      margin: 20px auto 20px;
      font-style: normal;
    font-weight: 500;
      @media screen and (max-width: 767px) {
        line-height: 40px;
        margin: 20px 0px;
      }
      span {
        float: left;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        color: #707c7f;
        line-height: 1.2;
      }
    }
    h5 {
      display: block;
      width: 97%;
      font-size: 16px;
      font-weight: normal;
      font-style: italic;
      color: #FF4400;
      letter-spacing: 0%;
      padding: 0px;
      margin: 10px auto 10px;
      background-color: #FFEEEE;
      padding: 0.5rem 0rem 0.5rem 1rem;
      border-left: solid 3px;
      @media screen and (max-width: 767px) {
        line-height: 40px;
        margin: 20px 0px;
      }
      span {
        float: left;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        color: #707c7f;
        line-height: 1.2;
      }
    }
    ul {
      display: block;
      width: 100%;
      margin: 0px 0px 30px;
      padding: 0px 0px 0px 0px;
      li {
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        color: $text-color;
        line-height: 175%;
        padding: 0px;
        margin: 0px 0px 10px 18px;
      }
    }
    div.bdy-img {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px 0px 20px;
        @media screen and (max-width: 767px) {
            text-align: center;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            text-align: center;
        }
        img {
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
        }
      }
      .lftImgFlx {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: column wrap;
        width: 100%;
        //margin-bottom: 5rem;
        @media screen and (max-width: 767px) {
          flex-flow: column;
          margin-bottom: 2rem;
        }
        .lftImgItemTitle {
          flex: 0 auto;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-flow: column;
          .titleDate {
            flex: 0 auto;
            width: 100%;
            font-size: 18px;
            font-weight: 500;
            color: #707c7f;
            line-height: 1.5;
          }
          .titleMain {
            flex: 0 auto;
            width: 100%;
            font-size: 25px;
            font-weight: 600;
            color: $dark-color;
            line-height: 1.5;
          }
        }
        .lftItemImg {
          flex: 0 auto;
          width: 100%;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
        .lftItemRgt {
          flex: 0 auto;
          width: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          .lftImgItemTitle {
            flex: 0 auto;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: column;
            .titleDate {
              flex: 0 auto;
              width: 100%;
              // font-size: 18px;
              // font-weight: 500;
              font-size: 25px;
              font-weight: 600;
              color: #707c7f;
              text-align: left;
              line-height: 1.2;
              @media screen and (max-width: 767px) {
                font-size: 20px;
                text-align: center;
                margin-top: 1rem;
              }
            }
            .titleMain {
              flex: 0 auto;
              width: 100%;
              // font-size: 25px;
              // font-weight: 600;
              font-size: 18px;
              font-weight: 500;
              color: $dark-color;
              line-height: 1.5;
              margin-top: 0.50rem;
              @media screen and (max-width: 767px) {
                text-align: center;
                margin-top: 1rem;
              }
            }
          }
          .lftImgRgtTxt {
            flex: 0 auto;
            width: 100%;
            padding-top: 1rem;
            font-size: 15px;
            font-weight: 400;
            color: $text-color;
            text-align: justify;
            line-height: 1.5;
            padding-bottom: 2rem;
          }
        }
      }
      .rgtImgFlx {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: column wrap;
        width: auto;
       // margin-bottom: 5rem;
        @media screen and (max-width: 767px) {
          margin-bottom: 2rem;
          flex-direction: column-reverse;
          // flex-flow: column;
        }
        .rgtItemLft {
          flex: 0 auto;
          width: auto;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          .rgtImgItemTitle {
            flex: 0 auto;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: column;
            .titleDate {
              flex: 0 auto;
              width: 100%;
              // font-size: 18px;
              // font-weight: 500;
              font-size: 25px;
              font-weight: 600;
              color: #707c7f;
              text-align: left;
              line-height: 1.2;
              @media screen and (max-width: 767px) {
                font-size: 20px;
                text-align: center;
                margin-top: 2rem;
              }
            }
            .titleMain {
              flex: 0 auto;
              width: 100%;
              // font-size: 25px;
              // font-weight: 600;
              font-size: 18px;
              font-weight: 500;
              color: $dark-color;
              line-height: 1.5;
              margin-top: 0.50rem;
              @media screen and (max-width: 767px) {
                text-align: center;
                margin-top: 1rem;
              }
            }
          }
          .rgtImgLftTxt {
            flex: 0 auto;
            width: 100%;
            padding-top: 1rem;
            font-size: 15px;
            font-weight: 400;
            color: $text-color;
            text-align: justify;
            line-height: 1.5;
            padding-bottom: 2rem;
          }
        }
        .rgtItemImg {
          flex: 0 auto;
          width: 100%;
          //margin-bottom: 2rem;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
       
      }
  }
}
