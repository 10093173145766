@import "themes.scss";
@import 'mixins.scss';
.newsevents-banner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/news/newsevents_banner_01.svg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: left;
            padding: 145px 0px 0px;
            margin: 0px;
            @include upsure-xs {
                font-size: 2.2rem;
            }
        
            @include upsure-sm {
                font-size: 2.2rem;
            }
            @include upsure-md {
                font-size: 4rem;
            }
            @include upsure-lg {
                font-size: 60px;
            }
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: left;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }

.nsent-banndwn {
    float: left;
    width: 100%;
    margin: 482px 0px 0px 0px;
    .container {
        .mt-100 {
            //margin-top: 50px !important;
        }
        .news-rwone-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            margin: 80px 0px 80px;
            @media screen and (max-width: 767px) {
                flex-flow: column;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                flex-flow: column;
            }
            .news-rwone-item-lft {
                flex: 0 auto;
                width: 50%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    text-align: center;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                    text-align: center;
                }
                img {
                    width: 100%;
                }
            }
            .news-rwone-item-rgt {
                flex: 0 auto;
                width: 40%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                }
                h4 {
                    display: block;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: $dark-color;
                    text-align: left;
                    padding: 0px;
                    margin: 0px 0px 30px;
                    span {
                        display: block;
                        width: 100%;
                        font-size: 14px;
                        font-weight: 400;
                        color: #707C7F;
                        text-align: left;
                        text-transform: uppercase;
                    }
                }
                p {
                    font-size: 15px;
                    font-weight: 400;
                    color: $text-color;
                    text-align: justify;
                    padding: 10px 0px 0px 0px;
                    margin: 0px;
                }
            }
        }
        .news-rwtwo-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            margin: 80px 0px 80px;
            padding-bottom: 0px;
            @media screen and (max-width: 767px) {
                flex-flow: column;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                flex-flow: column;
            }
            .news-rwtwo-item-lft {
                flex: 0 auto;
                width: 45%;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                }
                h4 {
                    display: block;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: $dark-color;
                    text-align: left;
                    padding: 0px;
                    margin: 0px 0px 30px;
                    span {
                        display: block;
                        width: 100%;
                        font-size: 15px;
                        font-weight: 400;
                        color: #707C7F;
                        text-align: left;
                        text-transform: uppercase;
                    }
                }
                p {
                    font-size: 15px;
                    font-weight: 400;
                    color: $text-color;
                    text-align: justify;
                    padding: 10px 0px 0px 0px;
                    margin: 0px;
                }
            }
            .news-rwtwo-item-rgt {
                flex: 0 auto;
                width: 50%;
                text-align: right;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    text-align: center;
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    width: 100%;
                    text-align: center;
                }
                img {
                    width: 100%;
                }
            }
        }
    }
}

.tab-view {
    .mt-100 {
        margin-top: 100px !important;
    }
    .news-rwone-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin: 20px 0px 50px;
        @media screen and (max-width: 767px) {
            flex-flow: column;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            flex-flow: column;
        }
        .news-rwone-item-lft {
            flex: 0 auto;
            width: 50%;
            @media screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
                text-align: center;
            }
            img {
                width: 100%;
            }
        }
        .news-rwone-item-rgt {
            flex: 0 auto;
            width: 40%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
            h4 {
                display: block;
                width: 100%;
                font-size: 30px;
                font-weight: 600;
                color: $dark-color;
                text-align: left;
                padding: 0px;
                margin: 0px 0px 30px;
                span {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    color: #707C7F;
                    text-align: left;
                    text-transform: uppercase;
                }
            }
            p {
                font-size: 15px;
                font-weight: 400;
                color: $text-color;
                text-align: justify;
                padding: 10px 0px 0px 0px;
                margin: 0px;
            }
        }
    }
    .news-rwtwo-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin: 20px 0px 50px;
        @media screen and (max-width: 767px) {
            flex-flow: column;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            flex-flow: column;
        }
        .news-rwtwo-item-lft {
            flex: 0 auto;
            width: 45%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
            h4 {
                display: block;
                width: 100%;
                font-size: 30px;
                font-weight: 600;
                color: $dark-color;
                text-align: left;
                padding: 0px;
                margin: 0px 0px 30px;
                span {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    color: #707C7F;
                    text-align: left;
                    text-transform: uppercase;
                }
            }
            p {
                font-size: 15px;
                font-weight: 400;
                color: $text-color;
                text-align: justify;
                padding: 10px 0px;
                margin: 0px;
            }
        }
        .news-rwtwo-item-rgt {
            flex: 0 auto;
            width: 50%;
            text-align: right;
            @media screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
                text-align: center;
            }
            img {
                width: 100%;
            }
        }
    }
}

.mobile-view {
    .mt-100 {
        margin-top: 100px !important;
    }
    .news-rwone-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin: 20px 0px 50px;
        @media screen and (max-width: 767px) {
            flex-flow: column;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            flex-flow: column;
        }
        .news-rwone-item-lft {
            flex: 0 auto;
            width: 50%;
            @media screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
                text-align: center;
            }
            img {
                width: 100%;
            }
        }
        .news-rwone-item-rgt {
            flex: 0 auto;
            width: 40%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
            h4 {
                display: block;
                width: 100%;
                font-size: 30px;
                font-weight: 600;
                color: $dark-color;
                text-align: left;
                padding: 0px;
                margin: 0px 0px 30px;
                span {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    color: #707C7F;
                    text-align: left;
                    text-transform: uppercase;
                }
            }
            p {
                font-size: 15px;
                font-weight: 400;
                color: $text-color;
                text-align: justify;
                padding: 10px 0px;
                margin: 0px;
            }
        }
    }
    .news-rwtwo-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin: 20px 0px 50px;
        @media screen and (max-width: 767px) {
            flex-flow: column;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            flex-flow: column;
        }
        .news-rwtwo-item-lft {
            flex: 0 auto;
            width: 45%;
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
            }
            h4 {
                display: block;
                width: 100%;
                font-size: 30px;
                font-weight: 600;
                color: $dark-color;
                text-align: left;
                padding: 0px;
                margin: 0px 0px 30px;
                span {
                    display: block;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #707C7F;
                    text-align: left;
                    text-transform: uppercase;
                }
            }
            p {
                font-size: 15px;
                font-weight: 400;
                color: $text-color;
                text-align: justify;
                padding: 0px;
                margin: 10px 0px;
            }
        }
        .news-rwtwo-item-rgt {
            flex: 0 auto;
            width: 50%;
            text-align: right;
            @media screen and (max-width: 767px) {
                width: 100%;
                text-align: center;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                width: 100%;
                text-align: center;
            }
            img {
                width: 100%;
            }
        }
    }
}