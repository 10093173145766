@import "themes.scss";


.mutualfund-banner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/businesslines/lifeinsurance_banner.svg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: center;
            padding: 145px 0px 0px;
            margin: 0px;
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: center;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
  

  .mutualfunds-banner-dwn {
    background: #F5FBFB;
    float: left;
    width: 100%;
    height: 459px;
    margin-top: 482px;
    .container {
        h2.page-heading {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            font-style: normal;
            color: #012932;
            line-height: 33px;
            text-align: center;
            padding: 50px 0px 0px;
            margin: 0px 0px 30px;
        }
        p.contxt {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: justify;
            margin: 10px 0px;
            padding: 0px;
        }
        .mutualfund-bx {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            padding-top: 40px;
            .mutfunbx-item {
                flex: 0 auto;
                background: #F5FBFB;
                border: 1px solid #BBDFDF;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                border-radius: 10px;
                margin: 0px 25px;
                width: 200px;
                height: 128px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                .item-img {
                    flex: 0 auto;
                    width: 37px;
                    height: 46px;
                }
                .item-dec {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 500;
                    text-align: center;
                    padding-top: 9px;
                }
            }
        }
    }
  }
  .mutfund-bdycon {
    float: left;
    width: 100%;
    height: 100%;
    padding: 100px 0px;
    .container {
        h3.mutfund-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: center;
        }
        p.contxt {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: center;
            margin: 20px 0px;
            padding: 0px;
        }
        .mb-70 {
            margin-bottom: 70px !important;
        }
        h4.titlebnk {
            float: left;
            width: 100%;
            font-size: 22px;
            font-weight: 500;
            color: #012932;
            text-align: left;
            margin: 20px 0px 0px;
        }
    .mutufund-pro-bx {
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin-top: 70px;
        .onetime-item {
            flex: 0 auto;
            width: 50%;
            padding: 45px 50px 43px 48px;
            h4.ontime-title {
                float: left;
                width: 100%;
                font-size: 18px;
                font-weight: 600;
                color: #1B818D;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            p.onetime-dec {
                float: left;
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                color: #667477;
                text-align: justify;
                margin-bottom: 20px;
            }
        }
        .sipinvet-item {
            background: #2CA1AF;
            flex: 0 auto;
            width: 50%;
            -webkit-border-top-right-radius: 30px;
            -webkit-border-bottom-right-radius: 30px;
            -moz-border-radius-topright: 30px;
            -moz-border-radius-bottomright: 30px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            padding: 45px 50px 43px 48px;
            h4.sip-title {
                float: left;
                width: 100%;
                font-size: 18px;
                font-weight: 600;
                color: #fff;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            p.sip-dec {
                float: left;
                width: 100%;
                font-size: 15px;
                font-weight: 400;
                color: #fff;
                text-align: justify;
                margin-bottom: 20px;
            }
        }
    }
    }
  }
  
.mutufund-sipulip {
    background: #F5FBFB;
    float: left;
    width: 100%;
    padding: 100px 0px;
    margin: 0px;
    .container {
        h4.sipulip-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        p.sipulip-cont {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            font-style: normal;
            text-align: center;
            margin: 0px 0px 60px;
            padding: 15px 0px 20px;
        }
        .sipulip-pro-bx {
            background: #fff;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 70px;
            .onetime-item {
                flex: 0 auto;
                width: 50%;
                padding: 45px 50px 43px 48px;
                h4.ontime-title {
                    float: left;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    color: #1B818D;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }
                p.onetime-dec {
                    float: left;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    text-align: justify;
                    margin-bottom: 20px;
                }
            }
            .sipinvet-item {
                background: #012932;
                flex: 0 auto;
                width: 50%;
                -webkit-border-top-right-radius: 30px;
                -webkit-border-bottom-right-radius: 30px;
                -moz-border-radius-topright: 30px;
                -moz-border-radius-bottomright: 30px;
                border-top-right-radius: 30px;
                border-bottom-right-radius: 30px;
                padding: 45px 50px 43px 48px;
                h4.sip-title {
                    float: left;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }
                p.sip-dec {
                    float: left;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #fff;
                    text-align: justify;
                    margin-bottom: 20px;
                }
            }
        }

        ul {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 10px 0px 10px 15px;
            li {
                font-size: 15px;
                font-weight: 600;
                color: #000;
            }
        }
        p.cont-txt {
            float: left;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #667477;
            font-style: normal;
            text-align: left;
            margin: 0px;
            padding: 5px 0px 20px;
        }
    }
}

.mutufund-basedinvit {
    float: left;
    width: 100%;
    padding: 100px 0px;
    margin: 0px;
    .container {
        h4.basedinvit-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: center;
            margin: 0px;
            padding: 0px;
        }
        p.basedinvt-cont {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            font-style: normal;
            text-align: justify;
            margin: 50px 0px 20px;
            padding: 15px 0px 20px;
        }
        h4.choose-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: left;
            margin: 0px 0px 20px;
            padding: 0px;
        }
        ul {
            float: left;
            width: 100%;
            margin: 0px;
            padding: 10px 0px 10px 15px;
            li {
                font-size: 15px;
                font-weight: 500;
                color: #000;
                padding: 0px;
                margin: 0px;
            }
        }
        p.cont-txt {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            font-style: normal;
            text-align: justify;
            margin: 0px;
            padding: 5px 0px 20px;
        }
        h4.benitinvt-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: left;
            margin: 0px 0px 20px;
            padding: 0px;
        }
        h4.waring-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: left;
            margin: 0px 0px 20px;
            padding: 0px;
        }
        p.waring-cont {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            font-style: normal;
            text-align: justify;
            margin: 0px;
            padding: 5px 0px 20px;
        }
    }
}