@import "themes.scss";
@import 'mixins';

.pcgeneralinsubanner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/businesslines/lifeinsurance_banner.svg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: center;
            padding: 145px 0px 0px;
            margin: 0px;
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: center;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }

  .pcgeninsu-section-main {
    float: left;
    width: 100%;
    margin-top: 570px;
    margin-bottom: 100px;    
    p.contxt {
        float: left;
        width: 100%;
        font-size: 15px;
        font-weight: 400;
        color: #667477;
        text-align: justify;
        margin: 10px 0px;
        padding: 0px;
    }
    h2.title {
        float: left;
        width: 100%;
        font-size: 22px;
        font-weight: 600;
        color: #012932;
        text-align: center;
        margin: 40px 0px;
        padding: 0px;
    }
    .pc-subtitle{
        float: left;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        color: #012932;
        text-align: left;
        margin: 10px 0px;
        padding: 0px;
    }
    ul.sublists{
        padding-left: 0rem;
        li{
            color: #667477; 
            list-style-type: none;
        }
    }
    .right-products {
        background: #F5FBFB;
        float: left;
        width: 100%;
        padding: 100px 0px;
        margin-top: 100px;
    }
    h3.rgt-pro-title {
        float: left;
        width: 100%;
        font-size: 30px;
        font-weight: 600;
        font-style: normal;
        line-height: 33px;
        color: #012932;
        text-align: center;
        margin: 0px;
        padding: 0px;
    }
    .rgtpro-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        padding-top: 30px;
        .rgtpro-head-item {
            flex: 0 auto;
            width: 33%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: center;
            padding: 20px 40px;
        }
    }
    .rgt-pro-bx {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        border-radius: 30px;
        margin-top: 20px;
        margin-bottom: 100px;
        @include upsure-sm {
            flex-flow: column;
        }
        @include upsure-md {
            flex-flow: row;
        }
        .distribution-item {
            flex: 0 auto;
            width: 33%;
            min-height: 295px;
            @include upsure-xs {
                width: 100%;
            }
        
            @include upsure-sm {
                width: 100%;
            }
            
            
               @include upsure-xs {
            flex-flow: column;
        }
            .distri-con {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                padding-left: 80px;
                margin-top: 45px;
                @include upsure-xs{
                    padding-left: 50px;
                }
                @include upsure-md{
                    padding-left: 20px;
                }
                @include upsure-lg{
                    padding-left: 80px;
                }
                h4.distri-title {
                    font-size: 15px;
                    font-weight: 600;
                    color: #1B818D;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0px;
                    margin: 0px;
                }
                .distri-fets-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row wrap;
                    width: 100%;
                    margin-top: 30px;
                    .fetsflx-img-item {
                        flex: 0 auto;
                        width: 46px;
                        height: 46px;
                        // border: #2CA1AF solid 1px;
                        border-radius: 100%;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 15%;
                        }
                        @include upsure-sm{
                            width: 15%;
                        }
                        @include upsure-md{
                            width: 20%;
                        }
                        @include upsure-lg{
                            width: 18%;
                        }
                        img {
                            width: 46px;
                            height: 46px;
                        }
                    }
                    .fetsflx-item {
                        flex: 0 auto;
                        width: 80%;
                        font-size: 15px;
                        font-weight: 400;
                        color: #667477;
                        margin-left: 10px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 70%;
                            font-size: 11px;
                        }
                        @include upsure-sm{
                            width: 70%;
                            font-size: 11px;
                        }
                        @include upsure-md{
                            width:70%;
                            font-size: 11px;
                        }
                        @include upsure-lg{
                            width:70%;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .groupinsurance-item {
            flex: 0 auto;
            width: 33%;
            min-height: 295px;
            background: #2CA1AF;
            @include upsure-xs {
                width: 100%;
            }
        
            @include upsure-sm {
                width: 100%;
            }
            
            
               @include upsure-xs {
            flex-flow: column;
        }
            .groupinsurance-con {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                padding-left: 45px;
                margin-top: 45px;
                h4.grpinsu-title {
                    font-size: 15px;
                    font-weight: 600;
                    color: #fff;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0px;
                    margin: 0px;
                }
                .grpinsu-fets-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row wrap;
                    width: 100%;
                    margin-top: 30px;
                    .grpinsuflx-img-item {
                        flex: 0 auto;
                        width: 46px;
                        height: 46px;
                        border-radius: 100%;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 15%;
                        }
                        @include upsure-sm{
                            width: 15%;
                        }
                        @include upsure-md{
                            width: 20%;
                        }
                        @include upsure-lg{
                            width: 18%;
                        }
                        img {
                            width: 46px;
                            height: 46px;
                        }
                    }
                    .grpinsuflx-item {
                        flex: 0 auto;
                        width: 80%;
                        font-size: 15px;
                        font-weight: 400;
                        color: #fff;
                        margin-left: 10px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 70%;
                            font-size: 11px;
                        }
                        @include upsure-sm{
                            width: 70%;
                            font-size: 11px;
                        }
                        @include upsure-md{
                            width:70%;
                            font-size: 11px;
                        }
                        @include upsure-lg{
                            width:70%;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .corefunctions-item {
            flex: 0 auto;
            width: 33%;
            min-height: 295px;
            @include upsure-xs {
                width: 100%;
            }
        
            @include upsure-sm {
                width: 100%;
            }
            
            
               @include upsure-xs {
            flex-flow: column;
        }
            .corefunctions-con {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                padding-left: 45px;
                margin-top: 45px;
                @include upsure-md{
                    padding-left: 25px;
                }
                h4.corefunc-title {
                    font-size: 15px;
                    font-weight: 600;
                    color: #1B818D;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0px;
                    margin: 0px;
                }
                .corefunc-fets-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-flow: row wrap;
                    width: 100%;
                    margin-top: 30px;
                    .corefuncflx-img-item {
                        flex: 0 auto;
                        width: 46px;
                        height: 46px;
                        border: #2CA1AF solid 1px;
                        border-radius: 100%;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 15%;
                        }
                        @include upsure-sm{
                            width: 15%;
                        }
                        @include upsure-md{
                            width: 25%;
                        }
                        @include upsure-lg{
                            width: 46px;
                        }
                    }
                    .corefuncflx-item {
                        flex: 0 auto;
                        width: 80%;
                        font-size: 15px;
                        font-weight: 400;
                        color: #667477;
                        margin-left: 10px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 70%;
                            font-size: 11px;
                        }
                        @include upsure-sm{
                            width: 70%;
                            font-size: 11px;
                        }
                        @include upsure-md{
                            width:70%;
                            font-size: 11px;
                        }
                        @include upsure-lg{
                            width:80%;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
    .rgt-usecase-bx {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        background: #fff;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        border-radius: 30px;
        margin-top: 20px;
        margin-bottom: 100px;
        @include upsure-sm {
            flex-flow: column;
        }
        @include upsure-md {
            flex-flow: row;
        }
        .distribution-item {
            flex: 0 auto;
            width: 33%;
            min-height: 295px;
            @include upsure-xs {
                width: 100%;
            }
        
            @include upsure-sm {
                width: 100%;
            }
            
            
               @include upsure-xs {
            flex-flow: column;
        }
            .distri-con {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                padding-left: 80px;
                margin-top: 45px;
                @include upsure-xs{
                    padding-left: 40px;
                }
                @include upsure-sm{
                    padding-left: 40px;
                }
                @include upsure-lg{
                    padding-left: 80px;
                }
                h4.distri-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #1B818D;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0px;
                    margin: 0px;
                }
                .distri-fets-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-flow: row wrap;
                    width: 100%;
                    margin-top: 30px;
                    .fetsflx-img-item {
                        flex: 0 auto;
                        width: 5px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #667477;
                        line-height: 24px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 12%;
                        }
                        @include upsure-sm{
                            width: 10%;
                        }
                        @include upsure-md{
                            width: 10%;
                        }
                        @include upsure-lg{
                            width: 10%;
                        }
                    }
                    .fetsflx-item {
                        flex: 0 auto;
                        width:94%;
                        font-size: 15px;
                        font-weight: 400;
                        color: #667477;
                        line-height: 24px;
                        padding-right: 25px;
                        margin-left: 15px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 80%;
                            font-size: 11px;
                            margin-left: 0px;
                        }
                        @include upsure-sm{
                            width: 70%;
                            font-size: 11px;
                        }
                        @include upsure-md{
                            width:82%;
                            font-size: 11px;
                        }
                        @include upsure-lg{
                            width:90%;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
        .groupinsurance-item {
            flex: 0 auto;
            width: 33%;
            min-height: 295px;
            background: #012932;
            @include upsure-xs {
                width: 100%;
            }
        
            @include upsure-sm {
                width: 100%;
            }
            
            
               @include upsure-xs {
            flex-flow: column;
        }
            .groupinsurance-con {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                padding-left: 45px;
                margin-top: 45px;
                h4.grpinsu-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #fff;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0px;
                    margin: 0px;
                }
                .grpinsu-fets-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-flow: row wrap;
                    width: 100%;
                    margin-top: 30px;
                    .grpinsuflx-img-item {
                        flex: 0 auto;
                        width: 5px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #fff;
                        line-height: 24px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 10%;
                        }
                        @include upsure-sm{
                            width: 15%;
                        }
                        @include upsure-md{
                            width: 10%;
                        }
                        @include upsure-lg{
                            width: 10px;
                        }
                    }
                    .grpinsuflx-item {
                        flex: 0 auto;
                        width:94%;
                        font-size: 15px;
                        font-weight: 400;
                        color: #fff;
                        line-height: 24px;
                        padding-right: 25px;
                        margin-left: 15px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 80%;
                        }
                        @include upsure-sm{
                            width: 70%;
                        }
                        @include upsure-md{
                            width:75%;
                        }
                        @include upsure-lg{
                            width:90%;
                        }
                        @include upsure-xl{
                            width: 92%;
                        }
                    }
                }
            }
        }
        .corefunctions-item {
            flex: 0 auto;
            width: 33%;
            min-height: 295px;
            @include upsure-xs {
                width: 100%;
            }
        
            @include upsure-sm {
                width: 100%;
            }
            
            
               @include upsure-xs {
            flex-flow: column;
        }
            .corefunctions-con {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-flow: column;
                padding-left: 45px;
                margin-top: 45px;
                h4.corefunc-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #1B818D;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 0px;
                    margin: 0px;
                }
                .corefunc-fets-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-flow: row wrap;
                    width: 100%;
                    margin-top: 30px;
                    .corefuncflx-img-item {
                        flex: 0 auto;
                        width: 5px;
                        font-size: 16px;
                        font-weight: 400;
                        color: #667477;
                        line-height: 24px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 13%;
                        }
                        @include upsure-sm{
                            width: 15%;
                        }
                        @include upsure-md{
                            width: 10%;
                        }
                        @include upsure-lg{
                            width: 13%;
                        }
                    }
                    .corefuncflx-item {
                        flex: 0 auto;
                        width:94%;
                        font-size: 15px;
                        font-weight: 400;
                        color: #667477;
                        line-height: 24px;
                        padding-right: 25px;
                        margin-left: 15px;
                        margin-bottom: 15px;
                        @include upsure-xs{
                            width: 75%;
                            margin-left: 0px;
                        }
                        @include upsure-sm{
                            width: 70%;
                        }
                        @include upsure-md{
                            width:82%;
                        }
                        @include upsure-lg{
                            width:83%;
                        }
                    }
                }
            }
        }
    }
    .heltinsu-embedded {
        float: left;
        background: #fff;
        width: 100%;
        padding: 100px 0px;
        margin: 0px;
        .embedded-txt {
            float: left;
            width: 100%;
            font-size: 22px;
            font-weight: 500;
            font-style: normal;
            color: #012932;
            line-height: 33px;
            text-align: center;
            margin: 0px;
            padding: 0px 0px 100px;
        }
        .embedd-bx-sec {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            .embedd-bx-item {
                flex: 0 auto;
                background: #F5FBFB;
                border: 1px solid #BBDFDF;
                border-radius: 10px;
                width: 200px;
                min-height: 128px;
                margin-right: 27px;
                padding-bottom: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                .embedbx-img {
                    flex: 0 auto;
                    width: 50px;
                    height: 50px;
                    margin-top: 25px;
                    // background-color: #1b818d;
                    // border: #1b818d solid 1px;
                    img {
                        width: 46px;
                        height: 46px;
                    }
                }
                .embedbx-txt {
                    flex: 0 auto;
                    font-size: 16px;
                    font-weight: 500;
                    color: #012932;
                    font-style: normal;
                    line-height: 24px;
                    margin-top: 20px;
                }
            }
        }
    }
  }
  .business-line-btns {
    position: absolute;
    left: 0px;
    bottom: -25px;
    height: 50px;
    width: 100%;
    .business-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row wrap;
        button.bussin-btn, a.bussin-btn {
            flex: 0 auto;
            background: #156D77;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 57px;
            border: 0px;
            outline: 0px;
            min-width: 86px;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            line-height: 24px;
            padding: 10px 30px;
            margin-right: 15px;
            cursor: pointer;
        }
        button.bussin-btn-active, a.bussin-btn-active {
            flex: 0 auto;
            background: #46BFCA;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            border-radius: 57px;
            border: 0px;
            outline: 0px;
            min-width: 86px;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
            line-height: 24px;
            padding: 10px 30px;
            margin-right: 15px;
            cursor: pointer;
        }
    }
  }

  .pcgen-slidebg {
    width: 100%;
    margin: 0;
  overflow:hidden;
  position: relative;
  &::after {
    content:"";
    position:absolute;
    top:0;
    left:0;
    height: 100%;
    width:100%;
    transform: skewY(-3deg);
    background: #F5FBFB;
    transform-origin: bottom right;
    z-index: -1;
  }
  .pcgenslidebg {
    background: #F5FBFB;
    float: left;
    width: 100%;
    padding-bottom: 200px;
    // min-height: 1000px;
    margin-top: 130px;
    .container {
        .hmowner-sec-one {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            .hmowner-itemlft {
                flex: 0 auto;
                width: 40%;
                h3 {
                    display: block;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: #012932;
                    font-style: normal;
                    text-align: left;
                }
                p {
                    display: block;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    font-style: normal;
                    text-align: justify !important; 
                }
            }
            .hmowner-itemrgt {
                flex: 0 auto;
                width: 40%;
            }
        }
        .hmowner-sec-two {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 100px;
            margin-bottom: 100px;
            .hmowner-itemlft {
                flex: 0 auto;
                width: 40%;
            }
            .hmowner-itemrgt {
                flex: 0 auto;
                width: 40%;
                h3 {
                    display: block;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: #012932;
                    font-style: normal;
                    text-align: left;
                }
                p {
                    display: block;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    text-align: justify !important;
                    font-style: normal;
                    text-align: left;
                }
            }
        }
    }
  }
}
.propertyinsurance {
    float: left;
    width: 100%;
    padding: 100px 0px;
    .container {
        h4.property-title {
            float: left;
            width: 100%;
            font-size: 30px;
            font-weight: 600;
            color: #012932;
            text-align: center;
            padding: 0px;
            margin: 0px;
        }
        p.pro-cont {
            float: left;
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            color: #667477;
            text-align: justify !important;
            padding: 0px;
            margin: 30px 0px 0px;
        }
        .rentersinsu-sec {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            padding-top: 100px;
            .rentersinsu-itemlft {
                flex: 0 auto;
                width: 40%;
                h3 {
                    display: block;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: #012932;
                    font-style: normal;
                    text-align: justify !important;
                }
                p {
                    display: block;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    font-style: normal;
                    text-align: justify !important;
                }
            }
            .rentersinsu-itemrgt {
                flex: 0 auto;
                width: 40%;
                h3 {
                    display: block;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: #012932;
                    font-style: normal;
                    line-height: 33px;
                    text-align: left;
                }
                p {
                    display: block;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    font-style: normal;
                    text-align: justify !important;
                }
            }
        }
        .hmowner-sec-two {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 100px;
            margin-bottom: 100px;
            .hmowner-itemlft {
                flex: 0 auto;
                width: 40%;
            }
            .hmowner-itemrgt {
                flex: 0 auto;
                width: 40%;
                h3 {
                    display: block;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: #012932;
                    font-style: normal;
                    line-height: 33px;
                    text-align: left;
                }
                p {
                    display: block;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: #667477;
                    font-style: normal;
                    text-align: justify !important;
                }
            }
        }
    }
}