@import "themes.scss";
@import 'mixins.scss';

.whoweare-banner-bg {
    // background: #000;
    background-image: url('https://cdn.upsure.io/newui2023/team/whoweare_banner_01.svg');
    background-repeat: no-repeat;
    background-position: center top;
    display: block;
    width: 100%;
    min-height: 564px;
    position: absolute;
    top: 0px;
    .container {
        position: relative;
        margin-top: 82px;
        h1.banner-heading {
            display: block;
            width: 100%;
            font-size: 60px;
            font-weight: 900;
            color: $white-color;
            text-align: left;
            padding: 145px 0px 0px;
            margin: 0px;
        }
        p.banner-dec {
            display: block;
            width: 100%;
            font-size: 24px;
            font-weight: 400;
            color: $white-color;
            text-align: left;
            padding: 12px 0px 0px;
            margin: 0px;
        }
    }
  }
  .teams-bdy {
    float: left;
    width: 100%;
    padding: 0px;
    @include upsure-xs {
        margin: 490px 0px 0px;
    }
    @include upsure-sm {
        margin: 440px 0px 0px;
    }
    @include upsure-md {
        margin: 460px 0px 0px;
    }
    @include upsure-lg {
        margin: 460px 0px 0px;
    }
    @include upsure-xl {
        margin: 464px 0px 0px;
    }
    .team-flex {
        display: grid;
        grid-gap: 1rem;
        width: 100%;
        margin: 150px 0px 150px !important;
        @include upsure-xs {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            width: 100%;
            margin: 50px 0px 50px !important;
        }
        @include upsure-sm {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
            width: 100%;
            margin: 100px 0px 100px;
        }
        @include upsure-md {
            grid-template-columns: repeat(2, 1fr);
            margin: 100px 0px 100px !important;
        }
        @include upsure-lg {
            grid-template-columns: repeat(2, 1fr);
            margin: 100px 0px 100px !important;
        }
        
        @include upsure-xl {
            grid-template-columns: repeat(3, 1fr);
            margin: 100px 0px 100px !important;
        }
        .item-bx {
            // flex: 0 auto;
            // width: 30%;
            width: 420px;
            // min-height: 440px;
            position: relative;
            border-radius: 10px;
            @include upsure-xs {
                width: 100%;
            }
            .team-dec-flex {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column;
                min-height: 440px;
                @include upsure-xs {
                    min-height: auto;
                }
                h2.team-title {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 30px;
                    font-weight: 600;
                    color: $dark-color;
                    text-align: center;
                }
                p {
                    flex: 0 auto;
                    width: 100%;
                    font-size: 15px;
                    font-weight: 400;
                    color: $text-color;
                    line-height: 25px;
                    padding: 20px 20px 0px;
                }
            }
            .arrowbg {
                background-image: url("../../../public/assets/images/team_arrow_bg.svg");
                background-repeat: no-repeat;
                background-position: -55px 250px;
                background-size: contain;
                width: 100%;
                height: 100%;
                .tema-dec-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-flow: column;
                    .team-mem-nm {
                        flex: 0 auto;
                        font-size: 20px;
                        font-weight: 600;
                        color: $white-color;
                        padding: 35px 0px 0px 28px;
                        margin: 0px;
                    }
                    .team-mem-bio {
                        flex: 0 auto;
                        font-size: 15px;
                        font-weight: 400;
                        color: $white-color;
                        line-height: 24px;
                        padding: 23px 35px 0px 28px;
                        margin: 0px;
                    }
                }
            }
            .team-mem-pic {
                position: absolute;
                bottom: 0px;
                right: 0px;
                img {
                    width: 267px;
                }
                img.kiran-img {
                    width: 258px;
                }
                img.hemanth-img {
                    width: 202px;
                }
                img.jaline-img {
                    width: 179px;
                }
            }
            a.team-mem-linkedin {
                position: absolute;
                bottom: 35px;
                left: 35px;
                background-image: url("../../../public/assets/images/team/team_mem_linkedin_icon.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 50px;
                height: 50px;
                cursor: pointer;
            }
        }
        .minheight-first {
            min-height: 440px;
            @include upsure-xs {
                min-height: 360px;
            }
        }
        .minheight {
            @include upsure-xs {
                min-height: 475px;
            }
            @include upsure-sm {
                min-height: 495px;
            }
            @include upsure-md {
                min-height: 492px;
            }
            @include upsure-lg {
                min-height: 460px;
            }
            @include upsure-xl {
                min-height: 450px;
            }
        }
        .darkbg {
            background: $dark-color;
        }
        .lightbg {
            background: #2CA1AF;
        }
    }
  }